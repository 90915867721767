import React, { useState, Fragment, useEffect } from "react";
import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import axios from "axios";
import { useTranslation } from "react-i18next";

const Locationtab = ({ locationData }) => {
  const [lat, setLat] = useState("13.736717");
  const [embedCode, setEmbedCode] = useState("");
  const [long, setLong] = useState("100.523186");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [country, setCountry] = useState("");
  const [Tumbol, setTumbol] = useState("");
  const [locationDetails, setLocationDetails] = useState("");
  const [position, setPosition] = useState([parseFloat(lat), parseFloat(long)]);
  const [reverseGeocodingError, setReverseGeocodingError] = useState(null);
  const { t } = useTranslation();
  const customMarker = L.icon({
    iconUrl: process.env.PUBLIC_URL + "/assets/img/misc/marker.png",
    iconSize: [50, 50],
    iconAnchor: [25, 5],
  });

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          if (!isNaN(latitude)) {
            setLat(latitude.toFixed(6));
          }
          if (!isNaN(longitude)) {
            setLong(longitude.toFixed(6));
          }
        },
        (error) => {
          console.error("Error getting user's location: ", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };
  const handleEmbedCodeChange = (e) => {
    setEmbedCode(e.target.value);
  };
  useEffect(() => {
    getUserLocation();
  }, []);

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleMarkerMove = (e) => {
    const { lat, lng } = e.latlng;
    // Check if lat and lng are valid numbers
    if (!isNaN(lat) && !isNaN(lng)) {
      setLat(lat.toFixed(6));
      setLong(lng.toFixed(6));
    } else {
      console.error("Invalid LatLng object:", e.latlng);
    }
  };

  useEffect(() => {
    locationData({
      lat,
      long,
      address,
      city,
      province,
      zipcode,
      Tumbol,
      embedCode,
      country: country ? country : "Thailand",
    });
    if ((!isNaN(lat) && lat != "") && (!isNaN(long) && long != "")) {
      setPosition([parseFloat(lat), parseFloat(long)]);
    }
  }, [address, city, country, lat, long, province, zipcode, Tumbol,embedCode]);

  useEffect(() => {
    console.log(address);
    const fetchCoordinates = async () => {
      try {
        const response = await axios.get(
          `https://nominatim.openstreetmap.org/search?q=${address}&format=json&limit=1`
        );

        if (response.data && response.data.length > 0) {
          // console.log(response?.data[0]);
          const { lat, lon, display_name } = response.data[0];
          setLat(lat);
          setLong(lon);
          setLocationDetails(display_name);
        } else {
          setLocationDetails("");
        }
      } catch (error) {
        console.error("Error fetching location coordinates: ", error);
      }
    };
    if(address === ""){
      setCountry('')
      setProvince("")
      setCity("")
    }
    else{
      fetchCoordinates();
    }
  }, [address]);

  useEffect(() => {
    const fetchReverseGeocodingData = async () => {
      if ((!isNaN(lat) && lat != "") && (!isNaN(long) && long != "")) {
        try {
          const response = await axios.get(
            `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${long}&format=json`
          );
          if (response.data) {
            const { address } = response.data;
            setCountry(address.country || "");
            setCity(
              address.city ||
              address.town ||
              address.village ||
              address.hamlet ||
              address.suburb ||
              address.subdistrict ||
              address.district ||
              ""
            );
            setProvince(address.state || address.region || "");
            setZipcode(address.postcode || "");
          } else {
            setCountry("");
            setCity("");
            setProvince("");
            setZipcode("");
            setEmbedCode("")
          }
          setReverseGeocodingError(null);
        } catch (error) {
          console.error("Error fetching reverse geocoding data: ", error);
          setReverseGeocodingError("Error fetching location details.");
        }
      }
    };
    if(address === ""){
      setCountry('')
      setProvince("")
      setCity("")
    }
    else{
      fetchReverseGeocodingData();
    }
  }, [lat, long]);
  return (
    <Fragment>
      <div className="form-group submit-listing-map">
        <MapContainer zoom={13} center={position}>
          <TileLayer url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" />
          <Marker
            position={position}
            icon={customMarker}
            draggable={true}
            eventHandlers={{ moveend: handleMarkerMove }}
          >
            <Popup position={position}>
              Current location: <pre>{JSON.stringify(locationDetails, null, 2)}</pre>
            </Popup>
          </Marker>
        </MapContainer>
      </div>
      <div>
        <div className="form-group">
          <span className="acr-form-notice">
            {t('drag_and_drop_notice')}
          </span>
        </div>
        <div className="row">
          <div className="col-md-12 form-group">
            <label>{t('full_address_label')}</label>
            <input
              type="text"
              value={address}
              onChange={handleAddressChange}
              name="address"
              className="form-control"
              placeholder={t('full_address_label')}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-3 form-group">
            <label>{t('country_label')}</label>
            <input
              type="text"
              name="country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              className="form-control"
              placeholder={t('country_label')}
            />
          </div>
          <div className="col-lg-3 col-md-3 form-group">
            <label>{t('Tumbol')}</label>
            <input
              type="text"
              name="tumbol"
              value={Tumbol}
              onChange={(e) => setTumbol(e.target.value)}
              className="form-control"
              placeholder={t('Tumbol')}
            />
          </div>
          <div className="col-lg-3 col-md-3 form-group">
            <label>{t('city_label')}</label>
            <input
              type="text"
              name="city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              className="form-control"
              placeholder={t('city_label')}
            />
          </div>
          <div className="col-lg-3 col-md-3 form-group">
            <label>{t('province_label')}</label>
            <input
              type="text"
              name="province"
              value={province}
              onChange={(e) => setProvince(e.target.value)}
              className="form-control"
              placeholder={t('province_label')}
            />
          </div>
          <div className="col-lg-3 col-md-3 form-group">
            <label>{t('zipcode_label')}</label>
            <input
              type="text"
              name="zipcode"
              value={zipcode}
              onChange={(e) => setZipcode(e.target.value)}
              className="form-control"
              placeholder={t('zipcode_label')}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 form-group">
            <label>{t('longitude_label')}</label>
            <input
              type="text"
              name="lng"
              value={long}
              onChange={(e) => setLong(e.target.value)}
              id="lngVal"
              className="form-control"
              placeholder={t('longitude_label')}
            />
          </div>
          <div className="col-md-6 form-group">
            <label>{t('latitude_label')}</label>
            <input
              type="text"
              name="lat"
              value={lat}
              onChange={(e) => setLat(e.target.value)}
              id="latVal"
              className="form-control"
              placeholder={t('latitude_label')}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 form-group">
            <label>{t('Embed Link')}</label>
            <input
              type="text"
              name="embed_code"
              value={embedCode}
              onChange={handleEmbedCodeChange}
              className="form-control"
              placeholder={t('Embed Link')}
            />
          </div>
        </div>
      </div>
      {/* {reverseGeocodingError && <div>Error: {reverseGeocodingError}</div>} */}
    </Fragment>
  );
};

export default Locationtab;
