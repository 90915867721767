// import React from "react";
// import Modal from "react-modal";
// // import { useMediaQuery } from "react-responsive";
// import { Button } from "antd";
// import { RxCross2 } from "react-icons/rx";

// const CommentModal = ({
//   isOpen,
//   closeModal,
//   selectedReview,
//   backgroundColor,
//   textColor,
// }) => {
//   // const isMobile = useMediaQuery({ maxWidth: 800 });
//   const getWidth = () => {
//     return window.innerWidth <= 800 ? "80%" : "50%";
//   };
//   // console.log("object", selectedReview);

//   const modalStyle = {
//     overlay: {
//       backgroundColor: "rgba(0, 0, 0, 0.5)",
//     },
//     content: {
//       position: "absolute",
//       top: "50%",
//       left: "50%",
//       right: "auto",
//       bottom: "auto",
//       // height: isMobile ? "" : "414px",
//       width: "792px",
//       // width: "80%",
//       marginRight: "-50%",
//       transform: "translate(-50%, -50%)",
//       background: backgroundColor ? backgroundColor : "white",
//       display: "flex",
//       flexDirection: "column",
//       justifyContent: "space-between",
//       borderRadius: "20px",
//       alignItems: "center",
//       position: "relative",
//     },
//   };

//   return (
//     <>
//     {isOpen && <div
//       // isOpen={isOpen}
//       // onRequestClose={closeModal}
//       // ariaHideApp={false}
//       style={modalStyle}
//       className="reviewModal"
//     >
//       {/* Modal Content */}

//       {isOpen && selectedReview && (
//         <div
//           style={{
//             padding: "30px 15px",
//             marginBottom: "15px",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             flexDirection: "column",
//             borderRadius: "20px",
//             marginTop: "3vh",
//           }}
//         >
//           <button
//             style={{
//               position: "absolute",
//               right: "18px",
//               top: "18px",
//               borderRadius: "20px",
//               width: "30px",
//               height: "30px",
//               backgroundColor: "#E7E6E8",
//               padding: "7px",
//               display: "flex",
//               justifyContent: "end",
//             }}
//             onClick={closeModal}
//           >
//             <RxCross2 onClick={closeModal} className="text-center" />
//           </button>
//           <img
//             src={selectedReview && selectedReview?.img}
//             alt="No Imamge"
//             style={{
//               width: "116px",
//               height: "116px",
//               borderRadius: "55px",
//               marginBottom: "10px",
//             }}
//           />

//           <h6
//             className="text-center"
//             style={{
//               marginBottom: "15px",
//               fontFamily: "Poppins, sans-serif",
//               color: textColor ? textColor : "black",
//             }}
//           >
//             {selectedReview?.userName}
//           </h6>
//           <h6
//             className="text-center "
//             style={{
//               marginBottom: "15px",
//               fontFamily: "Poppins, sans-serif",
//               color: textColor ? textColor : "black",
//             }}
//           >
//             {selectedReview.companyname}
//           </h6>
//           <p
//             className="text-center "
//             style={{
//               marginBottom: "15px",
//               fontFamily: "Poppins, sans-serif",
//               color: textColor ? textColor : "black",
//             }}
//           >
//             {selectedReview.destination}
//           </p>
//           <p
//             className="text-center "
//             style={{
//               marginBottom: "15px",
//               fontFamily: "Poppins, sans-serif",
//               color: textColor ? textColor : "black",
//             }}
//           >
//             {selectedReview.comment}
//           </p>
//         </div>
//       )}

//       {/* <div style={{ display: "flex", alignItems: "center" }}>
//         <button onClick={closeModal} style={closebtn}>
//           Close{" "}
//         </button>
//       </div> */}
//     </div>}
//     </>

//   );
// };

// export default CommentModal;



// import React from "react";
// import { Button } from "antd";
// import { RiCloseLine } from "react-icons/ri"; // Close icon

// const CommentModal = ({
//   isOpen,
//   closeModal,
//   selectedReview,
//   backgroundColor,
//   textColor,
// }) => {
//   const modalStyle = {
//     position: "fixed",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     background: backgroundColor ? backgroundColor : "white",
//     borderRadius: "20px",
//     padding: "20px",
//     maxWidth: "80vw", // Adjusted for responsiveness
//     width: "600px", // Fixed width or adjust as needed
//     maxHeight: "80vh", // Adjusted for responsiveness
//     overflowY: "auto", // Enable scrolling if content exceeds maxHeight
//     boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
//     zIndex: 999,
//     display: isOpen ? "block" : "none", // Toggle visibility based on isOpen
//   };

//   return (
//     <>
//       {selectedReview && isOpen && (
//         <div style={modalStyle} className="reviewModal">
//           <button
//             style={{
//               position: "absolute",
//               top: "10px",
//               right: "10px",
//               background: "none",
//               border: "none",
//               cursor: "pointer",
//               color: "#999",
//               fontSize: "24px",
//             }}
//             onClick={closeModal}
//           >
//             <RiCloseLine />
//           </button>
//           {selectedReview && (
//             <div style={{ textAlign: "center" }}>
//               <img
//                 src={selectedReview.img}
//                 alt="No Image"
//                 style={{
//                   width: "100px",
//                   height: "100px",
//                   borderRadius: "50%",
//                   marginBottom: "10px",
//                 }}
//               />
//               <h3 style={{ fontFamily: "Poppins, sans-serif", color: textColor }}>
//                 {selectedReview.userName}
//               </h3>
//               <p style={{ fontFamily: "Poppins, sans-serif", color: textColor }}>
//                 {selectedReview.companyname}
//               </p>
//               <p style={{ fontFamily: "Poppins, sans-serif", color: textColor }}>
//                 {selectedReview.destination}
//               </p>
//               <p style={{ fontFamily: "Poppins, sans-serif", color: textColor }}>
//                 {selectedReview.comment}
//               </p>
//               {/* <Button onClick={closeModal}>Close</Button> */}
//             </div>
//           )}
//         </div>
//       )}
//     </>
//   );
// };

// export default CommentModal;


import React from "react";
import { Button } from "antd";
import { RiCloseLine } from "react-icons/ri"; // Close icon

const CommentModal = ({
  isOpen,
  closeModal,
  selectedReview,
  backgroundColor,
  textColor,
}) => {
  const modalStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: backgroundColor ? backgroundColor : "white",
    borderRadius: "20px",
    padding: "20px",
    maxWidth: "80vw", // Adjusted for responsiveness
    width: "600px", // Fixed width or adjust as needed
    maxHeight: "80vh", // Adjusted for responsiveness
    overflowY: "auto", // Enable scrolling if content exceeds maxHeight
    boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
    zIndex: 999,
    display: isOpen ? "block" : "none", // Toggle visibility based on isOpen
  };

  return (
    <>
      {selectedReview && isOpen && (
        <div style={modalStyle} className="reviewModal">
          <button
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              background: "none",
              border: "none",
              cursor: "pointer",
              color: "#999",
              fontSize: "24px",
            }}
            onClick={closeModal}
          >
            <RiCloseLine />
          </button>
          {selectedReview && (
            <div style={{ textAlign: "center" }}>
              <img
                src={selectedReview.img}
                alt="No Image"
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%",
                  marginBottom: "10px",
                  display: "block",
                  margin: "0 auto", // Centering the image horizontally
                }}
              />
              <h4 style={{ fontFamily: "Poppins, sans-serif", color: textColor }}>
                {selectedReview.userName}
              </h4>
              <p style={{ fontFamily: "Poppins, sans-serif", color: textColor }}>
                {selectedReview.companyname}
              </p>
              <p style={{ fontFamily: "Poppins, sans-serif", color: textColor }}>
                {selectedReview.destination}
              </p>
              <p style={{ fontFamily: "Poppins, sans-serif", color: textColor }}>
                {selectedReview.comment}
              </p>
              {/* <Button onClick={closeModal}>Close</Button> */}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CommentModal;
