import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import Header from "../layouts/Header";
import Breadcrumb from "../layouts/Breadcrumb";
import Footer from "../layouts/Footerthree";
import Content from "../sections/ThinkToKnow/EstateContent";
import Download from "../layouts/App";
import BsInfo from "../sections/services/Bsinfo";
import { useTranslation } from "react-i18next";

const Estate = () => {
  const {t} = useTranslation();

  let data = {
    title:t("Thailand Real estate Laws"),
    para:t("It is not possible"),
    picture:"/assets/img/listings-list/Laws.jpg"
  }

  return (
    <Fragment>
      <Helmet>
        <title>
          Buy Home For Less | Thailand real estate laws
        </title>
        <meta name="description" content="#" />
      </Helmet>
      <Header />
      <Breadcrumb breadcrumb={{ pagename: t("Thailand Real estate Laws")}} />
    <BsInfo data={data} />
      {/* <Content /> */}
      <Download />
      <Footer />
    </Fragment>
  );
};

export default Estate;
