import React from "react";
import { useTranslation } from "react-i18next";

const PrivacyContent = () => {
  const {t}=useTranslation();
  return (
   <div className="container">
     <div className="my-16  py-10 rounded-2xl text-center border">
      <div>
        <h3 className="mb-8 text-themeColor">
       {t("privacy_policy")}
        </h3>
      </div>
      <div
        className="my-20 text-medium font-medium"
        style={{ paddingLeft: "30px", paddingRight: "30px" }}
      >
        <p className="my-8">
          {t("Experience the beauty")}
        </p>
      </div>
    </div>
   </div>
  );
};

export default PrivacyContent;
