function Buyimg() {
    return (
        <div className="container">
            <div className="d-flex justify-center ">
                <img style={{height:"400px"}}  src={require("../../../assets/img/buy-img.png")} alt="infographic" />
            </div>
        </div>
    );
}

export default Buyimg;