// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    English: {
      translation: {
        heading: `We are the top Real Estate firm in Thailand that can help you find the best deal on the market`,
        "greeting": `Buy Home for Less (BHFL) is a subsidiary of Mstar Capital group, established since 2003 in the property
          development and finance business. Our specialty is catering to property that are below the market value.
          We work directly with distressed property and NPL, through major banks worldwide, to get our clients the best
          price. We not only work directly with financial institutions, but also with property owners who are in a financial 
          problems and need to sell their property fast`,
        "Most Popular": "Most Popular",
        "High to low": "Price: High - Low",
        "low to High": "Price: Low - High",
        "Number of baths": "Number of baths",
        "No Deals Available": "No Deals Available",
        "FansAirCondition":"Air Condition",
        "solarPower":"SolarPower",
        "seaview":"View",
        "Elevator":"Elevator",
        "Pent House":"Pent House",
        "electrcity":"Paid Electricity",
        "paidwater":"Paid Water",
        "Number of beds": "Number of beds",
        // Add more key-value pairs for English
        "BBQ Area": "BBQ Area",
        "Community Hall": "Community Hall",
        "Swimming Pool": "Swimming Pool",
        "Kitchen": "Kitchen",
        "Gym": "Gym",
        "Mini Mart": "Mini Mart",
        "Library": "Library",
        "Children Playground": "Children Playground",
        "Tennis Court": "Tennis Court",
        "Jacuzzi": "Jacuzzi",
        "Sauna": "Sauna",
        "Party Hall": "Party Hall",
        "Guest Parking": "Guest Parking",
        "Beds": "Beds",
        "Bathrooms": "Bathrooms",
        "Laundry Room": "Laundry Room",
        "Meeting room": "Meeting room",
        "Game Room": "Game Room",
        "Jacuzzi": "Jacuzzi",
        "Sauna": "Sauna",
        "Maid Room": "Maid Room",
        "Guest room": "Guest room",
        "Parking": "Parking",
        "Dining Room": "Dining Room",
        "Living Room": "Living Room",
        "BBQ Area": "BBQ Area",
        "Community Hall": "Community Hall",
        "Swimming Pool": "Swimming Pool",
        "Gym": "Gym",
        "Mini Mart": "Mini Mart",
        "Library": "Library",
        "Children Playground": "Children Playground",
        "Tennis Court": "Tennis Court",
        "Jacuzzi": "Jacuzzi",
        "Sauna": "Sauna",
        "Party Hall": "Party Hall",
        "Guest Parking": "Guest Parking",
        "Beds": "Beds",
        "Bathrooms": "Bathrooms",
        "Laundry Room": "Laundry Room",
        "Meeting room": "Meeting room",
        "Game Room": "Game Room",
        "Jacuzzi": "Jacuzzi",
        "Sauna": "Sauna",
        "Maid Room": "Maid Room",
        "Guest room": "Guest room",
        "Parking": "Parking",
        "Dining Room": "Dining Room",
        "Living Room": "Living Room",

        "Appartment": "Appartment",
        "li": `Our client will get the best price possible and gain profit when they purchase property from our professional real estate agent.
          Our real estate agent are fully trained and professional in the real estate field, which will ensure that your real estate
           transaction is smooth and secure. `,
        "li2": `You can be rest assured that when you buy property from us, you will get the best price and services, and in a quick turnaround.`,
        "GetStarted": ` Get Started`,
        "MoreInfo": `More Info`,
        "Moo": "Moo",
        "Featured Leased": "Featured, Leased",
        "Any amount": "Any amount",
        "Bangkok": "Bangkok",
        "For Sale Sold": "For Sale, Sold",
        "New Addition": "New Addition",
        "Karachi Division": "Karachi Division",
        //    service vala
        "Services": "Services",
        "Complaint Form": "Complaint Form",
        "need": "You Need It, We Got It",
        "OurOffice": "Our Office",
        "WorkingWithTopReal": "You're Working With Top Real Estate Firm In Thailand",
        "FindOutMore": "Buy a home",
        "sellmore": "Sell a home",
        "pick_agent": "Pick the right agent for you",
        "agent_description": "Answer a few questions and get a list of top agents in your area. Compare their costs and services, and choose the right agent for you.",
        "get_offers": "Get offers for your home",
        "offers_description": "Answer a few questions and get a list of top agents in your area. Compare their costs and services, and choose the right agent for you.",
        "visit_marketplace": "Submit your Listing",
        "track_value": "Track your home value",
        "track_description": "Answer a few questions and get a list of top agents in your area. Compare their costs and services, and choose the right agent for you.",
        "search_placeholder": "Search...",
        "sellerGuides": "Seller Guides",
        "downloadOurApp": "Download Our App",
        "dummyText": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
        "googlePlay": "Google Play",
        "desfaq": "If you have questions about houses and land or want to sell your home through us. Please contact us at \n \n Tel +66-097-392-4632",
        "office_location": "Easily locate our office for assistance and services. ",
        "appStore": "App Store",
        "submitListing": "Submit Listing",
        "login": "Login",
        "Floor": "Floor",
        "Room Floor":"Room Floor",
        "signup": "Signup",
        "basicInformation": "Basic Information",
        "gallery": "Gallery",
        "location": "Location",
        "Property Amenities": ' Amenities',
        "propertyAmenity": "Property Amenity",
        "details": "Details",
        "agentDashboard": "Agent Dashboard",
        "seo": "SEO",
        "PropertyDescription": "Property Description",
        "PropertyName": "Property Name",
        "PropertyStatus": "Property Status",
        "PropertyType": "Property Type",
        "SalePrice/RentalPrice": " Sale Price",
        "RentalPeriod": "Rental Period",
        "RentalPrice": "Rental Price",
        "BuildingArea": "Building Area (SQM) (optional)",
        "LandArea": "Land Area (SQM) (optional)",
        "PropertyVideo": "Property Video (Optional)",
        "Property Thumbnail": "Property Thumbnail",
        "Choose File": "Choose File",
        "Property Gallery": "Property Gallery",
        "Drop files here or click to upload.":
          "Drop files here or click to upload.",
        "This is just a demo dropzone. Selected files are not actually uploaded.":
          "This is just a demo dropzone. Selected files are not actually uploaded.",
        "You can upload up to 15 images for your listing":
          "*You can upload up to 15 images for your listing",
        "Listing images should be at least 620x480 in dimensions":
          "*Listing images should be at least 620x480 in dimensions",
        "drag_and_drop_notice":
          "Drag and drop the marker to identify your <b>Latitude</b> and <b>Longitude</b>",
        "full_address_label": "Full Address (optional)",
        "country_label": "Country",
        'Tumbol': "Tumbol",
        'city_label': "Amphor/City",
        "province_label": "Province/State",
        "zipcode_label": "Zipcode",
        "longitude_label": "Longitude (Optional)",
        "latitude_label": "Latitude (Optional)",
        "Property ID": "Property ID",
        "Beds": "Beds",
        "Number of Beds": "Number of Beds",
        "Bathrooms": "Bathrooms",
        "Number of Bathrooms": "Number of Bathrooms",
        "Building Floor": "Building Floor",
        "Condition": "Condition",
        "Used": "Used",
        "New": "New",
        "Year Built": "Year Built",
        "Land size": "Land size",
        "Property Year Built": "Property Year Built",
        "Neighborhood": "Neighborhood",
        "Property Neighborhood": "Property Neighborhood",
        "Living Room": "Living Room",
        "Yes": "Yes",
        "No": "No",
        "Agent Details":"Agent Details",
        "Dining Room": "Dining Room",
        "Laundry Room": "Laundry Room",
        "Meeting room": "Meeting room",
        "Game Room": "Game Room",
        "Jacuzzi": "Jacuzzi",
        "Sauna": "Sauna",
        "Maid Room": "Maid Room",
        "Guest room": "Guest room",
        "Parking": "Parking",
        "1-2 Car Garage": "1-2 Car Garage",
        "3 Car Garage": "3 Car Garage",
        "4 Car Garage": "4 Car Garage",
        "5 and More": "5 and More",
        "Lot Size": "Room Size (SQM)",
        "View": "View",
        "Beach": "Beach",
        "Mountain": "Mountain",
        "Lake": "Lake",
        "Cities Life": "Cities Life",
        "Rural hills": "Rural hills",
        "enterNearby": "Enter the Nearby",
        "buildingarea": "Building area",
        "selectType": "Select Type",
        "name": "Name",
        "FloorImages":"Floor Images",
        "distance": "Distance (km)",
        "addRow": "+ Add Row",
        "delete": "Delete",
        "edit": "Edit",
        "agree": " I Agree to the term's ;  Conditions of Property Submission",
        "Agent Thumbnail Picture": "Agent Thumbnail Picture",
        "Choose File": "Choose File",
        "ThumbImage": "ThumbImage",
        "Name": "Name",
        "Last Name": "Last Name",
        "Tel Number": "Tel Number",
        "WhatsApp": "WhatsApp",
        "Province": "Province",
        "Country": "Country",
        "Title": "Title",
        "Slug": "Slug",
        "Meta Tag": "Meta Tag",
        "Meta keywords": "Meta keywords",
        "I Agree to the terms & Conditions of Property Submission":
          "I Agree to the terms & Conditions of Property Submission",
        "fullName": "Full Name",
        "subject": "Subject",
        "emailAddress": "Email Address",
        "yourMessage": "Your Message",
        "sendMessage": "Send Message",
        "send": "Send",
        "placeholderFullName": "Full Name",
        "placeholderSubject": "Subject",
        "placeholderEmailAddress": "Email Address",
        "placeholderYourMessage": "Type your message...",
        "newYork": "New York",
        "usa": "USA",
        "usaAdd": "Cecilia Chapman 711-2880 Nulla St.",
        "propertyId": "Property Id",
        "tbilsi": "Tbilsi",
        "georgia": "Georgia",
        "moscow": "Moscow",
        "russia": "Russia",
        "cairo": "Cairo",
        "egypt": "Egypt",
        "locationAddress": "{{name}} {{address}}",
        "phoneLink": "(478) 339 120",
        "tbilsiAdd": "Aaron Hawkins 5587 Nunc. Avenue",
        "Monthly Mortage": "Monthly Mortage",
        "moscowAdd": "Lawrence Moreno 935-9940 Tortor. Street",
        "prevListing": "Prev Listing",
        "nextListing": "Next Listing",
        "prevLocation": "Theodore Lowe, Azusa New York 39531",
        "nextLocation": "Cecilia Chapman, Mankato Mississippi 96522",
        "homeValue": "Home Value",
        "downPayment": "Down Payment",
        "loanAmount": "Loan Amount",
        "interestRate": "Interest Rate",
        "loanDuration": "Loan duration (years)",
        "calculate": "Calculate",
        "enterHomeValue": "Enter the value of the home",
        "enterDownPayment": "Enter your funds",
        "enterInterestRate": "Enter your interest rate",
        "enterLoanDuration": "Enter the duration of your loan in years",
        "fundsNeeded": "Funds needed",
        "fullName": "Full Name",
        "emailAddress": "Email Address",
        "phoneNumber": "Phone Number",
        "date": "Date",
        "Park": "Children Playground",
        "Parks": "Park",
        "typeYourComment": "Type your comment...",
        "callAgent": "Call Agent",
        "lotSize": "Room Size ",
        "viewListings": "View Listings",
        "saveAgent": "Save Agent",
        "moveOnlineTitle": "WE HAVE THOUSAND OF PROPERTY",
        "moveOnlineTitle2": "THAT ARE UNDER MARKET VALUE",
        "searchDescription": "NOW YOU CAN SEARCH FOR BEST PROPERTY AT LOW PRICE IN THAILAND",
        "searchPlaceholder": "Search by location, station, condo name, or keyword",
        "buyRentSell": "Buy/Rent/Sell",
        "Residential": "Residential",
        "hotel": "Hotel",
        "Any floor": "Any floor",
        "Commercial": "Commercial",
        "Resort": "Resort",
        "Land": "Land",
        "Warehouse & Factories": "Warehouse & Factories",
        "any": "Any",
        "Top Selling": "Top Selling",
        "buy": "Buy",
        "sell": "Sell",
        "propertyType": "Property Type",
        "anyType": "Any type",
        "searchButton": "Search",
        "minPrice": "Min Price",
        "maxPrice": "Max Price",
        "beds": "Beds",
        "bath": "Bath",
        "buildingArea": "Building area",
        "landArea": "Land area",
        "browseByCategory": "Browse By Category",
        "Category": "Category",
        "ourMotto": "Our Moto",
        "chooseToWorkWithUs": "WHY CHOOSE TO WORK WITH US",
        "ourTopListings": "Our Top Listings",
        "No top list available": "No top list available",
        "viewDetails": "View Details",
        "trending": "Trending",
        "latestNews": "Latest News",
        "bestDealOfTheMonth": "Best Deal of the Month",
        "testimonials": "Testimonials",
        "whatPeopleAreSaying": "What Are People Saying",
        "createAnAcresAccount": "Create An Account Buy Home For Less",
        "User not found": "User not found",
        "username": "Username",
        "selectEmailOrPhoneNumber": "Select Email OR Phone Number",
        "propertySize": "Property Size",
        "mobileNumber": "Mobile Number",
        "password": "Password",
        "WeChatID": "WeChat ID",
        "WhatsappID": "Whatsapp ID",
        "lineID": "Line ID",
        "telegramID": "Telegram ID",
        "user": "User",
        "buyer": "Buyer",
        "Roomsize": "Room Size (SQM)",
        "seller": "Seller",
        "Satisfactory":"Satisfactory",
        "Fixer upper":"Fixer upper",
        "agent": "Agent",
        "Sell": "Sell",
        "buildingFloor": "Building Floor",
        "register": "Register",
        "whatsapp": 'Whatsapp',
        "yearBuilt": "Year Built",
        "PrintListing": "Print Listing",
        "continueWithFacebook": "Continue with Facebook",
        "continueWithGoogle": "Continue with Google",
        "alreadyHaveAccount": "Already have an account?",
        "login": "Login",
        "bedRooms": "BedRooms",
        "logIntoAcres": "Log Into Buy Home For Less",
        "forgotIntoAcres": "Forgot Into Acress",
        "selectEmailOrPhone": "Select Email OR Phone Number",
        "select": "Select",
        "reportingStayDescription": "Reporting Stay Description",
        "summaryTitle": "Summary",
        "summaryDescription": "Summary Description",
        "renewExtensionOfStay": "Renew Extension Of Stay",
        "emailAddress": "Email Address",
        "mobileNumber": "Mobile Number",
        "password": "Password",
        "forgotPassword": "Forgot Password?",
        "login": "Login",
        "Any Range": "Any Range",
        "bathrooms": "Bathrooms",
        "PropertyVideolink": "Property Video Youtube link",
        "lineid": "Line Id",
        "propertyNotFoundHeading": "Property Not Found",
        "propertyNotFoundDescription": "Sorry, the specific type of property you are searching for is currently not available on our website. Please check back later or contact us for further assistance.",
        "Newpassword": "New Password",
        "confirmPassword": "Confirm Password",
        "Confirm Password is required": "  ⚠️ Confirm Password is required ",
        "Buying property in Thailand": `Buying property in Thailand as a foreigner can be daunting and challenging. There are many factors in the Thai laws in which you are well-informed, but we can help in getting you a freehold property such as condo, but if you looking to find villa home or any other properties then we can arrange hundred of property for you to choose. We can arrange a leasehold property for up to 90 years on certain type of 
        property and if you looking to invest in commercial property then we can arrange for you to own a 100% of commercial property.`,
        "Any Location": "Any Location",
        "We can help you get": `We can help you get a long-term visa for 10 years and you can extended as it expire. 
        Thai immigration laws and change in the recent years that help foreigner to live in Thailand for longer period of time. 
        Once you are approved for a 10 years visa then you can apply for many benefit that are provided through the Thais 
        government. We can arrange and find you the right investment or any other health care for you without any obstacle .`,
        "This User Is Already Exits": "This User Is Already Exits",
        "It is not possible": `It is not possible for foreigners to own land in their name. You can own
         land if you are married to a Thai national. Foreign nationals who are the lawful heir to
          inherit land can do so, but are required to sell the land within one year of the acquisition of the land. .`,

        "Person": `Person 50 years of age and older may apply for the Thailand retirement visa. 
       This makes your stay in Thailand hassle-free. You can apply for a retirement visa in Thailand with the steps below.`,


        "Don’t walk away": "Don’t walk away from your home if you are facing a financial problem. If you are facing foreclosure due to any circumstance. We can help you solve the problem. We deal with more than 500 bank and financial institution worldwide that help you find the best loan for you even through your credit already bad. We have a financial institution that are specialize with distress property and they able to offer you many program that fit with your financial budget. We even offer program that help bring your loan current and freeze on your monthly mortgage payment until you can get back on your feet. ",
        "Let us help you": "Let us help you resolve your temporary financial problem, so you can keep your home.",

        "Buy home for": "Buy home for less can help you with financial planning to help you ready for next dream home. We give you a free consultant to help you save money and know when its ready for you to buy your next home. We can offer you many program that you don’t need to use your saving for a down payment on your home. We will work together with you in managing your budget that can help you make your mortgage payment comfortably.",

        "If you are looking": "If you are looking for the highest return on investment, we can help you with the best solution to help you find the best investment that fits your budget and provide you with Peace of mind that every baht you put down Capital will be safe and risky. Less risky, the planning team Our investment has helped Monk receive rewards from the highest investment,,",
        "If you enjoying": "If you enjoying a passive income but don’t want to deal with tenant. We can help you in finding new tenant or evicted them. We can fully manage your property at the condition at the highest standard. We collect rent and do a fully property maintenance for you. You don’t have to worry about your property. We can take care of all necessary step that can get your rent on time and finding you the right tenant that will love your property the same as you do. ",


        "Experience the beauty": `Experience the beauty and culture of Thailand with a long-term visa.
        Perfect for those looking to relocate or invest in real estate, this
        visa allows for extended stays of up to one year. With a long-term
        visa, you'll have the opportunity to fully immerse yourself in the
        Thai lifestyle and take advantage of all the country has to offer.
        Whether you're looking to retire, start a business, or just enjoy a
        prolonged vacation, a long-term visa in Thailand is the perfect
        choice. Contact us today to learn more about obtaining your long-term
        visa and finding the perfect property to suit your needs.`,

        "Sorry, the ":
          "Sorry, the specific type of property you are searching for is currently not available on our website. Please check back later or contact us for further assistance",





        //footer
        "foothead": "Buy Home For Less",
        "menu": "Menu",
        "find_home": "Find Home",
        "find_home2": "Find Your Home",
        "add_listing": "Add Listing",
        "listing": "Listing",
        "ischool": "International School",
        "become_agent": "Become our Agent",
        "about_us": "About Us",
        "complaint": "Complaint",
        "blogs_news": "Blogs/News",
        "sell_property": "Sell your property to us",
        "contact_us": "Contact us",
        "legal": "Legal",
        "privacy_policy": "Privacy Policy",
        "refund_policy": "Refund Policy",
        "cookie_policy": "Cookie Policy",
        "terms_condition": "Terms and Condition",
        "find_agent_worldwide": "Find our agent worldwide",
        "job": "Job",
        "newsletter": "Newsletter",
        "newsletter_description": "Join our newsletter today, and get the best flats that we have to offer.",
        "newsletter_placeholder": "Email Address",
        "join_newsletter": "Join Newsletter",
        "information": "Information",
        "aboutUs": "About Us",
        "contactUs": "Contact Us",
        "services": "Services",
        "About Us": "About Us",
        "Name is Required": "⚠️ Name is Required",
        "Please Select Option": "⚠️ Please Select Option",
        "Please Select Country Code": "⚠️ Please Select Country Code",
        "Address is Required": "⚠️ Address is Required",
        "Last Name is Required": "⚠️ Last Name is Required",
        "Tumbol is Required": "⚠️ Tumbol is Required",
        "City/Amphor is Required": "⚠️ City/Amphor is Required",
        "States/Province is Required": "⚠️ States/Province is Required",
        "Country is Required": "⚠️ Country is Required",
        "Line Id is Required": " ⚠️ Line Id is Required",
        "WhatsApp number must only contain digits": " ⚠️ WhatsApp number must only contain digits",
        "WhatsApp number is too short": "⚠️ WhatsApp number is too short",
        "WhatsApp number is too long": " ⚠️ WhatsApp number is too long",
        "WhatsApp Number is Required": " ⚠️ WhatsApp Number is Required",
        "Wechat is Required": " ⚠️ Wechat is Required",
        "Tel Number is Required": " ⚠️ Tel Number is Required",
        "WeChat": "We Chat",
        "Too short": " ⚠️ Too short",
        "Too long": " ⚠️ Too long",
        "Video Url is Required": " ⚠️ Video Url is Required",
        "image is required": " ⚠️ image is required",
        "At least one image is required": " ⚠️ At least one image is required",
        "File is required": " ⚠️ File is required",
        "View Agencies": "View Agencies",
        "Invalid email": "⚠️ Invalid email",
        "WhatsApp Number": "WhatsApp Number",
        "Email is required": "⚠️ Email is required",
        "Phone number must only contain digits": "Phone number must only contain digits",
        "Phone number is too short": "Phone number is too short, it should be at least 10 digits",
        "Phone number is too long": "Phone number is too long, it should be less than 15 digits",
        "Phone number is required": "⚠️ Phone number is required",
        "Password must contain": "Password must contain at least one capital letter, one number, and one special symbol",
        "Password must be at least 8 characters long": "Password must be at least 8 characters long",
        "Password is required": "⚠️ Password is required",
        "Please Select": "⚠️ Please Select",
        "please Select": "Please Select",
        "whatsappid": "WhatsApp ID",
        "Buying a Home?": "Buying a Home?",
        "Any Environment": "Any Environment",
        "Urban": 'Urban',
        "Cities": "Cities",
        "Any Land Area": "Any Land Area",
        "All Field are Required": "All Field are Required",
        "How Do You Sell a House": "How Do You Sell a House",
        "Embed Link": "Embed Link",
        "Any listing agent that you meet with should have a plan, with concrete details, to sell your house. homelight logo. Save thousands when buying a home": "Any listing agent that you meet with should have a plan, with concrete details, to sell your house. homelight logo. Save thousands when buying a home",
        "Save": "Save",
        "It has been saved successfully": "It has been saved successfully",
        "Floor Plan": "Floor Plan",
        "Floor Images": "Floor Images (optional)",
        "No floor images": "No floor images",
        "Select the value": "Select the value",
        "Furnished": "Furnished",
        "Type of Furnish": "Type of Furnish",
        "Maintenance Fee": "Maintenance Fee",
        "Stove": "Stove",
        "Refrigerator": "Refrigerator",
        "Air Condition": "Air Condition",
        "Bed": "Bed",
        "TV": "TV",
        "Agent Info": "Agent Info",
        "City": "City",
        "SQMBuildingSize":"Building Size (SQM )",
        "Agent Service":"Agent Service Area",
        "ROOM AREA":"ROOM AREA",
        "Room#":"Room#"
      },
    },
    Chinese: {
      translation: {
        "whatsapp": 'Whatsapp',
        "Satisfactory":"满意的",
        "Fixer upper":"固定器上部",
        "User not found": "未找到用户",
        "Property Amenities": '便利设施',
        "This User Is Already Exits": "该用户已退出",
        "image is required": " ⚠️ 图片为必填项",
        "FansAirCondition":"空调",
        "solarPower":"太阳能",
        "seaview":"看法",
        "Elevator":"电梯",
        "electrcity":"付费电费",
        "paidwater":"付费水",
        "Save": "节省",
        "Agent Service":"代理人服务区",
        "SQMBuildingSize":"平方米建筑面积",
        "heading": "我们是泰国顶级的房地产公司，可以帮助您找到市场上最优惠的价格",
        "Sell": "卖",
        "PropertyVideolink": "房产视频 YouTube 链接",
        "Pent House":"阁楼",
        "WhatsApp Number": "WhatsApp 号码",
        "Embed Link": "嵌入链接",
        "How Do You Sell a House": "你如何卖房子",
        "Any listing agent that you meet with should have a plan, with concrete details, to sell your house. homelight logo. Save thousands when buying a home": "您遇到的任何上市经纪人都应该有一个出售您的房子的计划，并包含具体细节。家居灯标志。买房省几千",
        "greeting": "Buy Home for Less (BHFL) 是 Mstar Capital 集团的子公司，成立于 2003 年，从事房地产开发和金融业务。我们的专长是为低于市场价值的房产提供服务。我们通过全球主要银行直接与不良资产和不良贷款合作，为客户提供最优惠的价格。我们不仅直接与金融机构合作，还与遇到财务问题并需要快速出售房产的业主合作",
        "li": "当我们的客户从我们的专业房地产经纪人那里购买房产时，他们将得到尽可能最好的价格并获得利润。我们的房地产经纪人在房地产领域受过全面培训且专业，这将确保您的房地产交易顺利、安全。",
        "li2": "您可以放心，当您向我们购买房产时，您将获得最优惠的价格和服务，并且周转速度很快。",
        "GetStarted": "开始使用",
        "Parks": "公园",
        "MoreInfo": `更多信息`,
        "Any floor": "任何楼层",
        "lineid": "線ID",
        "Agent Details":"代理详情",
        "whatsappid": "WhatsApp ID",
        "Appartment": "公寓",
        "Services": "服务",
        "hotel": "酒店",
        "Any Range": "任意范围",
        "High to low": "价格：从高到低",
        "low to High": "价格：低-高",
        "Number of baths": "浴池数量",
        "Number of beds": "床位数",
        "Most Popular": "最受欢迎",
        "For Sale Sold": "出售 已售",
        "need": "您想要它，我们理解。",
        "Top Selling": "最畅销",
        "Any Location": "任何地点",
        "Real Estate Services": "房地产服务",
        "Our Real estate service is second to none. We are a one-stop real estate service firm in Thailand that offer various type of property from regular property to high-end luxury. We also offer distress property such as Foreclosure, bank-owned, NPA and etc. that are not listed on the market, so you will get the under-market price.": "我们的房地产服务是首屈一指的。我们是泰国的一站式房地产服务公司，提供从普通房产到高端豪华房产的各种类型的房产。我们还提供未在市场上列出的不良财产，例如止赎权、银行拥有的、不良资产等，因此您将获得低于市场的价格。",
        "Moo": "哞",
        "New Addition": "新增加",
        "Featured Leased": "精选、租赁",
        "Real Estate Development": "房地产开发",
        "Our company worked with real investor worldwide to help them invest in to Thailand real estate. We offer service from consultant, finding you the land, development, getting you a building permit and construction. Our client will feel assure that invest in Thailand real estate they will get the highest return of their investment. ": "我们公司与世界各地的真实投资者合作，帮助他们投资泰国房地产。我们提供顾问服务，为您寻找土地、开发、获得建筑许可证和施工。我们的客户将确信投资泰国房地产，他们将获得最高的投资回报。",
        "Complaint Form": "投诉表格",
        "Property Management Service": "物业管理服务",
        "Many of our client is investor and they only buy property for investment purpose. We give a full management service from Finding tenant, fund collection, property maintenance, eviction and housekeeping. You don’t have to worry about your investment. Fund will be credited to your account every month.": "我们的许多客户都是投资者，他们购买房产只是为了投资目的。我们提供从寻找租户、资金收集、物业维护、驱逐和家政的全套管理服务。您不必担心您的投资。资金将每月存入您的账户。",
        "Any amount": "任何数量",
        "Investment Consulator": "投资领事馆",
        "We offer free service in real estate consultant. Client will meet with our finance and real estate specialist to guide you through out the investment process. You will get a better idea and solution to invest. Our team will help you make the right decision and gain a better return on your investment":
          "我们提供免费的房地产顾问服务。客户将与我们的金融和房地产专家会面，指导您完成投资过程。您将获得更好的投资想法和解决方案。我们的团队将帮助您做出正确的决定并获得更好的投资回报",

        "Business Merging and Acquisition": "企业并购",
        "Many of client not only invest in buying property, but they also want to buyout an existing business with potential growth. We can help find the right business that fit our client criteria. Our client can make money while living like a king in Thailand.": "许多客户不仅投资购买房产，还想买断具有潜在增长潜力的现有业务。我们可以帮助找到符合客户标准的合适业务。我们的客户可以在泰国像国王一样生活的同时赚钱。",

        "Mortgage Financing": "抵押贷款融资",
        "We have an in-house mortgage company that work directly with more than 200 bank and private investor worldwide. More than 20 years connection with the local bank. We can find you the best rate and get you approved. ": "我们有一家内部抵押贷款公司，直接与全球 200 多家银行和私人投资者合作。与当地银行有 20 多年的联系。我们可以为您找到最优惠的利率并让您获得批准。",



        "title": "您想要它，我们理解",
        "FloorImages":"地板图片",
        "Bangkok": "曼谷",
        "Karachi Division": "卡拉奇分区",
        "OurOffice": "我们的办公室",
        "WorkingWithTopReal": "您正在与泰国顶级房地产公司合作",
        "FindOutMore": "了解更多",
        "Want to look for property that fit your tastes": "想要寻找适合您品味的房产",
        "We have thousand of properties in our inventory, but not all can be add on the website. If you request to see a certain property. We can find it for you! ": "我们的库存中有数千处房产，但并非所有房产都可以添加到网站上。如果您要求查看某处房产。我们可以为您找到它！",
        "pick_agent": "为您挑选合适的经纪人",
        "agent_description": "回答几个问题，获取您所在区域顶级经纪人的列表。比较他们的费用和服务，选择适合您的经纪人。",
        "get_offers": "为您的家获取优惠",
        "offers_description": "回答几个问题，获取您所在区域顶级经纪人的列表。比较他们的费用和服务，选择适合您的经纪人。",
        "visit_marketplace": "提交您的清單",
        "track_value": "跟踪您的房屋价值",
        "track_description": "回答几个问题，获取您所在区域顶级经纪人的列表。比较他们的费用和服务，并选择适合您的经纪人。",
        "search_placeholder": "搜索...",
        "sellerGuides": "卖方指南",
        "downloadOurApp": "下载我们的应用",
        "dummyText": "这只是打印和排版行业的虚拟文本。Lorem Ipsum自1500年以来一直是该行业的标准虚拟文本",
        "googlePlay": "谷歌播放",
        "appStore": "应用商店",
        "submitListing": "提交列表",
        "login": "登录",
        "signup": "注册",
        "basicInformation": "基本信息",
        "gallery": "画廊",
        "location": "位置",
        "propertyAmenity": "物业设施",
        "details": "详细信息",
        "agentDashboard": "代理人仪表板",
        "seo": "搜索引擎优化",
        "PropertyDescription": "物业描述",
        "PropertyName": "物业名称",
        "PropertyStatus": "物业状态",
        "PropertyType": "物业类型",
        "SalePrice/RentalPrice": "销售价格",
        "RentalPeriod": "租赁期限",
        "RentalPrice": "租金",
        "BuildingArea":" 建筑面积（​​平方米）（可选）",
        "LandArea": "土地面积（平方米）（可选）",
        "PropertyVideo": "房产视频可选",

        "Property Thumbnail": "属性缩略图",
        "Floor": "地面",
        "Choose File": "选择文件",
        "Property Gallery": "属性画廊",
        "Drop files here or click to upload.": "将文件拖到这里或点击上传。",
        "This is just a demo dropzone. Selected files are not actually uploaded.":
          "这只是一个演示投放区。选择的文件实际上并没有上传。",
        "You can upload up to 15 images for your listing":
          "*您最多可以上传15张列表图片",
        "Listing images should be at least 620x480 in dimensions":
          "*列表图片尺寸至少应为620x480",
        "drag_and_drop_notice": "拖放标记以标识您的 <b>纬度</b> 和 <b>经度</b>",
        "full_address_label": "完整地址",
        "country_label": "国家",
        "Tumbol": "坦博爾",
        "city_label": "安弗尔/城市",
        "province_label": "省/州",
        "zipcode_label": "邮政编码",
        "longitude_label": "經度（可選",
        "latitude_label": "緯度（可選",

        "BBQ Area": "烧烤区",
        "Yes": "是",
        "No": "否",
        "Party Hall": "宴会厅",
        "Community Hall": "社区厅",
        "Swimming Pool": "游泳池",
        "Gym": "健身房",
        "Guest Parking": "访客停车",
        "Mini Mart": "迷你市场",
        "Library": "图书馆",
        "Monthly Mortage": "每月按揭",
        "Park": "兒童遊樂場",
        "Tennis Court": "网球场",
        "Jacuzzi": "按摩浴缸",
        "Sauna": "桑拿",
        "Line Id": "线路编号",
        "WeChatID": "微信ID",
        "WhatsappID": "Whatsapp 账号",
        "lineID": "线路编号",
        "telegramID": "电报 ID",
        "Property ID": "物业编号",
        "Beds": "床铺",
        "Number of Beds": "床铺数量",
        "Bathrooms": "浴室",
        "Number of Bathrooms": "浴室数量",
        "Building Floor": "建筑楼层",
        "Condition": "状况",
        "Used": "已使用",
        "New": "新建",
        "Year Built": "建造年份",
        "Land size": "土地面积",
        "Property Year Built": "物业建造年份",
        "Neighborhood": "社区",
        "Property Neighborhood": "物业社区",
        "Living Room": "客厅",
        "delete": "刪除",
        "edit": "編輯",

        "Yes": "是",
        "No": "否",
        "Dining Room": "餐厅",
        "Laundry Room": "洗衣房",
        "Meeting room": "会议室",
        "Game Room": "游戏室",
        "Jacuzzi": "按摩浴缸",
        "Sauna": "桑拿",
        "Maid Room": "佣人房",
        "Guest room": "客房",
        "Parking": "停车",
        "1-2 Car Garage": "1-2车库",
        "3 Car Garage": "3车库",
        "4 Car Garage": "4车库",
        "5 and More": "5车及以上",
        "Lot Size": "房間面積",
        "Building area": "建筑面积",
        "No Deals Available": "没有可用的优惠",
        "View": "景观",
        "Beach": "海滩",
        "Mountain": "山景",
        "Lake": "湖景",
        "Cities Life": "城市生活",
        "Rural hills": "乡村山丘",
        "enterNearby": "输入附近",
        "selectType": "选择类型",
        "name": "名称",
        "distance": "距离（公里）",
        "addRow": "添加行",
        "agree": "我同意條款和條件財產條件提交",
        "Agent Thumbnail Picture": "经纪人缩略图",
        "Choose File": "选择文件",
        "ThumbImage": "缩略图",
        "Name": "名字",
        "Last Name": "姓",
        "Tel Number": "电话号码",
        "WhatsApp/WeChat/LINE ID": "微信/微信/線路編號 ",
        "What area do you want to sell real estate?": "您想出售哪个地区的房地产？",
        "area": "区域  ",
        "Type of real estate you're interested in selling:": "您有兴趣出售的房地产类型：",

        "WhatsApp": "WhatsApp 号码",
        "Province": "省",
        "Province/City": "省/市",

        "Country": "国家",

        "Title": "标题",
        "Slug": "别名",
        "Meta Tag": "元标签",
        "Meta keywords": "元关键词",
        "I Agree to the terms & Conditions of Property Submission":
          "我同意提交财产的条款和条件",
        "View Agencies": "查看機構",
        "View Brokers": "查看經紀商",
        "Sqft": "平方英尺",
        "Schedule Link tour": "安排連結遊覽",
        "Type": "類型",
        "lotSize": "房間面積",
        "view": "视图",
        "Condition": "狀態",
        "Direction": "方向",
        "Price Range In the area Start": "價格範圍 在該地區 開始",
        "HIGHEST": "最高",
        "LOWEST": "最低",
        "Features": "特徵",
        "propertyId": "物业编号",
        "parking": "停车位",
        "buildingFloor": "建筑楼层",
        "yearBuilt": "建造年份",
        "propertyType": "物业类型",
        "propertySize": "物业面积",
        "lotSize": "房間面積",
        "view": "景观",
        "bathrooms": "浴室",
        "bedRooms": "卧室",
        "sqft": "平方英尺",
        "fullName": "全名",
        "subject": "主题",
        "emailAddress": "电子邮件地址",
        "yourMessage": "您的留言",
        "sendMessage": "发送消息",
        "send": "发送",

        "placeholderFullName": "全名",
        "placeholderSubject": "主题",
        "placeholderEmailAddress": "电子邮件地址",
        "placeholderYourMessage": "请输入您的消息...",
        "newYork": "纽约",
        "usa": "美国",
        "CALIFORNIA": "加利福尼亚州",
        "355 South Grand Street, Suite # 2450 Los Angeles, Ca 90071": "南格兰德街 355 号，套房#2450 洛杉矶，CA 90071",
        "Dubai": "迪拜",
        "Middle East": "中东",
        "ThaiLand": "泰国",
        "South East Asia": "东南亚",
        "TUNGSONGHONE, LAKSI BANGKOK, 10210 THAILAND": "TUGSONGHONE, 曼谷, 10210 泰国",
        "Uk": "英国",
        "Europe": "欧洲",
        "tbilsi": "第比利斯",
        "georgia": "格鲁吉亚",
        "moscow": "莫斯科",
        "buildingarea": "建築面積",
        "russia": "俄罗斯",
        "cairo": "开罗",
        "egypt": "埃及",
        "locationAddress": "{{name}} {{address}}",
        "phoneLink": "(478) 339 120",
        "usaAdd": "塞西莉亞·查普曼 711-2880 Nulla St.",
        "tbilsiAdd": "亞倫霍金斯 5587 Nunc。大街",
        "moscowAdd": "勞倫斯·莫雷諾 935-9940 酷刑者。街道",
        "Few": "很少",
        "More": "更多的",
        "What's Nearby": "附近有什麼",
        "Property Video": "樓盤視頻",
        "Property History": "房產歷史",
        "Schedule Tour": "行程安排",
        "prevListing": "上一页",
        "nextListing": "下一页",
        "prevLocation": "西奥多·洛伊，阿苏萨纽约39531",
        "nextLocation": "塞西莉亚·查普曼，曼卡托密西西比96522",
        "Calculate": "計算",
        "Mortgage Calculator": "抵押計算器",
        "homeValue": "房屋价值",
        "downPayment": "首付款",
        "loanAmount": "贷款金额",
        "interestRate": "利率",
        "loanDuration": "贷款期限（年）",

        "enterHomeValue": "输入房屋价值",
        "enterDownPayment": "输入您的资金",
        "enterInterestRate": "输入您的利率",
        "enterLoanDuration": "输入贷款期限",
        "fundsNeeded": "所需资金",
        "Monthly payment": "每月支付",
        "fullName": "全名",
        "emailAddress": "电子邮件地址",
        "phoneNumber": "电话号码",
        "date": "日期",
        "typeYourComment": "输入您的评论...",
        "Meet The Agent": "认识代理人",
        "Freddy Burben": "弗雷迪·伯本",
        "Company Agent": "公司代理",
        "callAgent": "呼叫经纪人",
        "viewListings": "查看列表",
        "saveAgent": "保存经纪人",
        "Recent Listings": "最近的房源",
        "Print Listing": "列印清單",
        "moveOnlineTitle": "我們擁有數千財產",
        "moveOnlineTitle2": "低於市場價值",

        "Searching...": "正在寻找...",
        "Villa Property Not Found": "未找到别墅物业",
        "Land Property Not Found": "未找到土地财产",
        "Residential Property Not Found": "未找到住宅物业类型",
        "Resort Property Not Found": "未找到度假村财产",
        "Condo Property Not Found": "未找到公寓物业",
        "Apartments Property Not Found": "未找到公寓物业",
        "Hotel Property Not Found": "未找到酒店物业",
        "Island Property Not Found": "未找到岛上财产",
        "Apartments": "公寓",


        "searchDescription": "現在您可以以低價搜尋泰國最好的房產",
        "searchPlaceholder": "按位置、站点、公寓名称或关键字搜索",
        "buyRentSell": "购买/租赁/出售",
        "any": "任何",
        "buy": "购买",
        "sell": "出售",
        "propertyType": "物业类型",
        "Listing Type": "列表类型",
        "Rental Period": "租借期限",

        "anyType": "任何类型",
        "searchButton": "搜索",
        "minPrice": "最低价格",
        "maxPrice": "最高价格",
        "beds": "床",
        "bath": "浴室",
        "buildingArea": "建筑面积",
        "landArea": "土地面积",
        "browseByCategory": "按類別瀏覽",
        "Residential & Condo & Apartment": "住宅及公寓",
        "Commercial ": "商业的",
        "Business ": "商业",
        "Warehouse & Factories": "仓库及工厂",
        "Category": "類別",
        "ourMotto": "我们的座右铭",
        "chooseToWorkWithUs": "为什么选择与我们合作",
        "Customer guaranteed": "客户有保障",
        "Reliable and guaranteed satisfaction for all our customers' needs.": "可靠且有保证地满足所有客户的需求。",
        "Higher return on investment ": "更高的投资回报",
        "Maximized Returns: Achieving More with Your Investments": "回报最大化：通过投资实现更多目标",
        "Fast and easy loan approval": "快速轻松的贷款审批",
        "Quick and Simple Loan Approvals": "快速简单的贷款审批",
        "One-Stop Full Service": "一站式全程服务",
        "A comprehensive and all-encompassing solution for various services and needs.": "针对各种服务和需求的全面、包罗万象的解决方案。",
        "Best Price on the market ": "市场上最优惠的价格",
        "We provide foreclosure property with low price ": "我们以低价提供止赎房产",


        "Book Tour": "預訂之旅",
        "trending": "热门趋势",
        "ourTopListings": "我们的热门列表",
        "No top list available": "没有可用的热门列表",

        "viewDetails": "查看详情",
        "latestNews": "最新消息",
        "bestDealOfTheMonth": "本月最佳交易",
        "No Data": "没有数据",

        "FAQ": "常問問題",
        "Can a foreigner buy property in Thailand?": "外国人可以在泰国购买房产吗？",
        "Yes, in some type of property that foreigner can own 100% right of the property like condo, but in a single family residential or land, there are certain artical of Thai laws that foreigner can buy property. Foreigner will need to contact real estate company that have experience in this field ": `是的，在某些类型的财产中，外国人可以拥有 100% 的财产权利，例如公寓，但在单户住宅或土地中，泰国法律有某些规定外国人可以购买财产。
        外国人需要联系有这方面经验的房地产公司`,

        "Can foreigner get a long stay visa? ": "外国人可以获得长期居留签证吗？",
        "Sure, there are immigration laws that open for foreigner to be able to live in Thailand for long period of time. You will need to get a consultation with immigration office or an attorney": `当然，移民法允许外国人在泰国长期居住。您需要咨询移民办公室或律师`,

        "Can a foreigner get a mortgage loan in Thailand?": "外国人可以在泰国获得抵押贷款吗？",
        "yes, We had done thousand of deal in getting foreigner approved on their loans. You need to deal with a company that know how to put together and process a loans the right way. You will need a loan processing company that know how to deal with your local bank and connect with Thai local bank to help complete the loan process. ": "是的，我们已经完成了数千笔交易来让外国人获得贷款批准。您需要与一家知道如何以正确方式整合和处理贷款的公司打交道。您将需要一家知道如何与当地银行打交道并与泰国当地银行联系以帮助完成贷款流程的贷款处理公司。",

        "Can I own a business in Thailand? ": "我可以在泰国拥有一家企业吗？",
        "Of course, Thailand is welcoming a foreign investor, but their Thai laws prohibit foreign investor to hold a 100% ownership in Thailand, but there are many exception that give 100% ownership right in doing business in Thailand": "当然，泰国欢迎外国投资者，但他们的泰国法律禁止外国投资者在泰国持有100%的所有权，但有很多例外，给予在泰国开展业务的100%所有权",

        "Can I invest in real estate in Thailand? ": "我可以在泰国投资房地产吗？",
        "Of course, You can invest in to any business in Thailand. You need to consult Thai attorney on how to setup or invest in the real estate in Thailand ": "当然，您可以投资泰国的任何企业。您需要咨询泰国律师如何在泰国设立或投资房地产",

        "Reach Out": "伸手",
        "Call Us": "致电我们",
        "Find Us": "找到我们",
        "Get Directions": "获取路线",


        "Room Floor":"房间楼层",
        "testimonials": "见证",
        "whatPeopleAreSaying": "人们都在说什么",
        "createAnAcresAccount": "创建一个以更少的价格购买房屋的帐户",
        "username": "用户名",
        "selectEmailOrPhoneNumber": "选择电子邮件或电话号码",
        "emailAddress": "电子邮件地址",
        "mobileNumber": "手机号码",
        "password": "密码",
        "user": "用户",
        "buyer": "买家",
        "seller": "卖家",
        "agent": "经纪人",
        "register": "注册",
        "continueWithFacebook": "继续使用Facebook登录",
        "continueWithGoogle": "继续使用Google登录",
        "alreadyHaveAccount": "已有帐户？",
        "login": "登录",
        "logIntoAcres": "登录以更少的价格买房",
        "selectEmailOrPhone": "选择电子邮件或电话号码",
        "select": "选择",
        "emailAddress": "电子邮件地址",
        "mobileNumber": "手机号码",
        "password": "密码",
        "forgotPassword": "忘记密码？",
        "login": "登录",
        "Log Out": "登出",
        "Status": "地位",
        "Search listings": "搜尋列表",
        "Price Range": "價格範圍",
        "Diameters": "價格範圍",
        "propertyNotFoundHeading": "找不到房产",
        "propertyNotFoundDescription": "抱歉，您正在搜索的特定类型的房产目前在我们的网站上不可用。请稍后再查看或联系我们以获取进一步的帮助。",
        "Advanced Search": "進階搜尋",
        "Search locations": "搜尋地點",
        "Floor": "地面",
        "Sort by": "排序方式",
        "Apply filters": "應用過濾器",
        "Filter": "篩選",
        "About Us": "關於我們",
        "Help With Distress Home": "幫助遇險之家",
        "Financial Planing": "財務規劃",
        "Buy home for": "以更少的价格买房可以帮助您进行财务规划，帮助您为下一个梦想之家做好准备。我们为您提供免费顾问，帮助您省钱并了解您何时准备好购买下一套房屋。我们可以为您提供许多计划，您无需使用储蓄来支付房屋首付。我们将与您一起管理您的预算，帮助您轻松支付抵押贷款。",
        "Loan Modification": "貸款修改",
        "Property Management": "物業管理",
        "If you enjoying": "如果您享受被动收入但不想与租户打交道。我们可以帮助您寻找新租户或驱逐他们。我们可以以最高标准全面管理您的财产。我们为您收取租金并进行全面的财产维护。您不必担心您的财产。我们可以处理所有必要的步骤，让您按时收到租金，并为您找到像您一样热爱您的房产的合适租户。",



        "Investment Planner": "投資策劃師",
        "If you are looking": "如果您正在寻找最高的投资回报。我们可以帮助您提供最佳的解决方案，帮助您获得更高的回报。我们可以为您找到最适合您预算的投资，让您放心投资的每一分钱都是安全且风险较小的。我们的投资策划团队已帮助数千名投资者获得充分的利益并获得最高的投资回报。,",
        "How to buy poperty in Thailand": "如何在泰國購買房產",
        "Get Long Term Visa in Thailand": "在泰国获得长期签证",
        "Thailand Real estate Laws": "泰國房地產法",
        "How to retire in Thailand": "如何在泰國退休",
        "Promotion Occurs": "促销发生",
        "ok": "好的",
        "Select Image": "选择图像",
        "Complain Form": "投诉表格",
        "Complain": "抱怨",
        "Blog List": "博客列表",
        "Read More": "阅读更多",
        "Comments by:": "评论者：",
        "Search Posts": "搜索帖子",
        "Recent Posts": "最近的帖子",
        "What agencies are looking for in an agent": "“机构正在寻找什么样的代理人”",
        "Changing the way we sell and buy real estate": "改变我们买卖房地产的方式",
        "Families, its the best time to start buying": "家庭，现在是入手购买的最佳时机",
        "Our all new duplex apartment is up for grabs": "我们全新的复式公寓可供抢购",
        "Popular Categories": "热门类别",
        "Living Rooms": "客厅",
        "Real Estate": "房地产",
        "Kitchens": "厨房",
        "Flats": "公寓",
        "Luxury": "奢华",
        "Bed Rooms": "卧室",
        "Popular Tags": "热门标签",
        "House": "房子",
        "Building": "建筑",
        "Agency": "机构",
        "Family": "家庭",
        "Acres": "英亩",
        "Baths": "浴场",
        "Rooms": "客房",
        "WechatId": "微訊號",








        "Pricing": "價錢",
        "Rent": "租",
        "OR": "或者",
        "Name is Required": "⚠️ 姓名為必填項",
        "Please Select Option": "⚠️ 請選擇選項",
        "Please Select Country Code": "⚠️ 请选择国家代码",
        "Last Name is Required": "⚠️ 姓氏为必填项",
        "Address is Required": "⚠️ 地址为必填项s",

        "Tumbol is Required": "⚠️ 需要翻滚",
        "City/Amphor is Required": "⚠️ 城市/安弗为必填项",
        "States/Province is Required": "⚠️ 州/省为必填项",
        "Country is Required": "⚠️ 国家/地区为必填项",
        "Line Id is Required": " ⚠️ 线路 ID 为必填项",
        "WhatsApp number must only contain digits": " ⚠️ WhatsApp 号码只能包含数字",
        "WhatsApp number is too short": "⚠️ WhatsApp 号码太短",
        "WhatsApp number is too long": " ⚠️ WhatsApp 号码太长",
        "WhatsApp Number is Required": " ⚠️ 需要 WhatsApp 号码",
        "Wechat is Required": " ⚠️ 必须使用微信",
        "Tel Number is Required": " ⚠️ 需要提供电话号码。",
        "Too short": " ⚠️ 过短",
        "Too long": " ⚠️ 太长",
        "Video Url is Required": " ⚠️ 视频网址为必填项。",
        "At least one image is required": " ⚠️ 必须至少有一张图像。",
        "File is required": " ⚠️ 需要文件",
        "WeChat": "微信",


        "Invalid email": "⚠️ 不合規電郵",
        "Email is required": "⚠️ 電子郵件為必填項",
        "Phone number must only contain digits": "電話號碼只能包含數字",
        "Phone number is too short": "電話號碼太短，至少應為 10 位數字",
        "Phone number is too long": "電話號碼太長，請少於15位",
        "Phone number is required": "⚠️ 電話號碼為必填項",
        "Password must contain": "密碼必須至少包含1個大寫字母、1個數字和1個特殊符號",
        "Password must be at least 8 characters long": "密碼長度必須至少為 8 個字符",
        "Password is required": "⚠️需要密碼",
        "Please Select": "⚠️ 請選擇",
        "please Select": "請選擇",
        "On Sale": "出售",
        "Pending": "待办的",
        "Rental": "出租",
        "Provience": "普罗旺斯",
        "Newpassword": "新密码",
        "confirmPassword": "确认密码",
        "Code": "代码",
        "Code is required": " ⚠️ 需要代码",
        "forgotIntoAcres": "忘记进入英亩",
        "Passwords must match": " 密码必须匹配",
        "Confirm Password is required": "  ⚠️ 需要确认密码",
        "Buying property in Thailand": `作为外国人在泰国购买房产可能会令人畏惧且具有挑战性。泰国法律中有许多因素需要您充分了解，
        但我们可以帮助您获得永久业权财产，例如公寓，但如果您想寻找别墅或任何其他财产，那么我们可以为您安排数百个财产你来选择。我们可以为某些类型的房产安排长达 
        90 年的租赁房产，如果您想投资商业房产，那么我们可以安排您拥有 100% 的商业房产。`,
        "We can help you get": `我们可以帮助您获得 10 年期的长期签证，并且您可以在签证到期时延期。 泰国移民法和近年来的变化有助于外国人在泰国居住更长时间。
         一旦您获得 10 年签证获批，您就可以申请通过泰国提供的许多福利 政府。我们可以毫无障碍地为您安排和找到合适的投资或任何其他医疗保健。`,

        "It is not possible": `外国人不可能以自己的名义拥有土地。如果您与泰国国民结婚, 您可以拥有土地。作为土地合法继承人的外国人可以这样做，
        但必须在获得土地后一年内出售土地。 。`,
        "Person": `50岁及以上的人可以申请泰国退休签证。这让您在泰国的逗留无忧无虑。您可以按照以下步骤在泰国申请退休签证。`,

        "titlee": "第一步：获取非移民签证 - O类别",
        "requirements": "要求：",
        "passport": "护照剩余有效期至少一年",
        "accommodation": "泰国住宿证明（如果您在泰国内申请此签证）",
        "bankStatement": "银行对帐单显示资金证明",
        "healthInsurance": "年度健康保险（根据某些泰国驻外使馆或领事馆的要求）",
        "nationalityResidence": "您必须持有签证申请国的国籍或永久居留权",
        "applicationLocation": "在泰国境外的泰国大使馆或在泰国境内的泰国移民局申请",
        "Residential": "住宅",
        "Hotel": "飯店",
        "Commercial": "商業的",
        "Resort": "採取",
        "Land": "土地",
        "Warehouse & Factories": "倉庫及工廠",
        "Step2": "第2步",
        "nonImmigrantOExtensionRequirements": "非移民O延期要求：",
        "mustBe50YearsOld": "必须年满50岁及以上",
        "passport": "护照（每页签名复印件）",
        "nonImmigrantOVisa": "非移民O签证",
        "tm30Receipt": "TM30收据",
        "proofOfMeetingFinancialRequirements": "满足财务要求的证明，如银行对帐单（用于收入证明）",
        "incomeCertificate": "泰国大使馆出具的收入证明（申请人的国籍）",
        "thaiBankBook": "泰国银行账簿显示存款账户（原件）",
        "letterFromThaiBank": "泰国银行的信函（证明书必须由银行官员签署）",
        "photos": "三张（3）4x5厘米的照片，拍摄于过去6个月内，全脸可见。",
        "proofOfPermanentAccommodation": "泰国的永久住宿证明，如租赁或租赁协议。",
        "medicalCertificateAndCriminalRecordCertificate": "在泰国境内完成的退休签证申请过程中不需要提供医疗证明和犯罪记录证明。",

        "Step3": "步驟3",
        "step3Description": "大多数人都误解“停留延长”和“再入境许可”的不同概念。有时候它们被彼此混淆，但您必须了解这是两种不同的东西，您在逗留期间都需要。停留延长只是您停留期间的控制日期。一切都依赖于此。当您的停留延长到期时，一切都会无效。停留延长通常有效期为1年。另一方面，再入境许可的有效期取决于您的停留延长。然而，如果没有再入境许可离开泰国，将自动使您的停留延长或停留期无效。",
        "reentryPermitOptions": "再入境许可对泰国退休签证持有人有两个选择：",
        "singleEntry": "单次入境（1,000泰铢）",
        "multipleEntries": "多次入境（3,800泰铢）",
        "reentryPermitApplication": "再入境许可申请需要在泰国机场提交。移民局为处理这些请求在护照控制柜台前分配了一名移民官员。",

        "Step4": "步驟4",
        "reportingStayDescription": "如果您持有长期停留延期签证，申请人必须每90天报告给移民局。持有多次入境签证的人只需离开泰国，并在每次90天入境后更新其停留延期。",
        "renewExtensionOfStay": "如果您无法在泰国境内获得您的停留延期，您将不得不从泰国驻外大使馆或领事馆获得新的非移民O签证。",


        "summaryTitle": "总结",
        "summaryDescription": `您现在持有为养老目的的1年非移民签证延期。不需要健康保险，但申请人必须在泰国内申请签证以免除保险要求。
            请记住每3个月报告一次，通常称为“90天报告”，可以在线或亲自提交给移民局。`,
        "Home": "家",
        "Buy": "买",
        "Thing To Know": "要知道的事情",
        "Residential": "住宅",
        "Condo": "公寓",
        "Apartment": "公寓",
        "Hotel": "酒店",
        "Resort": "采取",
        "Villa": "别墅",
        "Land": "土地",
        "Island": "岛",
        "ischool": "國際學校",
        "Buying a Home?": "买房？",
        "Selling a Home?": "卖房子？",
        "Step1": "步驟1",
        "Don’t walk away": "如果您面临财务问题，请不要离开家。如果您因任何情况面临丧失抵押品赎回权。我们可以帮助您解决问题。我们与全球 500 多家银行和金融机构有业务往来，即使您的信用已经很差，也可以帮助您找到最适合您的贷款。我们有一家专门处理困境财产的金融机构，他们能够为您提供许多适合您财务预算的计划。我们甚至提供帮助您恢复贷款并冻结每月抵押贷款付款的计划，直到您能够重新站起来。",
        "Let us help you": "让我们帮助您解决暂时的财务问题，这样您就可以保住自己的家。",

        "Agent Registration Form": "代理登记表",
        "Type of Property": "财产类型",
        "Upload Your Property Images": "上传您的房产图片",
        "Submit": "提交",
        "Experience the beauty": `持长期签证体验泰国的美丽和文化。
        对于那些想要搬迁或投资房地产的人来说，这是完美的选择
        签证允许最长停留一年。凭借长期的
        签证后，您将有机会完全沉浸在
        泰国生活方式并充分利用该国提供的一切。
        无论您是想退休、创业还是只是享受生活
        长假，泰国长期签证完美
        选择。请立即联系我们，了解有关获得长期合作伙伴关系的更多信息
        签证并寻找适合您需求的完美房产。`,
        "Verification Code": "验证码",
        "Enter your verification code": "输入您的验证码",
        "Verify": "核实",
        "Sorry, the ":
          "抱歉，您正在寻找的房产的具体类型是 目前在我们的网站上不可用。请回来查看 稍后或联系我们寻求进一步帮助",
        "For Rent": "出租",
        "Featured": "精选",
        "For Sale": "出售",
        "Leased": "租赁",
        "New Addition": "新增加",
        "Sold": "卖",
        "Rental": "出租",
        "Reduced": "减少",
        "Special Offer": "减少",
        "Address": "地址",
        "Get offer for your home": "获取您家的报价",
        "Tumbol": "汤博尔",
        "City/Amphor": "城市/安弗尔",
        "States/Province": "州/省",
        "Line Id": "线路编号",
        "Wechat": "微信",
        "Upload video": "上传视频",
        "Upload picture": "上传图片",
        "Real Estate Type": "房地产类型",
        "Property Location": "物业位置",




        //footer
        "foothead": "以更少的價格買房",
        "menu": "選單",
        "find_home": "尋找房屋",
        "find_home2": "找到你的家",
        "add_listing": "新增房源",
        "listing": "清單",
        "become_agent": "成為我們的代理商",
        "Rental Guideline": "租赁指南",
        "about_us": "關於我們",
        "complaint": "投訴",
        "blogs_news": "部落格/新聞",
        "sell_property": "將您的房地產賣給我們",
        "contact_us": "聯絡我們",
        "legal": "法律",
        "privacy_policy": "隐私政策",
        "refund_policy": "退款政策",
        "cookie_policy": "Cookie 政策",
        "terms_condition": "条款和条件",
        "find_agent_worldwide": "在全球找到我们的代理",
        "job": "工作",
        "newsletter": "通讯",
        "newsletter_description": "今天加入我们的通讯，获取我们提供的最佳公寓。",
        "newsletter_placeholder": "电子邮件地址",
        "join_newsletter": "加入通讯",
        "information": "信息",
        "aboutUs": "关于我们",
        "contactUs": "联系我们",
        "services": "服务",
        "Price and property can be change without notice": "价格和属性可能会更改，恕不另行通知",
        "Buyhomeforless.com © 2020 All rights reserved": "Buyhomeforless.com © 2020 版权所有",
        "Any Environment": "任何环境",
        "Urban": '城市的',
        "Cities": "城市",
        "Any Land Area": "任何土地面积",
        "All Field are Required": "所有字段均为必填",
        "Children Playground": "儿童游乐场",
        "It has been saved successfully": "已经保存成功了",
        "Floor Plan": "楼层平面图",
        "Floor Images": "地板图像可选",
        "No floor images": "没有楼层图像",
        "Select the value": "选择值",
        "Furnished": "家具",
        "Type of Furnish": "装修类型",
        "Maintenance Fee": "维护费",
        "Stove": "火炉",
        "Refrigerator": "冰箱",
        "Air Condition": "空调",
        "Air Condition": "空调",
        "Bed": "床",
        "TV": "电视",
        "Roomsize": "房间大小",
        "Agent Info": "代理商信息",
        "City": "城市",
        "ROOM AREA":"房间面积",
        "Room#":"房间#"
      },
    },
    Thai: {
      translation: {
        "BBQ Area": "พื้นที่บาร์บีคิว",
        "Roomsize": "ขนาดห้อง",
        "whatsapp": 'วอทส์แอพ',
        "Community Hall": "ศาลาประชาคม",
        "Swimming Pool": "สระว่ายน้ำ",
        "Gym": "โรงยิม",
        "Parks": "สวน",
        "Mini Mart": "มินิมาร์ท",
        "Agent Service":"บริการตัวแทน",
        "FansAirCondition":"เครื่องปรับอากาศ",
        "solarPower":"พลังงานแสงอาทิตย์",
        "seaview":"ดู",
        "Elevator":"ลิฟต์",
        "electricity":"จ่ายค่าไฟฟ้า",
        "paidwater":"จ่ายน้ำ",
        "Monthly Mortage": "จำนองรายเดือน",
        "Library": "ห้องสมุด",
        "Children Playground": "สนามเด็กเล่น",
        "Tennis Court": "สนามเทนนิส",
        "Jacuzzi": "อ่างจากุซซี่",
        "Sauna": "ซาวน่า",
        "Party Hall": "ปาร์ตี้ฮอลล์",
        "Guest Parking": "ที่จอดรถแขก",
        "Beds": "เตียง",
        "Bathrooms": "ห้องน้ำ",
        "Laundry Room": "ห้องซักรีด",
        "Meeting room": "ห้องประชุม",
        "Game Room": "ห้องเกม",
        "Jacuzzi": "อ่างจากุซซี่",
        "Sauna": "ซาวน่า",
        "Maid Room": "ห้องแม่บ้าน",
        "Guest room": "ห้องรับแขก",
        "Parking": "ที่จอดรถ",
        "Dining Room": "ห้องรับประทานอาหาร",
        "Living Room": "ห้องนั่งเล่น",

        "Save": "บันทึก",
        "Appartment": "อพาร์ตเมนต์",
        "This User Is Already Exits": "ผู้ใช้รายนี้ออกจากระบบแล้ว",
        "heading": "เราเป็นบริษัทอสังหาริมทรัพย์ชั้นนำในประเทศไทยที่สามารถช่วยคุณค้นหาข้อตกลงที่ดีที่สุดในตลาดได้",
        "Sell": "ขาย",
        "Book Tour": "จองทัวร์",
        "Embed Link": "ฝังลิงค์",
        "PropertyVideolink": "ลิงค์วิดีโออสังหาริมทรัพย์ ยูทูป",
        "WhatsApp Number": "หมายเลข วอทส์แอพพ์",
        "How Do You Sell a House": "คุณจะขายบ้านได้อย่างไร",
        "Any listing agent that you meet with should have a plan, with concrete details, to sell your house. homelight logo. Save thousands when buying a home": "ตัวแทนขายบ้านที่คุณพบควรมีแผนในการขายบ้านโดยมีรายละเอียดที่เป็นรูปธรรม โลโก้โฮมไลท์ ประหยัดหลายพันเมื่อซื้อบ้าน",
        "All Field are Required": "ต้องระบุข้อมูลทุกช่อง",
        "greeting": "Buy Home for Less (BHFL) เป็นบริษัทในเครือของกลุ่ม Mstar Capital ซึ่งก่อตั้งขึ้นเมื่อปี 2546 ในด้านอสังหาริมทรัพย์ การพัฒนาและธุรกิจการเงิน ความพิเศษของเราคือการจัดเตรียมอาหารให้กับอสังหาริมทรัพย์ที่มีมูลค่าต่ำกว่าตลาด เราทำงานโดยตรงกับทรัพย์สินด้อยคุณภาพและ NPL ผ่านธนาคารรายใหญ่ทั่วโลก เพื่อให้ลูกค้าของเราได้รับสิ่งที่ดีที่สุด ราคา. เราไม่เพียงแต่ทำงานโดยตรงกับสถาบันการเงินเท่านั้น แต่ยังทำงานร่วมกับเจ้าของทรัพย์สินที่อยู่ในแวดวงการเงินด้วย ปัญหาและจำเป็นต้องขายทรัพย์สินอย่างรวดเร็ว",
        "li": "ลูกค้าของเราจะได้รับราคาที่ดีที่สุดเท่าที่จะเป็นไปได้และได้รับผลกำไรเมื่อพวกเขาซื้ออสังหาริมทรัพย์จากตัวแทนอสังหาริมทรัพย์มืออาชีพของเรา ตัวแทนอสังหาริมทรัพย์ของเราได้รับการฝึกอบรรมอย่างเต็มที่และเป็นมืออาชีพในสาขาอสังหาริมทรัพย์ ซึ่งจะทำให้มั่นใจได้ว่าอสังหาริมทรัพย์ของคุณ การทำธุรกรรมเป็นไปอย่างราบรื่นและปลอดภัย",
        "li2": "คุณสามารถมั่นใจได้ว่าเมื่อคุณซื้ออสังหาริมทรัพย์จากเรา คุณจะได้รับราคาและบริการที่ดีที่สุด และดำเนินการได้อย่างรวดเร็ว",
        "GetStarted": "เริ่ม",
        "MoreInfo": `ข้อมูลเพิ่มเติม`,
        "Any floor": "ชั้นใดก็ได้",
        "Any Range": "ช่วงใดก็ได้",
        "High to low": "ราคา: สูง - ต่ำ",
        "low to High": "ราคา: ต่ำ - สูง",
        "Number of baths": "จำนวนห้องอาบน้ำ",
        "Number of beds": "จำนวนเตียง",
        "Any Environment": "สิ่งแวดล้อมใดๆ",
        "Urban": 'ในเมือง',
        "hotel": "โรงแรม",
        "Cities": "Cities",
        "Services": "บริการ",
        "whatsappid": "วอทส์แอพ",
        "WeChatID": "รหัสวีแชท",
        "WhatsappID": "วอทส์แอปไอดี",
        "lineID": "ไลน์ไอดี",
        "telegramID": "โทรเลขไอดี",
        "Top Selling": "มียอดขายสูงสุด",
        "Most Popular": "ที่นิยมมากที่สุด",
        "Complaint Form": "แบบฟอร์มการร้องเรียน",
        "need": "คุณต้องการมัน เราเข้าใจแล้ว",
        "Real Estate Services": "บริการด้านอสังหาริมทรัพย์",
        "Karachi Division": "แผนกการาจี",
        "For Sale Sold": "ขาย, ขาย",
        "New Addition": "นอกจากนี้ใหม่",
        "Featured Leased": "โดดเด่น, เช่า",
        "Our Real estate service is second to none. We are a one-stop real estate service firm in Thailand that offer various type of property from regular property to high-end luxury. We also offer distress property such as Foreclosure, bank-owned, NPA and etc. that are not listed on the market, so you will get the under-market price.": "บริการด้านอสังหาริมทรัพย์ของเราไม่เป็นรองใคร เราเป็นบริษัทผู้ให้บริการด้านอสังหาริมทรัพย์แบบครบวงจรในประเทศไทยที่นำเสนออสังหาริมทรัพย์หลากหลายประเภทตั้งแต่อสังหาริมทรัพย์ทั่วไปไปจนถึงสินค้าหรูหราระดับไฮเอนด์ นอกจากนี้เรายังเสนอทรัพย์สินด้อยคุณภาพ เช่น การยึดสังหาริมทรัพย์ ธนาคารเป็นเจ้าของ NPA และอื่นๆ ที่ไม่ได้จดทะเบียนในตลาด ดังนั้นคุณจะได้รับราคาที่ต่ำกว่าตลาด",
        "Real Estate Development": "การพัฒนาอสังหาริมทรัพย์",
        "Our company worked with real investor worldwide to help them invest in to Thailand real estate. We offer service from consultant, finding you the land, development, getting you a building permit and construction. Our client will feel assure that invest in Thailand real estate they will get the highest return of their investment. ": "บริษัทของเราทำงานร่วมกับนักลงทุนจริงทั่วโลกเพื่อช่วยพวกเขาลงทุนในอสังหาริมทรัพย์ในประเทศไทย เรามีบริการจากที่ปรึกษา จัดหาที่ดิน พัฒนา รับใบอนุญาตก่อสร้างและก่อสร้าง ลูกค้าของเราจะรู้สึกมั่นใจว่าการลงทุนในอสังหาริมทรัพย์ในประเทศไทยพวกเขาจะได้รับผลตอบแทนจากการลงทุนสูงสุด",
        "Moo": "หมู่",
        "Bangkok": "กรุงเทพฯ",
        "Property Management Service": "บริการบริหารจัดการทรัพย์สิน",
        "Many of our client is investor and they only buy property for investment purpose. We give a full management service from Finding tenant, fund collection, property maintenance, eviction and housekeeping. You don’t have to worry about your investment. Fund will be credited to your account every month.": "ลูกค้าหลายรายของเราเป็นนักลงทุน และพวกเขาซื้ออสังหาริมทรัพย์เพื่อการลงทุนเท่านั้น เราให้บริการการจัดการเต็มรูปแบบตั้งแต่การค้นหาผู้เช่า การรวบรวมกองทุน การบำรุงรักษาทรัพย์สิน การขับไล่ และการดูแลทำความสะอาด คุณไม่ต้องกังวลกับการลงทุนของคุณ เงินจะเข้าบัญชีของคุณทุกเดือน",
        "Any amount": "จำนวนเท่าใดก็ได้",
        "Investment Consulator": "กงสุลการลงทุน",
        "We offer free service in real estate consultant. Client will meet with our finance and real estate specialist to guide you through out the investment process. You will get a better idea and solution to invest. Our team will help you make the right decision and gain a better return on your investment":
          "เราให้บริการการปรึกษาด้านอสังหาริมทรัพย์ฟรี ลูกค้าจะพบกับนักเงินและนักอสังหาริมทรัพย์ของเราเพื่อคำแนะนำในกระบวนการลงทุน คุณจะได้ความคิดที่ดีกว่าและสำหรับการลงทุนของคุณ เราจะช่วยคุณในการตัดสินใจที่ถูกต้องและบรรลุผลตอบแทนที่ดีกว่าสำหรับการลงทุนของคุณ การผสมธุรกิจและการรับซื้อบริษัท: ลูกค้าของเรามากไม่เพียงแค่ลงทุนในการซื้ออสังหาริมทรัพย์ แต่พวกเขายังต้องการซื้อกิจการที่มีโอกาสสำหรับการเติบโต เราสามารถช่วยค้นหาธุรกิจที่เหมาะสมที่สุดสำหรับลูกค้าของเรา ลูกค้าของเราสามารถทำเงินขึ้นได้ในขณะที่มีรูปแบบการดีในชีวิต.",

        "Business Merging and Acquisition": "การรวมธุรกิจและการเข้าซื้อกิจการ",
        "Many of client not only invest in buying property, but they also want to buyout an existing business with potential growth. We can help find the right business that fit our client criteria. Our client can make money while living like a king in Thailand.": "ลูกค้าจำนวนมากไม่เพียงแต่ลงทุนในการซื้ออสังหาริมทรัพย์เท่านั้น แต่ยังต้องการซื้อธุรกิจที่มีอยู่ที่มีศักยภาพในการเติบโตอีกด้วย เราสามารถช่วยค้นหาธุรกิจที่เหมาะสมและเหมาะสมกับเกณฑ์ลูกค้าของเราได้ ลูกค้าของเราสามารถสร้างรายได้ในขณะที่ใช้ชีวิตอย่างราชาในประเทศไทย",

        "Mortgage Financing": "สินเชื่อจำนอง",
        "We have an in-house mortgage company that work directly with more than 200 bank and private investor worldwide. More than 20 years connection with the local bank. We can find you the best rate and get you approved. ": "เรามีบริษัทจำนองภายในที่ทำงานโดยตรงกับธนาคารและนักลงทุนเอกชนมากกว่า 200 รายทั่วโลก มีประสบการณ์เชื่อมโยงกับธนาคารท้องถิ่นมากกว่า 20 ปี เราสามารถหาอัตราที่ดีที่สุดให้กับคุณได้และทำให้คุณได้รับการอนุมัติ ",


        "OurOffice": "ออฟฟิศของเรา",
        "Agent Details":'รายละเอียดตัวแทน',
        "WeChat": "วีแชท",
        "WorkingWithTopReal": "คุณกำลังทำงานร่วมกับบริษัทอสังหาริมทรัพย์ชั้นนำในประเทศไทย",
        "FindOutMore": "หาข้อมูลเพิ่มเติม",
        "Want to look for property that fit your tastes": "ต้องการมองหาอสังหาริมทรัพย์ที่เหมาะกับรสนิยมของคุณ",
        "We have thousand of properties in our inventory, but not all can be add on the website. If you request to see a certain property. We can find it for you! ": "เรามีอสังหาริมทรัพย์หลายพันรายการในคลังของเรา แต่ไม่ใช่ทั้งหมดสามารถเพิ่มบนเว็บไซต์ได้ หากคุณขอดูทรัพย์สินบางอย่าง เราหาให้คุณได้!",
        "pick_agent": "เลือกตัวแทนที่เหมาะกับคุณ",
        "agent_description": "ตอบคำถามไม่กี่ข้อและรับรายชื่อตัวแทนที่เป็นที่นิยมในพื้นที่ของคุณ เปรียบเทียบค่าบริการและบริการของพวกเขา และเลือกตัวแทนที่เหมาะสมกับคุณ",
        "get_offers": "รับข้อเสนอสำหรับบ้านของคุณ",
        "offers_description": "ตอบคำถามไม่กี่ข้อและรับรายชื่อตัวแทนที่เป็นที่นิยมในพื้นที่ของคุณ เปรียบเทียบค่าบริการและบริการของพวกเขา และเลือกตัวแทนที่เหมาะสมกับคุณ",
        "visit_marketplace": "ส่งรายการของคุณ",
        "track_value": "ติดตามมูลค่าบ้านของคุณ",
        "track_description": "ตอบคำถามไม่กี่ข้อและรับรายชื่อตัวแทนที่เป็นที่นิยมในพื้นที่ของคุณ เปรียบเทียบค่าบริการและบริการของพวกเขา และเลือกตัวแทนที่เหมาะสมกับคุณ",
        "search_placeholder": "ค้นหา...",
        "sellerGuides": "คู่มือผู้ขาย",
        "downloadOurApp": "ดาวน์โหลดแอปของเรา",
        "dummyText": "Lorem Ipsum เป็นข้อความ虚构ที่ใช้ในอุตสาหกรรมการพิมพ์และจัดวางตัวอักษร เป็นเพียงข้อความ虚构มาตั้งแต่ศตวรรษที่ 16",
        "googlePlay": "Google Play",
        "appStore": "แอพสโตร์",
        "submitListing": "ส่งรายการ",
        "login": "เข้าสู่ระบบ",
        "Log Out": "ออกจากระบบ",
        "signup": "ลงทะเบียน",
        "basicInformation": "ข้อมูลพื้นฐาน",
        "gallery": "แกลเลอรี",
        "location": "ที่ตั้ง",
        "propertyAmenity": "สิ่งอำนวยความสะดวกทางทรัพย์",
        "details": "รายละเอียด",
        "ischool": "โรงเรียนนานาชาติ",
        "agentDashboard": "แดชบอร์ดเอเจนต์",
        "seo": "SEO",
        "PropertyDescription": "รายละเอียดเกี่ยวกับบ้านและที่ดิน",
        "PropertyName": "ชื่อโครงการ",
        "PropertyStatus": "สถานะทรัพย์",
        "PropertyType": "ประเภททรัพย์",
        "Listing Type": "ประเภทรายการ",
        "Rental Period": "ระยะเวลาการเช่า",
        "Monthly": "ระยะเวลาการเช่า",
        "Weekly": "รายสัปดาห์",
        "Daily": "รายวัน",
        "FloorImages":"ภาพพื้น",
        "SalePrice/RentalPrice": "ราคาขาย",
        "RentalPeriod": "ระยะเวลาเช่า",
        "RentalPrice": "ราคาเช่า",
        "BuildingArea": "พื้นที่อาคาร (SQM) (ไม่จำเป็น)",
        "LandArea": "พื้นที่ดิน (ตร.ม.) (ไม่บังคับ)",
        "PropertyVideo": "ตัวเลือกวิดีโอคุณสมบัติ",
        "Floor": "พื้น",
        "Property Thumbnail": "รูปภาพย่อ",
        "Choose File": "เลือกไฟล์",
        "Property Gallery": "แกลเลอรีทรัพย์",
        "Drop files here or click to upload.":
          "ลากและวางไฟล์ที่นี่หรือคลิกเพื่ออัปโหลด",
        "This is just a demo dropzone. Selected files are not actually uploaded.":
          "นี้เป็นโซนการทดสอบเท่านั้น ไฟล์ที่เลือกจะไม่ถูกอัปโหลดจริง",
        "You can upload up to 15 images for your listing":
          "*คุณสามารถอัปโหลดรูปภาพได้สูงสุด 15 รูปสำหรับรายการของคุณ",
        "Listing images should be at least 620x480 in dimensions":
          "*รูปภาพในรายการควรมีขนาดอย่างน้อย 620x480",
        "drag_and_drop_notice": "ลากและวางการแจ้งเตือน",
        "enterNearby": "ป้อนที่อยู่ใกล้",
        "selectType": "เลือกประเภท",
        "name": "ชื่อ",
        "distance": "ระยะทาง (กม.)",
        "addRow": "เพิ่มแถว",
        "delete": "ลบ",
        "edit": "แก้ไข",
        "agree": "ฉันยอมรับข้อกำหนด &amp; เงื่อนไขทรัพย์สิน   การส่ง",
        "Any Location": "สถานที่ใดก็ได้",
        "full_address_label": "บ้านเลขที่",
        "country_label": "ป้ายชื่อประเทศ",
        "Tumbol": "ตำบล",
        "city_label": "อำเภอ/เมือง",
        "province_label": "จังหวัด/รัฐ",
        "zipcode_label": "รหัสไปรษณีย์",
        "longitude_label": "ลองจิจูด",

        "latitude_label": "ละติจุด",
        "Yes": "ใช่",
        "No": "ไม่ใช่",
        "Party Hall": "ห้องจัดเลี้ยง",
        "Community Hall": "ห้องประชุมชุมชน",
        "Swimming Pool": "สระว่ายน้ำ",
        "No Deals Available": "ไม่มีข้อเสนอ",
        "Gym": "โรงยิม",
        "Guest Parking": "ที่จอดรถสำหรับผู้เยี่ยม",
        "Mini Mart": "มินิมาร์ท",
        "Library": "ห้องสมุด",
        "Park": "สนามเด็กเล่น",
        "Tennis Court": "สนามเทนนิส",
        "Jacuzzi": "อ่างจากุซซี่",
        "Sauna": "ซาวน่า",
        "Line Id": "ไลน์ไอดี",
        "WeChatID": "วีแชทไอดี",
        "Property ID": "รหัสทรัพย์สิน",
        "Beds": "เตียง",
        "Number of Beds": "จำนวนเตียง",
        "Bathrooms": "ห้องน้ำ",
        "buildingarea": "พื้นที่อาคาร",
        "Number of Bathrooms": "จำนวนห้องน้ำ",
        "Building Floor": "จำนวนชั้นสร้าง",
        "Condition": "เงื่อนไข",
        "Used": "ใช้แล้ว",
        "New": "ใหม่",
        "Year Built": "ปีที่สร้าง",
        "Land size": "ขนาดที่ดิน",
        "Property Year Built": "ปีที่สร้างของทรัพย์สิน",
        "Neighborhood": "ย่านที่ตั้ง",
        "Property Neighborhood": "ย่านที่ตั้งของทรัพย์สิน",
        "Living Room": "ห้องนั่งเล่น",
        "Yes": "ใช่",
        "No": "ไม่",
        "Dining Room": "ห้องอาหาร",
        "Laundry Room": "ห้องซักผ้า",
        "Meeting room": "ห้องประชุม",
        "Game Room": "ห้องเกม",
        "Jacuzzi": "จาคูซี่",
        "Sauna": "ซาวน่า",
        "Maid Room": "ห้องแม่บ้าน",
        "Guest room": "ห้องพักแขก",
        "Parking": "ที่จอดรถ",
        "1-2 Car Garage": "1-2 ที่จอดรถ",
        "3 Car Garage": "3 ที่จอดรถ",
        "4 Car Garage": "4 ที่จอดรถ",
        "5 and More": "5 และมากกว่า",
        "Lot Size": "ขนาดห้อง",
        "Building area": "พื้นที่อาคาร",
        "View": "วิว",
        "Beach": "ชายหาด",
        "Mountain": "ภูเขา",
        "Lake": "ทะเลสาบ",
        "Cities Life": "ชีวิตในเมือง",
        "Rural hills": "ภูเขาทางชนบท",
        "Agent Thumbnail Picture": "รูปภาพขนาดย่อของตัวแทน",
        "Property Amenities": "สิ่งอำนวยความสะดวก",
        "Choose File": "เลือกไฟล์",
        "ThumbImage": "รูปประจำตัว",
        "Name": "ชื่อ",
        "Last Name": "นามสกุล",
        "Tel Number": "หมายเลขโทรศัพท์",
        "WhatsApp/WeChat/LINE ID": "วีแชท/วีแชท/ไลน์ไอดี",
        "What area do you want to sell real estate?": "คุณต้องการขายอสังหาริมทรัพย์ในพื้นที่ใด?",
        "area": "พื้นที่?",
        "Type of real estate you're interested in selling:": "ประเภทอสังหาริมทรัพย์ที่คุณสนใจขาย:",
        "It has been saved successfully": "ได้รับการบันทึกเรียบร้อยแล้ว",

        "WhatsApp": "หมายเลข WhatsApp",
        "Province": "จังหวัด",
        "Province/City": "จังหวัด/เมือง",
        "Country": "ประเทศ",
        "Title": "หัวข้อ",
        "Slug": "สลัก",
        "Meta Tag": "แท็กเมต้า",
        "Meta keywords": "คีย์เวิร์ดเมต้า",
        "I Agree to the terms & Conditions of Property Submission":
          "ฉันยินยอมตามข้อกำหนดและเงื่อนไขของการส่งทรัพย์",
        "View Agencies": "ดูหน่วยงาน",
        "View Brokers": "ดูโบรกเกอร์",
        "Sqft": "ตารางฟุต",
        "Schedule Link tour": "ลิงค์กำหนดการทัวร์",
        "Type": "พิมพ์",
        "lotSize": "ขนาดห้อง",
        "view": "มุมมอง",
        "Condition": "เงื่อนไข",
        "Direction": "ทิศทาง",
        "Price Range In the area Start": "ช่วงราคา ในพื้นที่เริ่มต้น",
        "HIGHEST": "สูงสุด",
        "LOWEST": "ต่ำสุด",
        "Features": "สิ่งที่โดดเด่น",
        "propertyId": "รหัสทรัพย์",
        "parking": "ที่จอดรถ",
        "buildingFloor": "ชั้นของอาคาร",
        "yearBuilt": "ปีที่สร้าง",
        "propertyType": "ประเภททรัพย์",
        "propertySize": "ขนาดทรัพย์",
        "lotSize": "ขนาดห้อง",

        "view": "วิว",
        "bathrooms": "ห้องน้ำ",
        "bedRooms": "ห้องนอน",
        "sqft": "ตร.ฟุต",
        "fullName": "ชื่อเต็ม",
        "subject": "หัวข้อ",
        "emailAddress": "ที่อยู่อีเมล",
        "yourMessage": "ข้อความของคุณ",
        "sendMessage": "ส่งข้อความ",
        "send": "ส่ง",

        "placeholderFullName": "ชื่อเต็ม",
        "placeholderSubject": "หัวข้อ",
        "placeholderEmailAddress": "ที่อยู่อีเมล",
        "placeholderYourMessage": "กรุณาพิมพ์ข้อความของคุณ...",
        "newYork": "นิวยอร์ก",
        "usa": "สหรัฐอเมริกา",
        "CALIFORNIA": "แคลิฟอร์เนีย",
        "355 South Grand Street, Suite # 2450 Los Angeles, Ca 90071": "355 South Grand Street, ห้อง # 2450 ลอสแอนเจลิส, แคลิฟอร์เนีย 90071",
        "Dubai": "ดูไบ",
        "Middle East": "ตะวันออกกลาง",
        "ThaiLand": "ประเทศไทย",
        "South East Asia": "เอเชียตะวันออกเฉียงใต้",
        "TUNGSONGHONE, LAKSI BANGKOK, 10210 THAILAND": "อังซองฮอน หลักสี่ กรุงเทพฯ 10210 ประเทศไทย",
        "Uk": "สหราชอาณาจักร",
        "Europe": "ยุโรป",
        "tbilsi": "ทบิลซี",
        "georgia": "จอร์เจีย",
        "moscow": "มอสโก",
        "russia": "รัสเซีย",
        "cairo": "ไคโร",
        "egypt": "อียิปต์",
        "locationAddress": "{{name}} {{address}}",
        "phoneLink": "(478) 339 120",
        "usaAdd": "เซซิเลีย แชปแมน 711-2880 ถนนนัลลา",
        "tbilsiAdd": "แอรอน ฮอว์กินส์ 5587 นันท์ อเวนิว",
        "moscowAdd": "ลอว์เรนซ์ โมเรโน 935-9940 ทอร์เตอร์ ถนน",
        "Few": "น้อย",
        "More": "มากกว่า",
        "What's Nearby": "สิ่งที่อยู่ใกล้เคียง",
        "Property Video": "วิดีโอเกี่ยวกับอสังหาริมทรัพย์",
        "Property History": "ประวัติทรัพย์สิน",
        "Schedule Tour": "ตารางทัวร์",
        "prevListing": "รายการก่อนหน้า",
        "nextListing": "รายการถัดไป",
        "prevLocation": "เซโดร์ โลว์ อะซูซ่า นิวยอร์ก 39531",
        "nextLocation": "เซซิเลีย แชปแมน แมงคาโต้ มิสซิสซิปปี 96522",
        "Calculate": "คำนวณ",
        "Mortgage Calculator": "เครื่องคำนวณสินเชื่อที่อยู่อาศัย",
        "homeValue": "มูลค่าบ้าน",
        "downPayment": "เงินดาวน์",
        "loanAmount": "จำนวนเงินกู้",
        "interestRate": "อัตราดอกเบี้ย",
        "loanDuration": "ระยะเวลาของสินเชื่อ (ปี)",
        "calculate": "คำนวณ",
        "enterHomeValue": "ป้อนมูลค่าบ้าน",
        "enterDownPayment": "ป้อนเงินทุนของคุณ",
        "enterInterestRate": "ป้อนอัตราดอกเบี้ยของคุณ",
        "enterLoanDuration": "ป้อนระยะเวลาของสินเชื่อ",
        "fundsNeeded": "เงินทุนที่ต้องการ",
        "Monthly payment": "การชำระเงินรายเดือน",
        "fullName": "ชื่อเต็ม",
        "emailAddress": "ที่อยู่อีเมล",
        "phoneNumber": "หมายเลขโทรศัพท์",
        "date": "วันที่",
        "Residential": "ที่อยู่อาศัย",
        "Hotel": "โรงแรม",
        "Commercial": "ทางการค้า",
        "Resort": "รีสอร์ท",
        "desfaq": "หากคุณมีคำถามเกี่ยวกับบ้านและที่ดินหรือต้องการขายบ้านผ่านเรา กรุณาติดต่อเราได้ที่โทร.+66-097-392-4632",
        "Land": "ที่ดิน",
        "Warehouse & Factories": "คลังสินค้าและโรงงาน",
        "typeYourComment": "พิมพ์ความคิดเห็นของคุณ...",
        "Meet The Agent": "พบกับตัวแทน",
        "lineid": "เส้นตรง",
        "Freddy Burben": "เฟรดดี้ เบอร์เบน",
        "Company Agent": "ตัวแทนบริษัท",
        "callAgent": "โทรหาตัวแทน",
        "office_location": "ค้นหาสำนักงานของเราเพื่อขอความช่วยเหลือและบริการได้อย่างง่ายดาย",
        "viewListings": "ดูรายการ",
        "saveAgent": "บันทึกตัวแทน",
        "Recent Listings": "รายการล่าสุด",
        "Print Listing": "พิมพ์รายการ",
        "moveOnlineTitle": "เรามีทรัพย์สินนับพัน",
        "moveOnlineTitle2": "ที่อยู่ภายใต้มูลค่าตลาด",

        "Searching...": "กำลังค้นหา...",
        "Villa Property Not Found": "ไม่พบทรัพย์สินวิลล่า",
        "Land Property Not Found": "ไม่พบทรัพย์สินที่ดิน",
        "Residential Property Not Found": "ประเภทไม่พบทรัพย์สินที่อยู่อาศัย",
        "Resort Property Not Found": "ไม่พบทรัพย์สินของรีสอร์ท",
        "Condo Property Not Found": "ไม่พบทรัพย์สินคอนโด",
        "Apartments Property Not Found": "ไม่พบทรัพย์สินของอพาร์ทเมนท์",
        "Hotel Property Not Found": "ไม่พบทรัพย์สินของโรงแรม",
        "Island Property Not Found": "ไม่พบคุณสมบัติของเกาะ",
        "Apartments": "อพาร์ตเมนต์",
        "Sorry, the ":
          "ขออภัย ประเภทอสังหาริมทรัพย์เฉพาะที่คุณกำลังค้นหาคือ  ขณะนี้ยังไม่มีให้บริการบนเว็บไซต์ของเรา โปรดตรวจสอบอีกครั้ง  ในภายหลังหรือติดต่อเราเพื่อขอความช่วยเหลือเพิ่มเติม",
        "searchDescription": "ตอนนี้คุณสามารถค้นหาอสังหาริมทรัพย์ที่ดีที่สุดในราคาต่ำในประเทศไทยได้แล้ว",
        "searchPlaceholder": "ค้นหาตามที่ตั้ง สถานี ชื่อคอนโด หรือคำค้นหา",
        "buyRentSell": "ซื้อ/เช่า/ขาย",
        "any": "ทุกประการ",
        "buy": "ซื้อ",
        "sell": "ขาย",
        "propertyType": "ประเภททรัพย์",
        "anyType": "ทุกรูปแบบ",
        "searchButton": "ค้นหา",
        "minPrice": "ราคาต่ำสุด",
        "maxPrice": "ราคาสูงสุด",
        "beds": "เตียง",
        "bath": "ห้องน้ำ",
        "buildingArea": "พื้นที่สร้าง",
        "landArea": "พื้นที่ดิน",
        "browseByCategory": "เรียกดูตามหมวดหมู่",
        "Residential & Condo & Apartment": "ที่อยู่อาศัยและคอนโดและอพาร์ตเมนต์",
        "Commercial ": "ทางการค้า",
        "Business ": "ธุรกิจ",

        "Warehouse & Factories": "โกดังและโรงงาน",


        "Category": "หมวดหมู่",
        "ourMotto": "ขีดคร้านของเรา",
        "chooseToWorkWithUs": "ทำไมต้องเลือกทำงานกับเรา",
        "Customer guaranteed": "รับประกันลูกค้า",
        "Reliable and guaranteed satisfaction for all our customers' needs.": "ความพึงพอใจที่เชื่อถือได้และรับประกันสำหรับทุกความต้องการของลูกค้าของเรา",
        "Higher return on investment ": "ผลตอบแทนจากการลงทุนที่สูงขึ้น",
        "Maximized Returns: Achieving More with Your Investments": "ผลตอบแทนสูงสุด: บรรลุผลสำเร็จมากขึ้นด้วยการลงทุนของคุณ",
        "Fast and easy loan approval": "การอนุมัติสินเชื่อที่ง่ายและรวดเร็ว",
        "Quick and Simple Loan Approvals": "การอนุมัติสินเชื่อที่ง่ายและรวดเร็ว",
        "One-Stop Full Service": "บริการครบวงจรแบบครบวงจร",
        "A comprehensive and all-encompassing solution for various services and needs.": "โซลูชั่นที่ครบวงจรและครอบคลุมสำหรับบริการและความต้องการที่หลากหลาย",
        "Best Price on the market ": "ราคาที่ดีที่สุดในตลาด",
        "We provide foreclosure property with low price ": "เราให้บริการทรัพย์สินยึดสังหาริมทรัพย์ในราคาถูก",


        "trending": "ยอดนิยม",
        "ourTopListings": "รายการยอดนิยมของเรา",
        "No top list available": "ไม่มีรายการยอดนิยม",
        "viewDetails": "ดูรายละเอียด",
        "latestNews": "ข่าวล่าสุด",
        "bestDealOfTheMonth": "ดีลที่ดีที่สุดของเดือน",
        "No Data": "ไม่มีข้อมูล",
        "FAQ": "คำถามที่พบบ่อย",
        "Can a foreigner buy property in Thailand?": "ชาวต่างชาติสามารถซื้ออสังหาริมทรัพย์ในประเทศไทยได้หรือไม่?",
        "Yes, in some type of property that foreigner can own 100% right of the property like condo, but in a single family residential or land, there are certain artical of Thai laws that foreigner can buy property. Foreigner will need to contact real estate company that have experience in this field ": `ใช่ ในอสังหาริมทรัพย์บางประเภทที่ชาวต่างชาติสามารถเป็นเจ้าของสิทธิในทรัพย์สินได้ 100% เช่น คอนโด แต่ในที่อยู่อาศัยครอบครัวเดี่ยวหรือที่ดิน มีกฎหมายไทยบางประการที่ชาวต่างชาติสามารถซื้ออสังหาริมทรัพย์ได้
         ชาวต่างชาติจะต้องติดต่อกับบริษัทอสังหาริมทรัพย์ที่มีประสบการณ์ในด้านนี้`,

        "Can foreigner get a long stay visa? ": "ชาวต่างชาติสามารถขอวีซ่าพำนักระยะยาวได้หรือไม่?",
        "Sure, there are immigration laws that open for foreigner to be able to live in Thailand for long period of time. You will need to get a consultation with immigration office or an attorney": `แน่นอนว่ามีกฎหมายคนเข้าเมืองที่เปิดให้ชาวต่างชาติสามารถอยู่ในประเทศไทยได้เป็นเวลานาน 
         คุณจะต้องขอคำปรึกษาจากสำนักงานตรวจคนเข้าเมืองหรือทนายความ`,

        "Can a foreigner get a mortgage loan in Thailand?": "ชาวต่างชาติสามารถรับสินเชื่อจำนองในประเทศไทยได้หรือไม่?",
        "yes, We had done thousand of deal in getting foreigner approved on their loans. You need to deal with a company that know how to put together and process a loans the right way. You will need a loan processing company that know how to deal with your local bank and connect with Thai local bank to help complete the loan process. ": "ใช่ เราได้ทำข้อตกลงนับพันครั้งในการขออนุมัติสินเชื่อจากชาวต่างชาติ คุณต้องติดต่อกับบริษัทที่รู้วิธีรวบรวมและดำเนินการสินเชื่ออย่างถูกวิธี คุณจะต้องมีบริษัทประมวลผลสินเชื่อที่รู้วิธีจัดการกับธนาคารในประเทศของคุณและเชื่อมต่อกับธนาคารในประเทศของไทยเพื่อช่วยดำเนินการเงินกู้ให้เสร็จสิ้น",

        "Can I own a business in Thailand? ": "ฉันสามารถเป็นเจ้าของธุรกิจในประเทศไทยได้หรือไม่?",
        "Of course, Thailand is welcoming a foreign investor, but their Thai laws prohibit foreign investor to hold a 100% ownership in Thailand, but there are many exception that give 100% ownership right in doing business in Thailand": "แน่นอนว่าประเทศไทยยินดีต้อนรับนักลงทุนต่างชาติ แต่กฎหมายไทยห้ามไม่ให้นักลงทุนต่างชาติถือกรรมสิทธิ์ในไทย 100% แต่มีข้อยกเว้นหลายประการที่ให้สิทธิความเป็นเจ้าของ 100% ในการดำเนินธุรกิจในประเทศไทย",


        "Can I invest in real estate in Thailand? ": "ฉันสามารถลงทุนในอสังหาริมทรัพย์ในประเทศไทยได้หรือไม่?",
        "Of course, You can invest in to any business in Thailand. You need to consult Thai attorney on how to setup or invest in the real estate in Thailand ": "แน่นอนว่าคุณสามารถลงทุนในธุรกิจใดๆ ในประเทศไทยได้ คุณต้องปรึกษาทนายความไทยเกี่ยวกับวิธีการตั้งค่าหรือลงทุนในอสังหาริมทรัพย์ในประเทศไทย",

        "Reach Out": "เอื้อมมือออก",
        "Call Us": "โทรหาเรา",
        "Find Us": "ค้นหาเรา",
        "Get Directions": "รับเส้นทาง",




        "testimonials": "คำรับรอง",
        "whatPeopleAreSaying": "คนพูดอะไร",
        "createAnAcresAccount": "สร้างการซื้อบ้านด้วยบัญชีที่น้อยลง",
        "username": "ชื่อผู้ใช้",
        "selectEmailOrPhoneNumber": "เลือกอีเมลหรือหมายเลขโทรศัพท์",
        "emailAddress": "ที่อยู่อีเมล",
        "mobileNumber": "หมายเลขโทรศัพท์มือถือ",
        "password": "รหัสผ่าน",
        "user": "ผู้ใช้",
        "buyer": "ผู้ซื้อ",
        "seller": "ผู้ขาย",
        "agent": "ตัวแทน",
        "register": "ลงทะเบียน",
        "continueWithFacebook": "ดำเนินการต่อด้วย Facebook",
        "continueWithGoogle": "ดำเนินการต่อด้วย Google",
        "alreadyHaveAccount": "已经有帐户？",

        "login": "เข้าสู่ระบบ",
        "logIntoAcres": "เข้าสู่ระบบซื้อบ้านในราคาที่ถูกลง",
        "selectEmailOrPhone": "เลือกอีเมลหรือหมายเลขโทรศัพท์",
        "select": "เลือก",
        "emailAddress": "ที่อยู่อีเมล",
        "mobileNumber": "หมายเลขโทรศัพท์มือถือ",
        "Room Floor":"พื้นห้อง",
        "password": "รหัสผ่าน",
        "forgotPassword": "ลืมรหัสผ่าน?",
        "login": "เข้าสู่ระบบ",
        "Status": "สถานะ",
        "Search listings": "ค้นหารายการ",
        "Price Range": "ช่วงราคา",
        "Diameters": "เส้นผ่านศูนย์กลาง",
        "propertyNotFoundHeading": "ไม่พบทรัพย์สิน",
        "propertyNotFoundDescription": "ขออภัยครับ ประเภททรัพย์ที่คุณกำลังค้นหายังไม่มีบนเว็บไซต์ของเราในขณะนี้ กรุณากลับมาตรวจสอบในภายหลังหรือติดต่อเราสำหรับความช่วยเหลือเพิ่มเติม",
        "Advanced Search": "การค้นหาขั้นสูง",
        "Search locations": "ค้นหาสถานที่",
        "Floor": "พื้น",
        "Sort by": "เรียงตาม",
        "Apply filters": "ใช้ตัวกรอง",
        "Filter": "กรอง",
        "About Us": "เกี่ยวกับเรา",
        "Financial Planing": "การวางแผนทางการเงิน",
        "Buy home for": "ซื้อบ้านในราคาที่ถูกลงสามารถช่วยคุณในการวางแผนทางการเงินเพื่อช่วยให้คุณพร้อมสำหรับบ้านในฝันต่อไป เราให้คำปรึกษาฟรีแก่คุณเพื่อช่วยให้คุณประหยัดเงิน และรู้ว่าเมื่อใดพร้อมให้คุณซื้อบ้านหลังต่อไป เรามีโปรแกรมมากมายให้คุณโดยไม่ต้องใช้เงินออมเพื่อดาวน์บ้าน เราจะทำงานร่วมกับคุณในการจัดการงบประมาณที่สามารถช่วยให้คุณชำระเงินจำนองได้อย่างสะดวกสบาย",

        "Loan Modification": "การปรับเปลี่ยนสินเชื่อ",
        "Property Management": "การจัดการทรัพย์สิน",
        "If you enjoying": "หากคุณชอบมีรายได้แบบ Passive Income แต่ไม่อยากติดต่อกับผู้เช่า เราสามารถช่วยคุณในการหาผู้เช่าใหม่หรือไล่พวกเขาออก เราสามารถจัดการทรัพย์สินของคุณได้อย่างเต็มที่ในสภาพที่เป็นมาตรฐานสูงสุด เราเก็บค่าเช่าและบำรุงรักษาทรัพย์สินอย่างเต็มที่ให้กับคุณ คุณไม่ต้องกังวลเกี่ยวกับทรัพย์สินของคุณ เราสามารถดูแลขั้นตอนที่จำเป็นทั้งหมดเพื่อให้คุณได้รับค่าเช่าตรงเวลา และค้นหาผู้เช่าที่เหมาะสมที่จะรักทรัพย์สินของคุณเช่นเดียวกับคุณ",

        "Investment Planner": "นักวางแผนการลงทุน",
        "If you are looking": "หากคุณกำลังมองหาผลตอบแทนการลงทุนสูงสุด เราสามารถช่วยคุณได้ด้วยทางออกที่ดีที่สุดที่จะช่วยให้คุณได้รับผลตอบแทนที่สูงขึ้น เราสามารถค้นหาการลงทุนที่ดีที่สุดที่เหมาะกับงบประมาณของคุณและให้ความอุ่นใจว่าเงินทุกบาทที่คุณลงทุนจะปลอดภัยและมีความเสี่ยงน้อยลง ทีมวางแผนการลงทุนของเราได้ช่วยเหลือนักลงทุนนับพันรายให้ได้รับผลประโยชน์เต็มที่และได้รับผลตอบแทนจากการลงทุนสูงสุด,,",

        "How to buy poperty in Thailand": "วิธีการซื้ออสังหาริมทรัพย์ในประเทศไทย",
        "Get Long Term Visa in Thailand": "รับวีซ่าระยะยาวในประเทศไทย",
        "Thailand Real estate Laws": "กฎหมายอสังหาริมทรัพย์ของประเทศไทย",
        "How to retire in Thailand": "วิธีการเกษียณอายุในประเทศไทย",
        "Pricing": "ราคา",
        "OR": "หรือ",
        "Rent": "เช่า",
        "Name is Required": "⚠️ จำเป็นต้องระบุชื่อ",
        "Last Name is Required": "⚠️ นามสกุลเป็นสิ่งจำเป็น",
        "Address is Required": "⚠️ ต้องระบุที่อยู่",
        "Tumbol is Required": "⚠️ จำเป็นต้องมีถัง",

        "City/Amphor is Required": "⚠️ ต้องระบุเมือง/อำเภอ",
        "States/Province is Required": "⚠️ ต้องระบุรัฐ/จังหวัด",
        "Country is Required": "⚠️ ต้องระบุประเทศ",
        "Line Id is Required": " ⚠️ ต้องระบุไอดีไลน์",
        "WhatsApp number must only contain digits": " ⚠️ หมายเลข WhatsApp ต้องมีเฉพาะตัวเลขเท่านั้น",
        "WhatsApp number is too short": " ⚠️ หมายเลข WhatsApp สั้นเกินไป",
        "WhatsApp number is too long": " ⚠️ หมายเลข WhatsApp ยาวเกินไป",
        "WhatsApp Number is Required": " ⚠️ ต้องมีหมายเลข WhatsApp",
        "Wechat is Required": " ⚠️ ต้องใช้วีแชท",
        "Tel Number is Required": " ⚠️ หมายเลขโทรศัพท์เป็นสิ่งจำเป็น",
        "Too short": " ⚠️ สั้นเกินไป",
        "Too long": " ⚠️ นานเกินไป",
        "Video Url is Required": " ⚠️ ต้องระบุ URL ของวิดีโอ",
        "At least one image is required": " ⚠️ ต้องมีอย่างน้อยหนึ่งภาพ",
        "File is required": " ⚠️ จำเป็นต้องมีไฟล์",
        "Real Estate Type": "ประเภทอสังหาริมทรัพย์",
        "Step1": "ขั้นตอนที่ 1",





        "Please Select Option": "⚠️ โปรดเลือกตัวเลือก",
        "Please Select Country Code": "⚠️ กรุณาเลือกรหัสประเทศ",
        "Invalid email": "⚠️ อีเมลไม่ถูกต้อง",
        "Email is required": "⚠️ จำเป็นต้องระบุอีเมล",
        "Phone number must only contain digits": "หมายเลขโทรศัพท์ต้องมีเฉพาะตัวเลขเท่านั้น",
        "Phone number is too short": "หมายเลขโทรศัพท์สั้นเกินไป ควรมีอย่างน้อย 10 หลัก",
        "Phone number is too long": "หมายเลขโทรศัพท์ยาวเกินไป ควรน้อยกว่า 15 หลัก",
        "Phone number is required": "⚠️ ต้องระบุหมายเลขโทรศัพท์",
        "Password must contain": "รหัสผ่านต้องมีตัวพิมพ์ใหญ่ ตัวเลขหนึ่งตัว และสัญลักษณ์พิเศษอย่างน้อยหนึ่งตัว",
        "Password must be at least 8 characters long": "รหัสผ่านต้องมีความยาวอย่างน้อย 8 ตัวอักษร",
        "Password is required": "⚠️ ต้องใช้รหัสผ่าน",
        "Please Select": "⚠️ โปรดเลือก",
        "please Select": "โปรดเลือก",
        "On Sale": "ลดราคา",
        "Pending": "รอดำเนินการ",
        "Rental": "เช่า",
        "Provience": "โพรวองซ์",
        "Newpassword": "รหัสผ่านใหม่",
        "confirmPassword": "ยืนยันรหัสผ่าน",
        "Code": "รหัส",
        "Code is required": " ⚠️ จำเป็นต้องระบุรหัส",
        "forgotIntoAcres": " ลืมIntoAcres",
        "Passwords must match": " รหัสผ่านต้องตรงกัน",
        "Confirm Password is required": " จำเป็นต้องยืนยันรหัสผ่าน",
        "Buying property in Thailand": `การซื้ออสังหาริมทรัพย์ในประเทศไทยในฐานะชาวต่างชาติอาจเป็นเรื่องที่น่ากังวลและท้าทาย
         มีหลายปัจจัยในกฎหมายไทยที่คุณได้รับข้อมูลอย่างดี แต่เราสามารถช่วยให้คุณได้รับอสังหาริมทรัพย์แบบฟรีโฮลด์ เช่น คอนโด แต่หากคุณกำลังมองหาบ้านพักตากอากาศหรืออสังหาริมทรัพย์อื่นใด
          เราสามารถจัดหาอสังหาริมทรัพย์นับร้อยให้กับคุณได้ ให้คุณเลือก เราสามารถจัดเตรียมสิทธิการเช่าอสังหาริมทรัพย์บางประเภทได้นานถึง
         90 ปี และหากคุณต้องการลงทุนในอสังหาริมทรัพย์เชิงพาณิชย์ เราสามารถจัดเตรียมให้คุณเป็นเจ้าของทรัพย์สินเชิงพาณิชย์ 100%`,

        "We can help you get": `เราช่วยให้คุณได้รับวีซ่าระยะยาวเป็นเวลา 10 ปี และคุณสามารถต่ออายุได้เมื่อวีซ่าหมดอายุ
         กฎหมายคนเข้าเมืองของไทยและการเปลี่ยนแปลงในช่วงไม่กี่ปีที่ผ่านมาที่ช่วยให้ชาวต่างชาติอยู่อาศัยในประเทศไทยได้นานขึ้น
         เมื่อคุณได้รับการอนุมัติวีซ่า 10 ปีแล้ว คุณสามารถสมัครเพื่อรับสิทธิประโยชน์มากมายที่ได้รับจากคนไทย
         รัฐบาล. เราสามารถจัดเตรียมและค้นหาการลงทุนที่เหมาะสมหรือการดูแลสุขภาพอื่น ๆ ให้กับคุณได้โดยไม่มีอุปสรรค`,

        "It is not possible": `ชาวต่างชาติไม่สามารถถือครองที่ดินในนามของตนเองได้
          คุณสามารถเป็นเจ้าของที่ดินได้หากคุณแต่งงานกับบุคคลสัญชาติไทย ชาวต่างชาติที่เป็นทายาทโดยชอบด้วยกฎหมายในการรับมรดกที่ดินสามารถทำได้
           แต่ต้องขายที่ดินภายในหนึ่งปีนับแต่วันที่ได้มาซึ่งที่ดิน .`,
        "Person": `ผู้ที่มีอายุ 50 ปีขึ้นไปสามารถยื่นขอวีซ่าเกษียณอายุในประเทศไทยได้ ซึ่งจะทำให้การเข้าพักในประเทศไทยของคุณปราศจากความยุ่งยาก คุณสามารถยื่นขอวีซ่าเกษียณอายุในประเทศไทยได้ตามขั้นตอนด้านล่าง`,

        "titlee": "ขั้นตอนที่ 1: ขอวีซ่าที่ไม่ใช่ผู้กลับถิ่นถาวร - หมวด 'O'",
        "requirements": "ข้อกำหนด:",
        "passport": "หนังสือเดินทางที่เหลืออายุอย่างน้อย 1 ปี",
        "accommodation": "หลักฐานที่พักอาศัยในประเทศไทย (หากคุณยื่นขอวีซ่านี้ในประเทศไทย)",
        "bankStatement": "รายการเครื่องชำระเงินแสดงหลักฐานเงินทุน",
        "healthInsurance": "ประกันสุขภาพประจำปี (ตามที่สถานเอกอัครราชทูตหรือคณะกงสุลต่างประเทศของประเทศไทยบางแห่งกำหนด)",
        "nationalityResidence": "คุณต้องเป็นผู้ถือสัญชาติหรือถือสิทธิ์การพำนักถาวรของประเทศที่ยื่นขอวีซ่า",
        "applicationLocation": "ยื่นขอที่สถานเอกอัครราชทูตไทยต่างประเทศหรือสำนักงานตรวจคนเข้าเมืองไทยภายในประเทศไทย",
        "Step2": "ขั้นตอนที่ 2",
        "nonImmigrantOExtensionRequirements": "ข้อกำหนดการขอวีซ่า O หมวดการขอขยายอายุ:",
        "mustBe50YearsOld": "ต้องมีอายุ 50 ปีขึ้นไป",
        "passport": "หนังสือเดินทาง (สำเนาลายเซ็นบนทุกหน้า)",
        "nonImmigrantOVisa": "วีซ่า O หมวดการขอขยายอายุ",
        "tm30Receipt": "ใบเสร็จรับเงิน TM30",
        "proofOfMeetingFinancialRequirements": "หลักฐานที่แสดงการปฏิบัติตามข้อกำหนดทางการเงิน เช่น รายการธนาคาร (สำหรับรายได้)",
        "incomeCertificate": "หนังสือรับรองรายได้จากสถานทูตของประเทศไทย (สัญชาติของผู้ขอ)",
        "thaiBankBook": "สมุดบัญชีธนาคารไทยที่แสดงบัญชีเงินฝาก (เอกสารต้นฉบับ)",
        "letterFromThaiBank": "จดหมายจากธนาคารไทย (ใบรับรองต้องลงนามโดยเจ้าหน้าที่ธนาคาร)",
        "photos": "รูปถ่ายขนาด 4x5 ซม. จำนวน 3 รูป ถ่ายใหม่ล่าสุดในช่วง 6 เดือนที่ผ่านมา",
        "proofOfPermanentAccommodation": "หลักฐานที่แสดงที่พักถาวรในประเทศไทย เช่น สัญญาเช่าหรือสัญญาเช่า",
        "medicalCertificateAndCriminalRecordCertificate": "ไม่จำเป็นต้องให้เอกสารรับรองแพทย์และเอกสารระเบียบวินัยสำหรับกระบวนการสมัครวีซ่าเพื่อเข้าเมืองเกษียณภายในประเทศไทย",


        "Step3": "ขั้นตอนที่ 3",
        "SQMBuildingSize":"ขนาดอาคาร ตร.ม",
        "step3Description": `ส่วนใหญ่คนมักเข้าใจความแตกต่างระหว่าง "การต่ออายุการอยู่" และ "ใบอนุญาตเข้าประเทศอีกครั้ง" ผิด ๆ กัน บางครั้งมักสับสนระหว่างกัน แต่คุณต้องเข้าใจว่าเป็นสองสิ่งที่แตกต่างกัน ทั้งสองคือสิ่งที่คุณต้องการในระหว่างการอยู่ของคุณ 
            การต่ออายุการอยู่คือเพียงแค่วันที่ควบคุมสำหรับระยะเวลาการอยู่ของคุณ ทุกอย่างอื่นขึ้นอยู่กับสิ่งนี้ ทุกอย่างจะถูกยกเลิกเมื่อการต่ออายุการอยู่สิ้นสุด 
            การต่ออายุการอยู่มักมีระยะเวลาใช้งานประมาณ 1 ปี ในทางตรงข้าม ความถูกต้องของใบอนุญาตเข้าประเทศอีกครั้งขึ้นอยู่กับการต่ออายุการอยู่ของคุณ 
            อย่างไรก็ตาม หากออกจากประเทศไทยโดยไม่มีใบอนุญาตเข้าประเทศอีกครั้ง จะทำให้การต่ออายุการอยู่หรือระยะเวลาการอยู่ของคุณเป็นโมฆะโดยอัตโนมัติ`,
        "reentryPermitOptions": "ใบอนุญาตเข้าประเทศอีกครั้งมาพร้อมกับตัวเลือกสองแบบสำหรับผู้ถือวีซ่าเกษียณในประเทศไทย:",
        "singleEntry": "สิบล้าน (1,000 บาท)",
        "multipleEntries": "หลายรายการ (3,800 บาท)",
        "reentryPermitApplication": "การยื่นใบอนุญาตเข้าประเทศอีกครั้งจะต้องส่งที่สนามบินในประเทศไทย สำนักงานตรวจคนเข้าเมืองมีเจ้าหน้าที่ตรวจคนเข้าเมืองที่ระบบควบคุมบัตรประชาชนเพื่อดำเนินการขอใบอนุญาตเหล่านี้",


        "Step4": "ขั้นตอนที่ 4",
        "reportingStayDescription": "ผู้สมัครจะต้องรายงานต่อสำนักงานตรวจคนเข้าเมืองทุก 90 วันหากคุณมีวีซ่าต่ออายุระยะยาว ผู้ถือวีซ่าหลายครั้งเพียงแค่ออกจากประเทศไทยและต่ออายุการอยู่ของพวกเขาหลังจากทุก 90 วันที่เข้าประเทศ",
        "renewExtensionOfStay": "หากคุณไม่สามารถขออายุการอยู่ในประเทศไทยได้ คุณจะต้องขอวีซ่า O ที่ไม่ใช่ของต่างประเทศจากสถานเอกอัครราชทูตหรือคณะกงสุลต่างประเทศของประเทศไทย",
        "summaryTitle": "สรุป",
        "summaryDescription": `ขณะนี้คุณเป็นผู้ถือวีซ่าการอยู่ระยะยาว 1 ปี เป็นเพียงการต่ออายุการอยู่สำหรับวัตถุประสงค์การเกษียณ
               ไม่จำเป็นต้องมีประกันสุขภาพ แต่ผู้สมัครต้องยื่นขอวีซ่าภายในประเทศไทยเพื่อยกเว้นจากข้อกำหนดเรื่องประกัน อย่าลืมที่จะรายงานทุก 3 เดือนหรือที่เรียกว่า 'รายงาน 90 วัน'
                สามารถยื่นในโหมดออนไลน์หรือส่งมาที่สำนักงานตรวจคนเข้าเมืองได้เช่นกัน`,

        "Home": "บ้าน",
        "Buy": "ซื้อ",
        "Thing To Know": "สิ่งที่ต้องรู้",
        "Residential": "ที่อยู่อาศัย",
        "Condo": "คอนโด",
        "Apartment": "อพาร์ทเม้น",
        "Hotel": "โรงแรม",
        "Resort": "รีสอร์ท",
        "Villa": "วิลล่า",
        "Land": "ที่ดิน",
        "Island": "เกาะ",
        "Promotion Occurs": "โปรโมชั่นเกิดขึ้น",
        "ok": "ตกลง",
        "Buying a Home?": "ซื้อบ้าน?",
        "Selling a Home?": "ขายบ้าน?",
        "Don’t walk away": "อย่าเดินออกจากบ้านหากคุณประสบปัญหาทางการเงิน หากคุณกำลังเผชิญกับการยึดสังหาริมทรัพย์เนื่องจากเหตุการณ์ใด ๆ เราช่วยคุณแก้ปัญหาได้ เราติดต่อกับธนาคารและสถาบันการเงินมากกว่า 500 แห่งทั่วโลกที่ช่วยคุณค้นหาสินเชื่อที่ดีที่สุดสำหรับคุณ แม้ว่าเครดิตของคุณจะแย่อยู่แล้วก็ตาม เรามีสถาบันการเงินที่เชี่ยวชาญด้านทรัพย์สินด้อยคุณภาพ และสามารถเสนอโปรแกรมมากมายที่เหมาะกับงบประมาณทางการเงินของคุณได้ เรายังเสนอโปรแกรมที่ช่วยให้สินเชื่อของคุณเป็นปัจจุบันและหยุดการชำระเงินจำนองรายเดือนของคุณจนกว่าคุณจะกลับมายืนได้อีกครั้ง",
        "Let us help you": "ให้เราช่วยคุณแก้ไขปัญหาทางการเงินชั่วคราว เพื่อให้คุณสามารถรักษาบ้านของคุณไว้ได้",
        "Agent Registration Form": "แบบฟอร์มลงทะเบียนตัวแทน",
        "Select Image": "เลือกรูปภาพ",
        "Complain Form": "แบบฟอร์มการร้องเรียน",
        "Complain": "ร้องทุกข์",
        "Blog List": "รายการบล็อก",
        "Read More": "อ่านเพิ่มเติม",
        "Comments by:": "ความคิดเห็นโดย:",
        "Search Posts": "ค้นหาโพสต์",
        "Recent Posts": "โพสต์ล่าสุด",
        "What agencies are looking for in an agent": "เอเจนซี่กำลังมองหาอะไรในตัวตัวแทน",
        "Changing the way we sell and buy real estate": "ปลี่ยนวิธีการขายและซื้ออสังหาริมทรัพย์",
        "Families, its the best time to start buying": "ครอบครัว เป็นเวลาที่ดีที่สุดที่จะเริ่มซื้อ",
        "Our all new duplex apartment is up for grabs": "อพาร์ทเมนต์ดูเพล็กซ์ใหม่ของเราพร้อมแล้ว",
        "Popular Categories": "หมวดหมู่ยอดนิยม",
        "Living Rooms": "ห้องนั่งเล่น",
        "Real Estate": "อสังหาริมทรัพย์",
        "Kitchens": "ห้องครัว",
        "Flats": "แฟลต",
        "Luxury": "หรูหรา",
        "Bed Rooms": "卧室",
        "Popular Tags": "แท็กยอดนิยม",

        "House": "บ้าน",
        "Building": "อาคาร",
        "Agency": "เอเจนซี่",
        "Family": "ตระกูล",
        "Acres": "เอเคอร์",
        "Baths": "อาบน้ำ",
        "Rooms": "ห้องพัก",

        "Type of Property": "ประเภทของทรัพย์สิน",
        "Upload Your Property Images": "อัปโหลดภาพทรัพย์สินของคุณ",
        "Submit": "ส่ง",
        "Experience the beauty": `สัมผัสความงามและวัฒนธรรมของประเทศไทยด้วยวีซ่าระยะยาว
          เหมาะสำหรับผู้ที่ต้องการย้ายหรือลงทุนในอสังหาริมทรัพย์สิ่งนี้
          วีซ่าอนุญาตให้อยู่ต่อได้นานถึงหนึ่งปี ด้วยระยะยาว
          วีซ่า คุณจะมีโอกาสได้ดื่มด่ำไปกับ
          วิถีชีวิตแบบไทยและใช้ประโยชน์จากทุกประเทศที่มีให้
          ไม่ว่าคุณกำลังมองหาที่จะเกษียณอายุ เริ่มต้นธุรกิจ หรือเพียงเพลิดเพลินไปกับ
          วันหยุดยาว วีซ่าระยะยาวในประเทศไทยเหมาะที่สุด
          ทางเลือก. ติดต่อเราวันนี้เพื่อเรียนรู้เพิ่มเติมเกี่ยวกับการได้รับบริการระยะยาว
          วีซ่าและค้นหาทรัพย์สินที่สมบูรณ์แบบเพื่อให้เหมาะกับความต้องการของคุณ`,
        "Verification Code": "รหัสยืนยัน",
        "Enter your verification code": "ป้อนรหัสยืนยันของคุณ",
        "Verify": "ตรวจสอบ",

        "For Rent": "สำหรับเช่า",
        "Featured": "จุดเด่น",
        "For Sale": "สำหรับขาย",
        "Leased": "เช่าแล้ว",
        "New Addition": "นอกจากนี้ใหม่",
        "Sold": "ขายแล้ว",
        "Rental": "เช่า",
        "Reduced": "ที่ลดลง",
        "Special Offer": "ข้อเสนอพิเศษ",
        "Address": "ที่อยู่",
        "Get offer for your home": "รับข้อเสนอสำหรับบ้านของคุณ",
        "Tumbol": "ตำบล",
        "City/Amphor": "เมือง/โถ",
        "States/Province": "รัฐ/จังหวัด",
        "Line Id": "ไลน์ไอดี",
        "Wechat": "วีแชท",
        "Upload video": "อัปโหลดวิดีโอ",
        "Upload picture": "อัพโหลดรูปภาพ",

        "Property Location": "ที่ตั้งทรัพย์สิน",







        "Community Hall": "社区礼堂",
        "Swimming Pool": "สระว่ายน้ำ",
        "Gym": "健身房",
        "Mini Mart": "迷你超市",
        "Library": "图书馆",
        "Tennis Court": "网球场",
        "Jacuzzi": "按摩浴缸",
        "Sauna": "桑拿",
        "Party Hall": "宴会厅",
        "Guest Parking": "宾客停车场",
        "Beds": "床",
        "Bathrooms": "浴室",
        "Laundry Room": "洗衣房",
        "Meeting room": "会议室",
        "Game Room": "游戏厅",
        "Jacuzzi": "游戏厅",
        "Sauna": "桑拿",
        "Maid Room": "佣人房",
        "Guest room": "客房",
        "Parking": "停車處",
        "Dining Room": "饭厅",
        "Living Room": "客厅",


        "image is required": " ⚠️ จำเป็นต้องมีรูปภาพ",




        "User not found": "ไม่พบชื่อผู้ใช้",

        //footer
        "foothead": "ซื้อบ้านในราคาที่ถูกลง",
        "menu": "เมนู",
        "find_home": "ค้นหาบ้าน",
        "find_home2": "ค้นหาบ้านของคุณ",
        "listing": "รายการ",
        "add_listing": "เพิ่มรายการ",
        "become_agent": "เป็นตัวแทนของเรา",
        "Rental Guideline": "แนวทางการเช่า",

        "about_us": "เกี่ยวกับเรา",
        "complaint": "ร้องเรียน",
        "blogs_news": "บทความ/ข่าวสาร",
        "sell_property": "ขายทรัพย์สินให้เรา",
        "contact_us": "ติดต่อเรา",
        "legal": "กฏหมาย",
        "privacy_policy": "นโยบายความเป็นส่วนตัว",
        "refund_policy": "นโยบายการคืนเงิน",
        "cookie_policy": "นโยบายคุกกี้",
        "terms_condition": "เงื่อนไข",
        "find_agent_worldwide": "ค้นหาตัวแทนของเราทั่วโลก",
        "job": "งาน",
        "newsletter": "จดหมายข่าว",
        "newsletter_description": "เข้าร่วมจดหมายข่าวของเราวันนี้และได้รับอพาร์ทเมนท์ที่ดีที่สุดที่เรามีให้",
        "newsletter_placeholder": "ที่อยู่อีเมล",
        "join_newsletter": "เข้าร่วมจดหมายข่าว",
        "information": "ข้อมูล",
        "aboutUs": "เกี่ยวกับเรา",
        "contactUs": "ติดต่อเรา",
        "services": "บริการ",
        "Help With Distress Home": "ช่วยเหลือเรื่องความทุกข์ที่บ้าน",
        "Price and property can be change without notice": "ราคาและทรัพย์สินสามารถเปลี่ยนแปลงได้โดยไม่ต้องแจ้งให้ทราบล่วงหน้า",
        "Buyhomeforless.com © 2020 All rights reserved": "Buyhomeforless.com © 2020 สงวนลิขสิทธิ์",
        "Any Land Area": "พื้นที่ที่ดินใดๆ",
        "Floor Plan": "ผังพื้น",
        "Floor Images": "รูปภาพชั้นเป็นตัวเลือก",
        "No floor images": "ไม่มีภาพพื้น",
        "Select the value": "เลือกค่า",
        "Furnished": "พร้อมเฟอร์นิเจอร์",
        "Type of Furnish": "ประเภทของเฟอร์นิเจอร์",
        "Maintenance Fee": "ค่าบำรุงรักษา",
        "Stove": "เตา",
        "Refrigerator": "ตู้เย็น",
        "Air Condition": "เครื่องปรับอากาศ",
        "Bed": "เตียง",
        "TV": "โทรทัศน์",
        "Agent Info": "ข้อมูลตัวแทน",
        "City": "เมือง",
        "Satisfactory":"น่าพอใจ",
        "Fixer upper":"ตัวยึดด้านบน",
        "Agent Service":"ตัวแทน พื้นที่ให้บริการ",
        "ROOM AREA":"บริเวณห้องพัก",
        "Pent House":"เพนต์เฮาส์",
        "Room#":"ห้อง#"
      },
    },
  },
  lng: "English",
  fallbackLng: "English",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
