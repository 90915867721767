
import React from "react";
import { Button } from "antd";
import { RiCloseLine } from "react-icons/ri"; // Close icon
import TextContent from "../sections/Condition-Text/TextContent";

const ConditionModal = ({
  isOpen,
  closeModal,
  selectedReview,
  backgroundColor,
  textColor,
  acceptCondition,
  value
}) => {
  const modalStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: backgroundColor ? backgroundColor : "white",
    borderRadius: "6px",
    padding: "20px",
    maxWidth: "80vw", // Adjusted for responsiveness
    width: "600px", // Fixed width or adjust as needed
    maxHeight: "80vh", // Adjusted for responsiveness
    overflowY: "auto", // Enable scrolling if content exceeds maxHeight
    boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
    zIndex: 999,
    display: isOpen ? "block" : "none", // Toggle visibility based on isOpen
  };

  return (
    <>
    {isOpen && (
        <div style={modalStyle}>
          <TextContent value={value} accept={()=>acceptCondition()} closeModal={()=>closeModal()}/>
        </div>
      )}
    </>
  );
};

export default ConditionModal;
