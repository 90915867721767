import React, { useState, useEffect, Fragment } from "react";
import Menu from "../layouts/Menu";
import Mobilemenu from "../layouts/Mobilemenu";
import { Link } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from "react-i18next";
import { Dropdown, NavLink } from "react-bootstrap";
import { useLanguage } from "../../Context/LanguageContext";

const Headerfive = () => {
    const [navtoggle, setnavtoggle] = useState(false);
    const [sticky, setSticky] = useState("false");

    const {  handleLanguageChange } = useLanguage(); 

  const { t ,i18n } = useTranslation();

    useEffect(() => {
        window.addEventListener(
            "scroll",
            () => {
                window.scrollY > 100 ? setSticky("sticky") : setSticky("");
            },
            false
        );
    },[]);

const changeLanguage = (lng) => {
        handleLanguageChange(lng);
        localStorage.setItem("language", lng);
        i18n.changeLanguage(lng);
    };
const storedLanguage = localStorage.getItem("language");

useEffect(() => {
  const storedLanguage = localStorage.getItem("language") || "English";
  handleLanguageChange(storedLanguage); 
  i18n.changeLanguage(storedLanguage); 
}, [i18n, handleLanguageChange]);


const getFlagAndName = (languageCode) => {
  switch (languageCode) {
    case "Chinese":
      return {
        flag: "china.png",
        name: "Chinese",
        width: "30px",
      };
    case "Thai":
      return {
        flag: "thailand.png",
        name: "Thai",
        width: "60px",
      };
    case "English":
    default:
      return {
        flag: "united-states.png",
        name: "English",
      };
  }
};

const { flag, name } = getFlagAndName(storedLanguage);



    return (
        <Fragment>
            <aside className={navtoggle ? "main-aside open" : "main-aside"}>
                <div className="aside-title">
                    <div className="aside-controls aside-trigger">
                        <h4>Menu</h4>
                        <div
                            className="close-btn close-dark"
                            onClick={() => setnavtoggle(false)}
                        >
                            <span />
                            <span />
                        </div>
                    </div>
                </div>
                <Mobilemenu />
            </aside>
            <div
                className="aside-overlay aside-trigger"
                onClick={() => setnavtoggle(false)}
            />
            {/* Header Start */}
            <header className={`main-header header-fw ${sticky}`}>
                {/* Top Header Start */}
                <div className="top-header">
                    <div className="top-header-inner">
                        <ul className="social-media">
                            <li>
                                {" "}
                                <Link to="#">
                                    {" "}
                                    <i className="fab fa-facebook-f" />{" "}
                                </Link>{" "}
                            </li>
                            <li>
                                {" "}
                                <Link to="#">
                                    {" "}
                                    <i className="fab fa-pinterest-p" />{" "}
                                </Link>{" "}
                            </li>
                            <li>
                                {" "}
                                <Link to="#">
                                    {" "}
                                    <i className="fab fa-linkedin-in" />{" "}
                                </Link>{" "}
                            </li>
                            <li>
                                {" "}
                                <Link to="#">
                                    {" "}
                                    <i className="fab fa-twitter" />{" "}
                                </Link>{" "}
                            </li>
                        </ul>
                        <ul className="top-header-nav">
            <li>
                <Link to="/login"> {t('login')}</Link>
            </li>
            <li>or</li>
            <li>
                <Link to="/register"> {t('signup')}</Link>
            </li>
        </ul>
                    </div>
                </div>
                {/* Top Header End */}
                <nav className="navbar">
                    {/* Menu */}
                    <Menu />
                    <div className="header-controls">
                        <ul className="header-controls-inner d-none d-lg-flex">
                            <li>
                            <Link to="/submit-listing" className="btn-custom primary">
                    {t('submitListing')} <i className="fas fa-plus" />
                </Link>
                            </li>
                        </ul>
                        {/* Toggler */}
                        <div
                            className="aside-toggler aside-trigger"
                            onClick={() => setnavtoggle(true)}
                        >
                            <span />
                            <span />
                            <span />
                        </div>
                    </div>
                    <div>
                    <Dropdown    className="  acr-language-selector ">
          <Dropdown.Toggle as={NavLink} className="dropdownicon">
        <img
          src={process.env.PUBLIC_URL + `/assets/img/flags/${flag}`}
          alt="flag"
          width={30}
        />
        <span>{name}</span>
      </Dropdown.Toggle>
              <Dropdown.Menu  className="dropdown-menu">
              <ul>
                  <li
                    onClick={() => {
                      changeLanguage("Chinese");
                    }}
                  >
                    {" "}
                    <Link to="#">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/img/flags/china.png"
                        }
                        alt="flag"
                      />{" "}
                      Chinese
                    </Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link
                      to="#"
                      onClick={() => {
                        changeLanguage("English");
                      }}
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/img/flags/united-states.png"
                        }
                        alt="flag"
                      />
                      English
                    </Link>{" "}
                  </li>
                  <li
                    onClick={() => {
                      changeLanguage("Thai");
                    }}
                  >
                    {" "}
                    <Link to="#">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/img/flags/thailand.png"
                        }
                        alt="flag"
                      />{" "}
                      Thai
                    </Link>{" "}
                  </li>
                </ul>
              </Dropdown.Menu>
            </Dropdown>
                    </div>
                </nav>
            </header>
            {/* Header End */}
        </Fragment>
    );
};

export default Headerfive;
