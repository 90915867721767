import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'

import Content from './Content'
import Headerfive from '../../layouts/Headerfive'


function Forgot() {
  return (
    <Fragment>
      <Helmet>
        <title>Buy Home For Less | Forgot</title>
        <meta name="description" content="#" />
      </Helmet>
      <Headerfive />
        <Content />
  
    </Fragment>
  )
}

export default Forgot
