import {
  Button,
  Input,
  InputGroup,
  InputRightElement,
  InputLeftAddon,
  InputLeftElement,
  InputRightAddon,
  Stack,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { Box, Typography } from "@mui/material";
import React from "react";
import "./Service.css";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

const ServiesCards = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Box className="container pt-4">
        <div className="row">
          <div className="col-md-4 col-lg-4 mb-4">
            <Box
              sx={{
                borderRadius: "25px",
                border: "0.5px solid ",
                paddingBottom: 25,
                height: "490px",
              }}
              className=" border border-2  p-3 b32"
            >
              <div className="p-2">
                <img
                  style={{
                    width: "100%",
                    height: "180px",
                    objectFit: "contain",
                  }}
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/img/listings-list/service1.jpg"
                  }
                  alt="img"
                />
              </div>
              <h4 className="py-1">{t("pick_agent")}</h4>
              <p className="py-2">{t("agent_description")}</p>
              <Link to="/sell/agent">
                <div className="but2">
                  <Button className="w-100 bg-dark text-white  mb-2 rounded-pill">
                    {t("GetStarted")}
                  </Button>
                </div>
              </Link>
            </Box>
          </div>
          <div className="col-md-4 col-lg-4 mb-4">
            <Box
              sx={{
                borderRadius: "25px",
                border: "0.5px solid ",
                paddingBottom: 25,
                height: "490px",
              }}
              className=" border border-2 p-3 b32"
            >
              <div className="p-2">
                <img
                  style={{
                    width: "100%",
                    height: "180px",
                    objectFit: "contain",
                  }}
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/img/listings-list/service2.jpg"
                  }
                  alt="img"
                />
              </div>
              <h4 className="py-1">{t("get_offers")}</h4>
              <p className="py-2">{t("offers_description")}</p>
              <div className="but2">
                <Link to="/submit-listing">
                  <Button
                    sx={{ border: "2px solid black" }}
                    className="w-100 text-black mb-2 rounded-pill"
                  >
                    {t("visit_marketplace")}
                  </Button>
                </Link>
              </div>
            </Box>
          </div>
          <div className="col-md-4 col-lg-4 mb-4">
            <Box
              sx={{
                borderRadius: "25px",
                border: "0.5px solid ",
                paddingBottom: 25,
                height: "490px",
              }}
              className="border border-2 p-3 b32"
            >
              <div className="p-2">
                <img
                  style={{
                    width: "100%",
                    height: "180px",
                    objectFit: "contain",
                  }}
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/img/listings-list/service3.jpg"
                  }
                  alt="img"
                />
              </div>
              <h4 className="py-1">{t("track_value")}</h4>
              <p id="lastpara" className="py-2 ">
                {t("track_description")}
              </p>
              <div className="but2">
                <InputGroup>
                  <InputRightElement
                    pointerEvents="none"
                    children={<SearchIcon color="gray.300" />}
                  />
                  <Input type="text" placeholder={t("search_placeholder")} />
                </InputGroup>
              </div>
            </Box>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default ServiesCards;
