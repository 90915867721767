import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";



const Blockcta = () => {
  const {t} = useTranslation();

  const ctablock = [
    {
      id: 1,
      icon: "sales-agent",
      title: t("Buying a Home?"),
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's",
    },
    {
      id: 2,
      icon: "sold",
      title: t("Selling a Home?"),
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's",
    },
    
  ];
  return (
    <div className="container">
    <div className="row">
      {ctablock?.map((item, i) => (
        <div key={i} className={`col-lg-6`}>
          <div className={`cta cta-2 item${item.id}`}>
            <i className={`flaticon-${item.icon}`} />
            <div className="cta-body">
              <h4>{item.title}</h4>
              {/* <p>{item.text}</p> */}
              {item.title == "Buying a Home?" ? (
                <Link to="/buy" className="btn-link">
                  {t("FindOutMore")} <i className="fas fa-arrow-right" />
                </Link>
              ) : (
                <Link to="/sell" className="btn-link">
                  {t("sellmore")} 
                  <i className="fas fa-arrow-right" />
                </Link>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>

  );
};

export default Blockcta;
