import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Element } from "react-scroll";
import {
  Dropdown,
  NavLink,
  Accordion,

  // Card,
} from "react-bootstrap";
import Modal from "react-modal";
import Calculator from "../../layouts/Calculator";
import Slider from "react-slick";
import $ from "jquery";
import "magnific-popup";
import axios from "axios";
import video1 from "../../../../src/assets/video/demo.mp4";
import { useTranslation } from "react-i18next";
import BASE_URL from "../../../baseUrl";
import { Image } from "antd";
import toast, { Toaster } from "react-hot-toast";
import bbqarea from "../../../assets/img/bbqarea.png";
import bathoom from "../../../assets/img/bathoom.png";
import Jacuzzi from "../../../assets/img/Jacuzzi.png";
import Sauna from "../../../assets/img/Sauna.png";
import bedroom from "../../../assets/img/bedroom.png";
import childrenplayground from "../../../assets/img/childrenplayground.jpg";
import communityhall from "../../../assets/img/communityhall.png";
import gameroom from "../../../assets/img/gameroom.png";
import guestparking from "../../../assets/img/guestparking.png";
import guestroom from "../../../assets/img/guestroom.png";
import gym from "../../../assets/img/gym.png";
import laundry from "../../../assets/img/laundry.png";
import library from "../../../assets/img/library.png";
import maidroom from "../../../assets/img/maidroom.png";
import meetingroom from "../../../assets/img/meetingroom.png";
import minimart from "../../../assets/img/minimart.jpg";
import parking from "../../../assets/img/parking.png";
import partyhall from "../../../assets/img/partyhall.png";
import swimingpool from "../../../assets/img/swimingpool.png";
import tennis from "../../../assets/img/tennis.png";
import diningroom from "../../../assets/img/diningroom.png";
import livingroom from "../../../assets/img/livingroom.png";
import ocean from "../../../assets/img/ocean.png";
import elevator from "../../../assets/img/elevator.png";
import fans from "../../../assets/img/air.png";
import solarPower from "../../../assets/img/solar.png";
import seaview from "../../../assets/img/seaview.png";
import hotwater from "../../../assets/img/hotwater.png";
import transport from "../../../assets/img/transport.png";
import penthouse from "../../../assets/img/penthouse.png";
import Electricity from "../../../assets/img/electricity.png";
import { MdOutlineContentCopy } from "react-icons/md";
import { MdOutlineAttachMoney } from "react-icons/md";
import maintainanceFeeicon from "../../../assets/img/maintanencefee.jpg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const Listingwrapper = ({ setSeo }) => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    Location: "location",
    BasicInformation: "BasicInformation",
    Details: "Details",
    Features: "Features",
    Gallery: "Gallery",
  });

  // const { t } = useTranslation();

  const [featureList, setFeatureList] = useState([]);
  const [Maplink, setMaplink] = useState();
  const [num, setNum] = useState(false);
  const [listing, setListing] = useState([]);
  const { t } = useTranslation();
  const [showmore, setShowMore] = useState(false);
  const [latitude, setlatitude] = useState(false);
  const [longitude, setlongitude] = useState(false);
  const [map, setmap] = useState(false);
  const [loader, setLoader] = useState(false);
  const [video, setvideo] = useState();
  const [scheduleLink, setScheduleLink] = useState({});
  const [Agentinfo, setAgentinfo] = useState(false);
  const [Agentcall, setAgentcall] = useState(false);
  // const [PriceRange, setPriceRange] = useState();
  const { id } = useParams();

  const [featureListData, setFeatureListData] = useState(() => {
    const initialData = [
      { name: t("BBQ Area"), value: "BBQArea", image: bbqarea },
      // { name: t("Community Hall"), value: "CommunityHall", image: communityhall },
      // { name: t("Swimming Pool"), value: "SwimmingPool", image: swimingpool },
      // { name: t("Gym"), value: "Gym", image: gym },
      { name: t("Mini Mart"), value: "MiniMart", image: minimart },
      { name: t("Library"), value: "Library", image: library },
      { name: t("FansAirCondition"), value: "Fans", image: fans },
      { name: t("Solar Power"), value: "solarPower", image: solarPower },
      { name: t("Sea View"), value: "seaview", image: seaview },
      { name: t("Elevator"), value: "outdoorBath", image: elevator },
      // { name: t("Play Ground"), value: "playGround", image: childrenplayground },
      { name: t("Tennis Court"), value: "tenniscourt", image: tennis },
      // { name: t("Jacuzzi"), value: "jacuzzi", image: Jacuzzi },
      // { name: t("Sauna"), value: "sauna", image: Sauna },
      { name: t("Party Hall"), value: "PartyHall", image: partyhall },
      // { name: t("Guest Parking"), value: "GuestParking", image: guestparking },
      { name: t("Pent House"), value: "penthouse", image: penthouse },
      { name: t("Laundry Room"), value: "laundryRoom", image: laundry },
      { name: t("Meeting Room"), value: "meetingRoom", image: meetingroom },
      { name: t("Game Room"), value: "gameRoom", image: gameroom },
      { name: t("Maid Room"), value: "maidRoom", image: maidroom },
      { name: t("Guest Room"), value: "guestRoom", image: guestroom },
      { name: t("Parking"), value: "parking", image: parking },
      { name: t("Beds"), value: "beds", image: bedroom },
      { name: t("Bathrooms"), value: "bathrooms", image: bathoom },
      { name: t("Dining Room"), value: "dining", image: diningroom },
      { name: t("Living Room"), value: "living", image: livingroom },
    ];
  
    // Filter out null values before setting the state
    return initialData.filter(Boolean);
  });
  
  // if(state?.BasicInformation?.listingType == "Rent"){
  //   featureListData.push(
  //     { name: t("electrcity"), value: "oceanFront", image: Electricity },
  //     {
  //       name: t("paidwater"),
  //       value: "hotWater",
  //       image: hotwater,
  //     },
  //   )
  // }
  const [Test, setTest] = useState(true); // Initialize the Test state to true

  // Function to check if the URL is a Google Maps URL
  const isGoogleMapsURL = (url) => {
    const googleMapsPattern =
      /^https:\/\/(www\.)?google\.(com|co\.\w{2})\/maps/;
    const isValid = googleMapsPattern.test(url);
    setTest(isValid); // Update Test state
    return isValid;
  };
  useEffect(() => {
    if (Maplink) {
      isGoogleMapsURL(Maplink);
    }
  }, [Maplink]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/submitlisting/submit/${id}`)
      .then((res) => {
        // console.log(res);
        // setPriceRange(res?.data?.BasicInformation?.)
        setMaplink(res?.data?.result?.Location?.embedCode);
        setlatitude(res.data.result.Location.latitude);
        setlongitude(res.data.result.Location.longitude);
        setvideo(res?.data?.result?.BasicInformation?.video);
        setmap(true);
        setSeo(res.data.result.SEO);
      })
      .catch((err) => {});
  }, [id]);
  // Split the link to extract the video identifier
  const videoId = state?.BasicInformation?.video
    ? state?.BasicInformation?.video.split("?")[0].split("/").pop()
    : "";

  const getData = async () => {
    const response = await fetch(`${BASE_URL}/submitlisting/get-properties`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        mode: "no-cors",
      },
    });
    const data = await response.json();
    const find = data.result.find((res) => res._id === id);
    setState(find);
    const features = await fetch(`${BASE_URL}/admin/get-features`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        mode: "no-cors",
      },
    });
    const featureList = await features.json();
    setFeatureList(featureList.result);
    setLoader(true);
  };
  function getYoutubeVideoId(url) {
    // Extract the video ID from the YouTube URL
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : ""; // Return the video ID
  }

  const copyToClipboard = () => {
    const telNumber = state?.Agent?.agentTel || ""; // Fallback to an empty string if undefined
    navigator.clipboard
      .writeText(telNumber)
      .then(() => {
        alert("Telephone number copied to clipboard!"); // Optional: Alert to indicate the text was copied
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "30px",
      width: "400px",
      transform: "translate(-50%, -50%)",
    },
  };

  let subtitle;
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  useEffect(() => {
    getData();
    axios.get(`${BASE_URL}/submitlisting/lastsubmit`).then((res) => {
      // console.log(res?.data);
      setListing(res.data.result);
    });
    let userData;
    userData = localStorage.getItem("userInfo");
    userData = JSON.parse(userData);
    // console.log("object",userData?._id)
    setScheduleLink({ ...scheduleLink, property: id, user: userData?._id });
  }, []);

  function popup() {
    var items = state?.Gallery?.picture?.map((name) => {
      return {
        src: `${BASE_URL}/${name}`,
      };
    });

    $(".gallery-thumb").magnificPopup({
      type: "image",
      gallery: {
        enabled: true,
      },
      tCounter: '<span class="mfp-counter">%curr% of %total%</span>', // markup of counter
      items: items,
    });
  }
  if (!loader) return;
  const myArray = state?.Features?.map((item) => {
    return item?._id;
  });

  const ScheduleSave = (e) => {
    e.preventDefault(); // Prevent form submission

    // Destructure the fields from scheduleLink
    const { fullname, email, phonenumber, date, comment } = scheduleLink;

    // Validate required fields
    if (!fullname || !email || !phonenumber || !date || !comment) {
      toast.error("Please fill all required fields", {
        position: "top-right",
      });
      return; // Stop form submission if fields are empty
    }

    // If validation passes, make the API call
    axios
      .post(`${BASE_URL}/scheduleLinkTour`, scheduleLink)
      .then((res) => {
        toast.success("Your schedule is done", {
          position: "top-right",
        });
        // Reset the form fields
        setScheduleLink({
          fullname: "",
          email: "",
          phonenumber: "",
          date: "",
          comment: "",
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!", {
          position: "top-right",
        });
      });
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    nextArrow: false,
    prevArrow: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 1500,
          infinite: true,
          dots: false,
          nextArrow: false,
          prevArrow: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 1500,
          nextArrow: false,
          prevArrow: false,
        },
      },
    ],
  };

  const FloorImagesettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    nextArrow: false,
    prevArrow: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 1500,
          infinite: true,
          dots: false,
          nextArrow: false,
          prevArrow: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 1500,
          nextArrow: false,
          prevArrow: false,
        },
      },
    ],
  };
  const formatContactId = (id) => {
    // Agar id null ya undefined hai, toh empty string return karo
    if (!id) return "";

    // Yeh function check karta hai ke id sirf numbers par mushtamil hai
    const isNumeric = /^\d+$/.test(id);

    // Agar id sirf numbers par mushtamil hai
    if (isNumeric) {
      return id.length > 10 ? "+" + id.slice(0, 10) + "..." : "+" + id;
    }

    // Agar id mein numbers ke ilawa kuch aur bhi ho, toh id bina "+" ke return kare
    return id;
  };
// console.log(state.Details.jacuzzi ,"jacuzii");
  return (
    <div className="section listing-wrapper" style={{ marginBottom: "10%" }}>
      <Toaster />
      <div className="container">
        <div className="row">
          {/* Listings Start */}
          <div className="col-lg-8 h-fit">
            {/* Content Start */}
            <div className="listing-content">
              <h4>{t("PropertyDescription")}</h4>
              <div className="description-container">
                <p>{state?.BasicInformation?.description}</p>
              </div>
              {/* Gallery Images */}
              {/* <div className="row">
                {state?.Gallery?.picture &&
                  state?.Gallery?.picture.map((item, i) => (
                    <div key={i} className="col-md-6 mb-3">
                      <button
                        // onClick={() => popup()}
                        className="gallery-thumb"
                        style={{ border: "none", background: "none" }}
                      >
                        <Image
                          width={200}
                          height={200}
                          src={`${item}`}
                          style={{ width: "300px", height: "200px" }}
                        /> */}
              {/* <img
                          src={`${item}`}
                          alt="post"
                          style={{ width: "300px", height: "200px" }}
                        /> */}
              {/* </button>
                    </div>
                  ))}
              </div> */}
              {state?.Gallery?.picture && state.Gallery.picture.length > 0 ? (
                state.Gallery.picture.length > 3 ? (
                  <div
                    className="row"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: "20px",
                      height: "260px",
                    }}
                  >
                    <Slider {...settings}>
                      {state.Gallery.picture.map((item, i) => (
                        <div key={i} className="col-md-4 mb-3">
                          <button
                            className="gallery-thumb"
                            style={{ border: "none", background: "none" }}
                          >
                            <Image
                              width={200}
                              height={200}
                              src={`${item}`}
                              style={{ width: "300px", height: "200px" }}
                            />
                          </button>
                        </div>
                      ))}
                    </Slider>
                  </div>
                ) : (
                  <div className="row">
                    {state.Gallery.picture.map((item, i) => (
                      <div key={i} className="col-md-4 mb-3">
                        <button
                          className="gallery-thumb"
                          style={{ border: "none", background: "none" }}
                        >
                          <Image
                            width={200}
                            height={200}
                            src={`${item}`}
                            style={{ width: "300px", height: "200px" }}
                          />
                        </button>
                      </div>
                    ))}
                  </div>
                )
              ) : (
                <p className="text-center">{t("No Property Images")}</p>
              )}

              {/* Floor Images */}
              {/* <div className="row">
                <h4>{t("Floor Images")}</h4>
                {state?.Floorimage && state.Floorimage.length > 0 ? (
                  state.Floorimage.map((item, i) => (
                    <div key={i} className="col-md-6 mb-3">
                      <button
                        // onClick={() => popup()}
                        className="gallery-thumb"
                        style={{ border: "none", background: "none" }}
                      >
                        <Image
                          width={200}
                          height={200}
                          src={`${item}`}
                          style={{ width: "300px", height: "200px" }}
                        /> */}
              {/* <img
                            src={`${item}`}
                            alt="post"
                            style={{ width: "300px", height: "200px" }}
                          /> */}
              {/* </button>
                    </div>
                  ))
                ) : (
                  <p className="text-center">{t("No floor images")}</p>
                )}
              </div> */}
              <div>
                {state?.Floorimage ? <h4>{t("FloorImages")}</h4> : ""}
                {state?.Floorimage && state.Floorimage.length > 0 ? (
                  state.Floorimage.length > 3 ? (
                    <div
                      className="row"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "20px",
                        height: "260px",
                      }}
                    >
                      <Slider {...FloorImagesettings}>
                        {state.Floorimage.map((item, i) => (
                          <div key={i} className="col-md-4 mb-3">
                            <button
                              className="gallery-thumb"
                              style={{ border: "none", background: "none" }}
                            >
                              <Image
                                width={200}
                                height={200}
                                src={`${item}`}
                                style={{ width: "300px", height: "200px" }}
                              />
                            </button>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  ) : (
                    <div className="row">
                      {state.Floorimage.map((item, i) => (
                        <div key={i} className="col-md-4 mb-3">
                          <button
                            className="gallery-thumb"
                            style={{ border: "none", background: "none" }}
                          >
                            <Image
                              width={200}
                              height={200}
                              src={`${item}`}
                              style={{ width: "300px", height: "200px" }}
                            />
                          </button>
                        </div>
                      ))}
                    </div>
                  )
                ) : (
                  <p className="text-center">{t("No floor images")}</p>
                )}
              </div>
            </div>
            <div style={{ height: "8%", padding: "5% 0", textAlign: "center" }}>
              <h4 style={{ marginBottom: "2%" }}>{t("Direction")}</h4>
              {Map && Test ? (
                <iframe
                  width="100%"
                  height="95%"
                  title="direction"
                  src={`${
                    Maplink
                      ? Maplink
                      : `https://maps.google.com/maps?q=${latitude},${longitude}&hl=es;z=14&output=embed`
                  }`}
                ></iframe>
              ) : (
                <p>Invalid URL: Only Google Maps URLs are allowed.</p>
              )}
              {/* <iframe
                width="100%"
                height="95%"
                title="direction"
                src={`${
                  Maplink
                    ? Maplink
                    : `https://maps.google.com/maps?q=${latitude},${longitude}&hl=es;z=14&output=embed`
                }`}
                // frameborder="0" // Change from frameborder to frameBorder
              ></iframe> */}
            </div>
            {/* Content End */}
            {/* {t("Price Range In the area Start")} */}

            <div className="section">
              <div className="acr-area-price">
                <span style={{ left: "30%" }}>
                  {state.BasicInformation.listingType === "Rent" ? (
                    <>
                      {state?.BasicInformation?.currency}
                      {state?.BasicInformation?.rentalPrice}
                    </>
                  ) : (
                    <>
                      {state?.BasicInformation?.currency}
                      {state?.BasicInformation?.price}
                    </>
                  )}
                </span>
                {/* {console.log("object",state?.BasicInformation)} */}
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "50%" }}
                    aria-valuenow={50}
                    aria-valuemin={
                      state?.BasicInformation?.minRange
                        ? state?.BasicInformation?.minRangecurrency ||
                          "$" + state?.BasicInformation?.minRange
                        : "100"
                    }
                    aria-valuemax={
                      state?.BasicInformation?.maxRange
                        ? state?.BasicInformation?.maxRangecurrency ||
                          "$" + state?.BasicInformation?.maxRange
                        : "500"
                    }
                  />
                </div>
                <div className="acr-area-price-wrapper">
                  <div className="acr-area-price-min">
                    <h5>
                      {state?.BasicInformation?.minRangecurrency || "$"}
                      {state?.BasicInformation?.minRange
                        ? state?.BasicInformation?.minRange
                        : "$100"}
                    </h5>
                    <span>{t("LOWEST")}</span>
                  </div>
                  <h5>{t("Price Range In the area Start")}</h5>
                  <div className="acr-area-price-max">
                    <h5>
                      {state?.BasicInformation?.maxRangecurrency || "$"}
                      {state?.BasicInformation?.maxRange
                        ? state?.BasicInformation?.maxRange
                        : "$500"}
                    </h5>
                    <span>{t("HIGHEST")}</span>
                  </div>
                </div>
              </div>
            </div>
            {/* Price Range In the area End */}
            <div className="section section-padding pt-0 acr-listing-features">
              <h4>{t("Property Amenities")}</h4>
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="listing-feature-wrapper">
                    <div className="listing-feature">
                      <i className="flaticon-key" />
                      <h6 className="listing-feature-label">
                        {t("propertyId")}
                      </h6>
                      <span className="listing-feature-value">
                        {state?.Details?.id == "0"
                          ? "--"
                          : state?.Details?.id
                          ? state?.Details?.id
                          : "---"}
                      </span>
                    </div>
                    <div className="listing-feature">
                      <i className="flaticon-pillow" />
                      <h6 className="listing-feature-label">{t("Gym")}</h6>
                      <span className="listing-feature-value">
                        {state.PropertyAmenity.Gym == "true"
                          ? "Yes"
                          : state.PropertyAmenity.Gym == "false"
                          ? "No"
                          : "--"}
                      </span>
                    </div>
                    <div className="listing-feature">
                      <i className="flaticon-garage" />
                      <h6 className="listing-feature-label">
                        {t("Community Hall")}
                      </h6>
                      <span className="listing-feature-value">
                        {state.PropertyAmenity.CommunityHall == "true"
                          ? "Yes"
                          : state.PropertyAmenity.CommunityHall == "false"
                          ? "No"
                          : "--"}
                      </span>
                    </div>
                    <div className="listing-feature">
                      <i className="flaticon-ruler" />
                      <h6 className="listing-feature-label">{t("Jacuzzi")}</h6>
                      <span className="listing-feature-value">

                        {state.Details.jacuzzi == "true"
                          ? "Yes"
                          : state.Details.jacuzzi == "false"
                          ? "No"
                          : "--"}
                      </span>
                    </div>
                    {/* <div className="listing-feature">
                      <i className="flaticon-garage" />
                      <h6 className="listing-feature-label">{t('parking')}</h6>
                      <span className="listing-feature-value">{state?.Details?.parking}</span>
                    </div> */}
                    <div className="listing-feature">
                      <i className="flaticon-history" />
                      <h6 className="listing-feature-label">
                        {t("Guest Parking")}
                      </h6>
                      <span className="listing-feature-value">
                        {state.PropertyAmenity.GuestParking == "true"
                          ? "Yes"
                          : state.PropertyAmenity.GuestParking == "false"
                          ? "No"
                          : "--"}
                      </span>
                    </div>
                    <div className="listing-feature">
                      <img
                        src={maintainanceFeeicon}
                        className="h-[50px] w-[50px] me-2"
                      />
                      <h6 className="listing-feature-label">
                        Maintainance Fee
                      </h6>
                      <span className="listing-feature-value">
                        {state?.BasicInformation?.maintainanceFeecurrency ||
                          "$"}
                        {state?.BasicInformation?.maintainanceFee || "--"}
                      </span>
                    </div>
                    {
                        state?.BasicInformation?.listingType == "Rent" && (
                          <div className="listing-feature">
                            <div className="flex">
                            <img src={hotwater} alt="" className="me-3" style={{width:"50px",height:"50px"}}/>
                          <h6 className="listing-feature-label mb-1 mt-3">{t("paidwater")}</h6>
                            </div>
                          <span className="listing-feature-value">
                            {state.PropertyAmenity.hotWater == "true"
                              ? "Yes"
                              : state.PropertyAmenity.hotWater == "false"
                              ? "No"
                              : "--"}
                          </span>
                        </div>
                        )
                      }
                      
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="listing-feature-wrapper">
                    <div className="listing-feature">
                      <i className="flaticon-picture" />
                      <h6 className="listing-feature-label">
                        {t("propertyType")}
                      </h6>
                      <span
                        className="listing-feature-value"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {state?.BasicInformation?.type}
                      </span>
                    </div>
                    <div className="listing-feature">
                      <i className="flaticon-bathroom" />
                      <h6 className="listing-feature-label">
                        {t("Swimming Pool")}
                      </h6>
                      <span className="listing-feature-value">
                        {state.PropertyAmenity.SwimmingPool == "true"
                          ? "Yes"
                          : state.PropertyAmenity.SwimmingPool == "false"
                          ? "No"
                          : "--"}
                      </span>
                    </div>
                    <div className="listing-feature">
                      <i className="flaticon-ruler" />
                      <h6 className="listing-feature-label">{t("Sauna")}</h6>
                      <span className="listing-feature-value">
                        {state.Details.sauna == "true"
                          ? "Yes"
                          : state.Details.sauna == "false"
                          ? "No"
                          : "--"}
                      </span>
                    </div>
                    <div className="listing-feature">
                      <i className="flaticon-picture" />
                      <h6 className="listing-feature-label">
                        {t("Children Playground")}
                      </h6>
                      <span className="listing-feature-value">
                        {state.PropertyAmenity.playGround == "true"
                          ? "Yes"
                          : state.PropertyAmenity.playGround == "false"
                          ? "No"
                          : "--"}
                      </span>
                    </div>
                    <div className="listing-feature">
                      <i className="flaticon-picture" />
                      <h6 className="listing-feature-label">{t("Parks")}</h6>
                      <span className="listing-feature-value">
                        {state.PropertyAmenity.Park == "true"
                          ? "Yes"
                          : state.PropertyAmenity.Park == "false"
                          ? "No"
                          : "--"}
                      </span>
                    </div>
                      {
                        state?.BasicInformation?.listingType == "Rent" && (
                          <div className="listing-feature">
                            <div className="flex">
                            <img src={Electricity} alt="" className="me-3" style={{width:"50px",height:"50px"}}/>
                          <h6 className="listing-feature-label mb-1 mt-3">{t("electrcity")}</h6>
                            </div>
                          <span className="listing-feature-value">
                            {state.PropertyAmenity.oceanFront == "true"
                              ? "Yes"
                              : state.PropertyAmenity.oceanFront == "false"
                              ? "No"
                              : "--"}
                          </span>
                        </div>
                        )
                      }
                    <div className="listing-feature">
                      <i className="flaticon-new" />
                      <h6 className="listing-feature-label">
                        {t("Condition")}
                      </h6>
                      <span className="listing-feature-value">
                        {state?.Details?.condition
                          ? state?.Details?.condition
                          : "---"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={showmore ? "d-block" : `hidden-listing-features`}>
                <h3 className="text-center">{t("Features")}</h3>
                <div className="row border_top">
                  <div className="col-lg-6 col-md-6">
                    {/* {featureList
                      .slice(0, featureList.length / 2 + 1)
                      .map((item, key) => (
                        <div key={key} className="listing-feature">
                          <i>
                            <img
                              src={`${item?.icon}`}
                            />
                          </i>  <h6 className="listing-feature-label newClass">{item?.name}</h6>
                          {myArray?.includes(item?._id) ? "yes" : "no"}
                        </div>
                      ))} */}

                    {featureListData.slice(0, 10).map((item, key) => (
                      <div key={key} className="listing-feature">
                        <i>
                          <img
                            // src="https://real-estate-react.nyc3.digitaloceanspaces.com/parking.png"
                            src={
                              item.image
                                ? item.image
                                : "https://real-estate-react.nyc3.digitaloceanspaces.com/parking.png"
                            }
                            alt="a"
                          />
                        </i>{" "}
                        <h6 className="listing-feature-label newClass">
                          {t(item.name)}
                        </h6>
                        {state?.PropertyAmenity[item.value] == "true"
                          ? "yes"
                          : state?.PropertyAmenity[item.value] == "false"
                          ? "no"
                          : "---"}
                      </div>
                    ))}
                  </div>
                  <div className="col-lg-6 col-md-6">
                    {/* {featureList
                      .slice(featureList.length / 2 + 1)
                      .map((item, key) => (
                        <div key={key} className="listing-feature">
                          <img style={{ width: '50px', height: '50px', }} src={item?.icon} />
                          <h6 style={{ marginLeft: '20px' }} className="listing-feature-label">{item?.name}</h6>
                          {myArray?.includes(item?._id) ? "yes" : "no"}
                        </div>
                      ))} */}

                    {featureListData.slice(10).map((item, key) => (
                      <div key={key} className="listing-feature">
                        {item.image ? (
                          <img
                            style={{ width: "50px", height: "50px" }}
                            src={item.image}
                          />
                        ) : (
                          ""
                        )}
                        <h6
                          style={{ marginLeft: "20px" }}
                          className="listing-feature-label"
                        >
                          {t(item.name)}
                        </h6>
                        {/* {Object.keys(state?.PropertyAmenity).includes(
                            item?.name
                          )
                            ? "yes"
                            : "no"} */}
                        {/* {console.log("first", state?.Details[item.value])} */}

                        {/* {state?.Details[item.value] == "true" 
                          ? "Yes"
                          : state?.Details[item.value] == "false" 
                          ? "No":state?.PropertyAmenity[item.value] == "true" ? "yes"
                          ? "No":state?.PropertyAmenity[item.value] == "false" ? "No"
                          : state?.Details[item.value]
                          ? state?.Details[item.value]
                          : "--"}
                           */}

                        {state?.Details[item.value] === "true"
                          ? "Yes"
                          : state?.Details[item.value] === "false"
                          ? "No"
                          : state?.PropertyAmenity[item.value] === "true"
                          ? "Yes"
                          : state?.PropertyAmenity[item.value] === "false"
                          ? "No"
                          : state?.Details[item.value] || "--"}

                        {/* {state?.Details[item.value] == "true"
                          ? "Yes"
                          : state?.Details[item.value] == "false"
                          ? "No":state?.PropertyAmenity[item.value] == "true" ? "yes" : "no"
                          : state?.Details[item.value]
                          ? state?.Details[item.value]
                          : "--"} */}
                        {/* {
  state?.Details[item.value] == "true" ? "Yes" :
  state?.Details[item.value] == "false" ? "No" :
  state?.PropertyAmenity[item.value] == "true" ? "Yes": 
  state?.PropertyAmenity[item.value] == "false" ? "No":
  state?.Details[item.value] ? state?.Details[item.value] : "--"
} */}
                      </div>
                      // {state?.PropertyAmenity[item.value] === true ? "yes" : "no"}
                    ))}
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="load-more-features btn-custom-2 light-grey btn-block"
                onClick={() => setShowMore(!showmore)}
              >
                {showmore ? t("Few") : t("More")}
              </button>
            </div>
            {
              state?.Details?.near?.length > 0 ? (
                // <div className="section pt-0 acr-listing-nearby">
                //   <h4>{t("What's Nearby")}</h4>
                //   <div className="flex justify-between px-5">
                //     <div className=" font-bold">
                //       <h6>Type</h6>
                //     </div>
                //     <div className=" font-bold">
                //       <h6>Location</h6>
                //     </div>
                //     <div className=" font-bold">
                //       <h6>Distance</h6>
                //     </div>
                //   </div>
                //   {state?.Details?.near &&
                //     state?.Details?.near.map((item, key) => (
                //       <div
                //         className="listing-nearby-item flex justify-between px-5"
                //         key={key}
                //       >
                //         <p className="listing-feature-label m-0 font-semibold">
                //           {item?.neartype?.name}
                //         </p>
                //         <p className="listing-feature-label m-0 font-semibold">
                //           {item.name.length > 10
                //             ? item.name.slice(0, 10) + "...."
                //             : item.name}
                //         </p>
                //         <span className="listing-feature-value font-semibold">
                //           ({item?.distance} km)
                //         </span>
                //       </div>
                //     ))}
                // </div>
                <div className="section pt-0 acr-listing-nearby container w-[90%] mx-auto">
                  <h4>{t("What's Nearby")}</h4>
                  <div className="listing-nearby-header  sm:text-2xl">
                    <div className="listing-nearby-column">Type</div>
                    <div className="listing-nearby-column">Location</div>
                    <div className="listing-nearby-column">Distance</div>
                  </div>

                  {state?.Details?.near &&
                    state.Details.near.map((item, key) => (
                      <div className="listing-nearby-item" key={key}>
                        <div className="listing-nearby-content">
                          <div className="listing-nearby-column">
                            <div className="flex">
                              <img
                                src={`${
                                  item?.neartype?.name === "Top School"
                                    ? `${process.env.PUBLIC_URL}/assets/img/school.png`
                                    : item?.neartype?.name === "University"
                                    ? `${process.env.PUBLIC_URL}/assets/img/university.png`
                                    : item?.neartype?.name === "Hospital"
                                    ? `${process.env.PUBLIC_URL}/assets/img/HOSPITAL__ICON.png`
                                    : item?.neartype?.name === "Shopping Mall"
                                    ? `${process.env.PUBLIC_URL}/assets/img/shopping.png`
                                    : item?.neartype?.name === "Transporation"
                                    ? transport
                                    : // ?`${process.env.PUBLIC_URL}/assets/img/DOWNTOWN_ICON.png`
                                    item?.neartype?.name === "Down Town"
                                    ? `${process.env.PUBLIC_URL}/assets/img/DOWNTOWN_ICON.png`
                                    : item?.neartype?.name === "Airport"
                                    ? `${process.env.PUBLIC_URL}/assets/img/Airport.png`
                                    : ""
                                }`}
                                alt="TypeIcon"
                                className="h-6 w-6"
                              />
                              <div style={{ color: `${item.neartype.color}` }}>
                                {item.neartype.name}
                              </div>
                            </div>
                          </div>
                          <div className="listing-nearby-column">
                            {item.name.length > 10
                              ? item.name.slice(0, 10) + "...."
                              : item.name}
                          </div>
                          <div className="listing-nearby-column">
                            ({item.distance} km)
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              ) : null

              // <div className="section pt-0 acr-listing-nearby">
              //   <h4>{t("What's Nearby")}</h4>
              //   <div className="row">
              //     <div className="col-lg-6 col-md-6">
              //       <div className="listing-feature-wrapper">
              //         <div className="listing-feature ">
              //           <FaSchool size={35} color="#01bbbc" />
              //           <h6 className="listing-feature-label p-0 m-0">{t('ischool')}</h6>
              //           <span className="listing-feature-value"></span>
              //         </div>
              //       </div>
              //     </div>
              //     <div className="col-lg-6 col-md-6">
              //       <div className="listing-feature-wrapper">
              //         <div className="listing-feature">
              //           <h6 className="listing-feature-label">3 Km </h6>
              //         </div>
              //       </div>
              //     </div>
              //     <div className="col-lg-6 col-md-6">
              //       <div className="listing-feature-wrapper">
              //         <div className="listing-feature ">
              //           <FaUniversity size={35} color="#01bbbc" />
              //           <h6 className="listing-feature-label p-0 m-0">{t('University')}</h6>
              //           <span className="listing-feature-value"></span>
              //         </div>
              //       </div>
              //     </div>
              //     <div className="col-lg-6 col-md-6">
              //       <div className="listing-feature-wrapper">
              //         <div className="listing-feature">
              //           <h6 className="listing-feature-label">3 Km </h6>
              //         </div>
              //       </div>
              //     </div>
              //     <div className="col-lg-6 col-md-6">
              //       <div className="listing-feature-wrapper">
              //         <div className="listing-feature ">
              //           <FaShoppingBag size={35} color="#01bbbc" />
              //           <h6 className="listing-feature-label p-0 m-0">{t('Shopping Center')}</h6>
              //           <span className="listing-feature-value"></span>
              //         </div>
              //       </div>
              //     </div>
              //     <div className="col-lg-6 col-md-6">
              //       <div className="listing-feature-wrapper">
              //         <div className="listing-feature">
              //           <h6 className="listing-feature-label">3 Km </h6>
              //         </div>
              //       </div>
              //     </div>
              //     <div className="col-lg-6 col-md-6">
              //       <div className="listing-feature-wrapper">
              //         <div className="listing-feature ">
              //           <FaHospitalAlt size={35} color="#01bbbc" />
              //           <h6 className="listing-feature-label p-0 m-0">{t('Hospital')}</h6>
              //           <span className="listing-feature-value"></span>
              //         </div>
              //       </div>
              //     </div>
              //     <div className="col-lg-6 col-md-6">
              //       <div className="listing-feature-wrapper">
              //         <div className="listing-feature">
              //           <h6 className="listing-feature-label">5 Km </h6>
              //         </div>
              //       </div>
              //     </div>
              //     <div className="col-lg-6 col-md-6">
              //       <div className="listing-feature-wrapper">
              //         <div className="listing-feature ">
              //           <CiShop size={35} color="#01bbbc" />
              //           <h6 className="listing-feature-label p-0 m-0">{t('MRT')}</h6>
              //           <span className="listing-feature-value"></span>
              //         </div>
              //       </div>
              //     </div>
              //     <div className="col-lg-6 col-md-6">
              //       <div className="listing-feature-wrapper">
              //         <div className="listing-feature">
              //           <h6 className="listing-feature-label">5 Km </h6>
              //         </div>
              //       </div>
              //     </div>
              //     <div className="col-lg-6 col-md-6">
              //       <div className="listing-feature-wrapper">
              //         <div className="listing-feature ">
              //           <MdOutlineConnectingAirports size={35} color="#01bbbc" />
              //           <h6 className="listing-feature-label p-0 m-0">{t('Airport')}</h6>
              //           <span className="listing-feature-value"></span>
              //         </div>
              //       </div>
              //     </div>
              //     <div className="col-lg-6 col-md-6">
              //       <div className="listing-feature-wrapper">
              //         <div className="listing-feature">
              //           <h6 className="listing-feature-label">5 Km </h6>
              //         </div>
              //       </div>
              //     </div>
              //     <div className="col-lg-6 col-md-6">
              //       <div className="listing-feature-wrapper">
              //         <div className="listing-feature ">
              //           <GrAttraction size={35} color="#01bbbc" />
              //           <h6 className="listing-feature-label p-0 m-0">{t('Attraction')}</h6>
              //           <span className="listing-feature-value"></span>
              //         </div>
              //       </div>
              //     </div>
              //     <div className="col-lg-6 col-md-6">
              //       <div className="listing-feature-wrapper">
              //         <div className="listing-feature">
              //           <h6 className="listing-feature-label">5 Km </h6>
              //         </div>
              //       </div>
              //     </div>
              //   </div>
              // </div>
            }
            <div className="section pt-0">
              {state?.BasicInformation?.video ? (
                <h4>{t("Property Video")}</h4>
              ) : (
                ""
              )}
              <div className="videodiv mb-3 container mx-auto w-[90%]">
                <div className="w-full " style={{height:"350px"}}>
                  {state?.BasicInformation?.video.slice(0, 5) === "https" ? (
                    <iframe
                      className="w-full h-full"
                      src={`https://www.youtube.com/embed/${getYoutubeVideoId(
                        state?.BasicInformation?.video
                      )}`}
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    ></iframe>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="videodiv mb-3 container w-[90%] mx-auto">
                {state.BasicInformation.videofile ? (
                  <div
                  className="w-full h-[350px]"
                  style={{height:"350px"}}
                    autoPlay
                  >
                    <video controls className="w-full h-full">
                      <source
                        src={
                          state.BasicInformation.videofile?.includes("uploads/")
                            ? `${BASE_URL}/${state.BasicInformation.videofile}`
                            : state.BasicInformation.videofile
                        }
                        type="video/mp4"
                      />
                    </video>
                  </div>
                ) : null}
              </div>
              {/* <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap
              </p> */}

              {/* {state?.BasicInformation?.video &&
              state?.BasicInformation?.video.slice(0, 5) === "https" ? (
                <div className="embed-responsive embed-responsive-21by9">
                  <iframe
                    title="video"
                    className="embed-responsive-item"
                    src={state.BasicInformation.video}
                  />
                </div>
              ) : state.BasicInformation.video ? (
                <div className="embed-responsive embed-responsive-21by9">
                  <iframe
                    title="video"
                    className="embed-responsive-item"
                    src={`https://${state?.BasicInformation?.video}`}
                  />
                </div>
              ) : (
                <div className="embed-responsive embed-responsive-21by9">
                  <h5>No Property Video Found</h5>
                </div>
              )} */}
            </div>
            {/* <div className="section pt-0 acr-listing-history">
              <h4>{t("Property History")}</h4>
              <Accordion defaultActiveKey="0" className="with-gap">
                <Accordion.Item eventKey="1">
                  <Accordion.Header>1979 - 1999</Accordion.Header>
                  <Accordion.Body className="collapseparent">
                    <div className="row">
                      <div className="col-sm-4">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/img/listing-single/history-1.jpg"
                          }
                          alt="property history"
                        />
                      </div>
                      <div className="col-sm-8">
                        <h5>The Beginning</h5>
                        Anim pariatur cliche reprehenderit, enim eiusmod high
                        life accusamus terry richardson ad squid. 3 wolf moon
                        officia aute, non cupidatat skateboard dolor brunch.
                        Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                        wolf moon tempor, sunt aliqua put a bird on it squid
                        single-origin coffee nulla assumenda shoreditch et.
                        Nihil anim keffiyeh helvetica, craft beer labore wes
                        anderson cred nesciunt sapiente ea proident. Ad vegan
                        excepteur butcher vice lomo. Leggings occaecat craft
                        beer farm-to-table, raw denim aesthetic synth nesciunt
                        you probably haven't heard of them accusamus labore
                        sustainable VHS.
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>2000 - 2012</Accordion.Header>
                  <Accordion.Body className="collapseparent">
                    <div className="row">
                      <div className="col-sm-4">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/img/listing-single/history-2.jpg"
                          }
                          alt="property history"
                        />
                      </div>
                      <div className="col-sm-8">
                        <h5>The Rebuilding Phase</h5>
                        Anim pariatur cliche reprehenderit, enim eiusmod high
                        life accusamus terry richardson ad squid. 3 wolf moon
                        officia aute, non cupidatat skateboard dolor brunch.
                        Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                        wolf moon tempor,
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>2013 - Till date</Accordion.Header>
                  <Accordion.Body className="collapseparent">
                    <div className="row">
                      <div className="col-sm-4">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/img/listing-single/history-3.jpg"
                          }
                          alt="property history"
                        />
                      </div>
                      <div className="col-sm-8">
                        <h5>Modernization</h5>
                        Anim pariatur cliche reprehenderit, enim eiusmod high
                        life accusamus terry richardson ad squid. 3 wolf moon
                        officia aute, non cupidatat skateboard dolor brunch.
                        Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                        wolf moon tempor,
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div> */}
            <Element name="schedule" className="section pt-0 mb-5 lg:mb-0">
              <div>
                <h4>{t("Schedule Link tour")}</h4>
                <form onSubmit={ScheduleSave}>
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t("fullName")}
                        name="fname"
                        value={scheduleLink?.fullname}
                        onChange={(e) =>
                          setScheduleLink({
                            ...scheduleLink,
                            fullname: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-6 form-group">
                      <input
                        type="email"
                        className="form-control"
                        placeholder={t("emailAddress")}
                        name="email"
                        value={scheduleLink?.email}
                        onChange={(e) =>
                          setScheduleLink({
                            ...scheduleLink,
                            email: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-6 form-group">
                      {/* <input
                        type="text"
                        className="form-control"
                        placeholder={t("phoneNumber")}
                        name="phone"
                        onChange={(e) =>
                          setScheduleLink({
                            ...scheduleLink,
                            phonenumber: e.target.value,
                          })
                        }
                      /> */}
                      <PhoneInput
                        country={"th"}
                        value={scheduleLink?.phonenumber}
                        onChange={(value) =>
                          setScheduleLink({
                            ...scheduleLink,
                            phonenumber: value, // Use the direct value
                          })
                        }
                        inputStyle={{ width: "100%" }}
                        placeholder="Telephone"
                      />
                    </div>
                    <div className="col-md-6 form-group">
                      <input
                        type="date"
                        className="form-control"
                        placeholder={t("date")}
                        name="date"
                        value={scheduleLink?.date}
                        onChange={(e) =>
                          setScheduleLink({
                            ...scheduleLink,
                            date: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-12 form-group">
                      <textarea
                        className="form-control"
                        placeholder={t("typeYourComment")}
                        name="comment"
                        value={scheduleLink?.comment}
                        onChange={(e) =>
                          setScheduleLink({
                            ...scheduleLink,
                            comment: e.target.value,
                          })
                        }
                        rows={7}
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn-custom primary mb-2"
                    name="button"
                    // onClick={ScheduleSave}
                  >
                    {t("Schedule Tour")}
                  </button>
                </form>
              </div>
            </Element>
            {/* Pagination Start
            <div className="section p-0 post-single-pagination-wrapper">
              <div className="post-single-pagination post-prev">
                <i className="fas fa-arrow-left" />
                <Link to="#" className="post-single-pagination-content">
                  <span>{t("prevListing")}</span>
                  <h6>{t("prevLocation")}</h6>
                </Link>
              </div>
              <div className="post-single-pagination post-next">
                <Link to="#" className="post-single-pagination-content">
                  <span>{t("nextListing")}</span>
                  <h6>{t("nextLocation")}</h6>
                </Link>
                <i className="fas fa-arrow-right" />
              </div>
            </div> */}
            {/* Pagination End */}
            {/* Similar Start */}

            {/* Similar End */}
          </div>
          {/* Listings End */}

          {/* Sidebar Start */}
          <div className="col-lg-4">
            <div className="sidebar ">
              <div className="sidebar-widget">
                <h5>{t("Meet The Agent")}</h5>
                {/* Author Start */}
                <div className="media sidebar-author listing-agent">
                  <div
                    className="flex justify-between"
                    style={{ height: "300px" }}
                  >
                    <div
                      style={{
                        width: "120px",
                        height: "140px",
                        marginRight: "30px",
                      }}
                    >
                      <Link to="#">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            state?.Agent?.agentthumbnail
                          }
                          alt="agent"
                          className="rounded w-full"
                        />
                      </Link>
                      <div className="media-body mt-4">
                        <h6>
                          {" "}
                          <Link to="#">{state?.Agent?.agentName}</Link>{" "}
                        </h6>
                        <span>{t("Company Agent")}</span>
                      </div>
                    </div>
                    <div style={{ width: "200px" }} className="flex flex-col">
                      {/* <Link to={state?.Agent?.agentTel}> */}
                      {/* {" "}
                        <i className="fas fa-phone" /> {t("callAgent")} */}
                      <button
                        type="submit"
                        onClick={() => setAgentcall(!Agentcall)}
                        className="btn-custom primary light btn-block mb-1"
                        style={{ width: "100%" }}
                      >
                       {t("callAgent")}
                      </button>
                      <button
                        type="submit"
                        onClick={() => setAgentinfo(!Agentinfo)}
                        className="btn-custom primary light btn-block mb-1 me-1"
                        style={{ width: "100%" }}
                      >
                        {!Agentinfo ? t("Agent Details") : "Hide Detail"}
                      </button>
                      {/* </Link>{" "} */}
                      {Agentinfo ? (
                        <div className="Agentinfo">
                          <div>
                            <h6 style={{ marginBottom: "10px" }}>
                              {t("Agent Info")}
                            </h6>
                            <p
                              style={{ marginBottom: "0px", fontSize: "12px" }}
                            >
                              {t("lineID")}: &nbsp;
                              {formatContactId(state?.Agent?.Line_Id)}
                            </p>
                            <p
                              style={{ marginBottom: "0px", fontSize: "12px" }}
                            >
                              {t("WhatsApp")}: &nbsp;
                              {formatContactId(state?.Agent?.agentwhatapp)}
                            </p>
                            <p
                              style={{ marginBottom: "0px", fontSize: "12px" }}
                            >
                              {t("WeChatID")}: &nbsp;
                              {formatContactId(state?.Agent?.WeChatID)}
                            </p>
                            <p
                              style={{ marginBottom: "0px", fontSize: "12px" }}
                            >
                              {t("Country")}: &nbsp;
                              {state?.Agent?.agentCountry?.length > 11
                                ? state.Agent.agentCountry.slice(0, 12) + "..."
                                : state.Agent.agentCountry}
                            </p>
                            <p
                              style={{ marginBottom: "0px", fontSize: "12px" }}
                            >
                              {t("Province")}: &nbsp;
                              {state?.Agent?.agentProvince?.length > 11
                                ? state.Agent?.agentProvince.slice(0, 12) +
                                  "..."
                                : state.Agent?.agentProvince}
                            </p>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="text-left">
                        <p style={{ marginBottom: "0px", color: "#519fff" }}>
                          Area Service: &nbsp;
                          {state?.Agent?.agentServices?.length > 10
                            ? state.Agent.agentServices.slice(0, 10) + "..."
                            : state.Agent.agentServices}
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* <Dropdown className="options-dropdown">
                    <Dropdown.Toggle as={NavLink}>
                      <i className="fas fa-ellipsis-v" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu-right">
                      <ul>
                        <li>
                          {" "}
                          <Link to="tel:+123456789">
                            {" "}
                            <i className="fas fa-phone" /> {t("callAgent")}
                          </Link>{" "}
                        </li>
                        <li>
                          {" "}
                          <Link to="/listing-grid">
                            {" "}
                            <i className="fas fa-th-list" /> {t("viewListings")}
                          </Link>{" "}
                        </li>
                        <li>
                          {" "}
                          <Link to="#">
                            {" "}
                            <i className="fas fa-star" /> {t("saveAgent")}
                          </Link>{" "}
                        </li>
                      </ul>
                    </Dropdown.Menu>
                  </Dropdown> */}
                </div>
                {/* Author End */}
                {/* Contact Start */}
                {/* <form>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("emailAddress")}
                      name="email"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("phoneNumber")}
                      name="phone"
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      name="lineid"
                      rows={3}
                      placeholder={t("lineid")}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      name="whatsappid"
                      rows={3}
                      placeholder={t("whatsappid")}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      name="wechatId"
                      rows={3}
                      placeholder={t("wechatId")}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      rows={3}
                      placeholder={t("placeholderYourMessage")}
                      className="form-control"
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn-custom primary light btn-block"
                  >
                    {t("sendMessage")}
                  </button>
                </form> */}
                {/* Contact End */}
              </div>
              <div className="sidebar-widget">
                <h5 className="pt-4">{t("Recent ss")}</h5>
                {/* Listing Start */}
                {listing?.map((item, i) => (
                  <div key={i} className="listing listing-list">
                    <div className="listing-thumbnail">
                      <Link
                        onClick={() => {
                          navigate(`/listing-details-v1/${item?._id}`);
                          window.location.reload(false);
                        }}
                      >
                        <img src={`${item?.Gallery.file?.[0]}`} alt="listing" />
                      </Link>
                    </div>
                    <div className="listing-body">
                      <h6 className="listing-title">
                        {" "}
                        <Link
                          onClick={() => {
                            navigate(`/listing-details-v1/${item?._id}`);
                            window.location.reload(false);
                          }}
                          title={item?.BasicInformation?.name}
                        >
                          {item?.BasicInformation?.name}
                        </Link>{" "}
                      </h6>
                      <span className="listing-price">
                        {item?.BasicInformation?.currency}
                        {item?.BasicInformation?.listingType === "Rent" ? (
                          <span>/{item?.BasicInformation?.rentalPrice}</span>
                        ) : (
                          <>{item?.BasicInformation?.price}</>
                        )}
                      </span>
                    </div>
                  </div>
                ))}
                {/* Listing End */}
              </div>
              <div className="sidebar-widget">
                <h5>{t("Mortgage Calculator")}</h5>
                <Calculator />
              </div>
            </div>
          </div>
          {/* Sidebar End */}
        </div>
      </div>
      {Agentcall && (
        <div>
          <Modal
            isOpen={Agentcall}
            onAfterOpen={afterOpenModal}
            onRequestClose={setAgentcall}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div className="flex justify-between items-center">
              <h6 ref={(_subtitle) => (subtitle = _subtitle)}>Call Agent</h6>
              <button
                className="btn btn-primary"
                onClick={() => setAgentcall(false)}
              >
                close
              </button>
            </div>
            <div className="flex justify-between item-center my-3">
              <p className="fs-6">
                Tel-number: {""}
                <span style={{ color: "#0d6efd" }}>
                  {state?.Agent?.agentTel?.length > 30
                    ? "+" + state.Agent.agentTel.slice(0, 30) + "..."
                    : "+" + state.Agent.agentTel}
                </span>
              </p>
              <p onClick={copyToClipboard} style={{ cursor: "pointer" }}>
                <MdOutlineContentCopy />
              </p>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default Listingwrapper;
