import React, { useEffect, useState } from "react";
import Faq from "./Faq";
import Pricebox from "./Pricebox";
import ListingFilter from "./ListingFilter";
import { Link, useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip, Dropdown, NavLink } from "react-bootstrap";
import Gallery from "../services/Gallery";
import Info from "../services/Info";
import Services from "../services/Services";
import Bluecta from "../../layouts/Bluecta";
import { Fragment } from "react";
import Buyimg from "../services/Buyimg";



const Content = () => {


  return (
    <Fragment>
      {/* <Buyimg /> */}
      <Info />
      <Services />
      <Gallery />
      <div className="section pt-0">
        <Bluecta />
      </div>
    </Fragment>
  );
};

export default Content;
