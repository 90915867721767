import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate, useNavigation, useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import axios from "axios";
import { Button } from "react-bootstrap";
import { t } from "i18next";
// import { useParams } from "react-router-dom";
import BASE_URL from "../../baseUrl";

const Menu = () => {
  const navig = useNavigate();
  const [mainLists, setMainLists] = useState([]);
  const [statuslist, setStatuslist] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedMainPage, setSelectedMainPage] = useState("");
  const params = useLocation();
  const fetchMainLists = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/NavList/api/mainlists`);
      const data = response?.data.sort((a, b) => a.index - b.index);

      setMainLists(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    fetchMainLists();
    axios
      .get(`${BASE_URL}/admin/get-categories`)
      .then((res) => {
        setStatuslist(res.data.result);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    const decodePathname = decodeURIComponent(params.pathname);
    const matchedMainList = mainLists.find((mainList) => {
      const mainPagePath = `/${mainPages[mainList.title]}`;
      return decodePathname.startsWith(mainPagePath);
    });

    if (matchedMainList) {
      setSelectedMainPage(matchedMainList._id);
    } else {
      // Check subPages
      mainLists.forEach((mainList) => {
        console.log(mainList.subLists);
        mainList.subLists.forEach((subList) => {
          const subPagePath = `/${subPages[subList.title]}/${mainList.title}`;
          // console.log(subPagePath);
          if (decodePathname.startsWith(subPagePath)) {
            setSelectedMainPage(mainList._id);
          }
        });
      });
    }
  }, [params.pathname, mainLists]);

  const navigBtn = (e) => {
    if (e.title == "Home") {
      navig("/home");
    }

    if (e.title == "About Us") {
      navig("/about");
    }
    if (e.title == "Services") {
      navig("/services");
    }
  };

  let mainPages = {
    Home: "home",
    "About Us": "about",
    Services: "services",
    Rent: "rent",
    sell: "sell",
    Buy: "buy",
    "Thing To Know": "category",
  };

  let subPages = {
    "Help With Distress Home": "Distress",
    "Financial Planing": "Planing",
    "Loan Modification": "Modification",
    "Property Management": "Management",
    "Investment Planner": "Planner",
    "How to buy poperty in Thailand": "Property",
    "Get Long Term Visa in Thailand": "Terms",
    "Thailand Real estate Laws": "Estate",
    "How to retire in Thailand": "Retire",
    Condo: "property/Condo",
    Commercial: "property/Commercial",
    Residential: "property/Residential",
    Apartment: "property/Apartment",
    Flats:"property/Flats",
    Land: "property/Land",
    Villa: "property/Villa",
    Island: "property/Island",
    Resort: "property/Resort",
    Hotel: "property/Hotel",
    "Residential/Condo/Apartment": "property/Residential/Condo/Apartment",
    "Warehouse & Factories": "property/Warehouse%20&%20Factories",
    Retails: "property/Retails",
  };

  const handleMainPageClick = (mainPage) => {
    setSelectedMainPage(mainPage);
  };

  return (
    <Fragment>
      {/* Logo */}
      <Link
        className="navbar-brand"
        to="/"
        onClick={() => window.location.replace("/")}
      >
        {" "}
        <img
          className="p-2"
          src={process.env.PUBLIC_URL + "/assets/img/logo.png"}
          alt="logo"
        />{" "}
      </Link>
      {/* Menu */}
      {/* <ul className="navbar-nav "> */}
      <ul id="menu_none" className="menu_none pt-4">
        {mainLists.map((mainList) => (
          <li
            key={mainList._id}
            className={`menu-item menu-item-has-children items-center ${selectedMainPage === mainList._id ? 'selected' : ''}`}
          >
            <div className="dropdown-wrapper">
              <Link
                to={`/${mainPages[mainList.title]}`}
                className="dropdown-trigger"
                onMouseEnter={() => setShowDropdown(mainList._id)}
                onClick={() => handleMainPageClick(mainList._id)}
                style={{ color: selectedMainPage === mainList._id ? '#519FFF' : '', backgroundColor: "white" }}
              >
                {t(mainList.title)}
              </Link>
              {showDropdown === mainList._id &&
                mainList.subLists.length > 0 && (
                  <div className="dropdown-menu">
                    {mainList.subLists.map((subList) => (
                     <>
                     {/* {console.log(subList.title)} */}
                      <Link
                        key={subList._id}
                        className="dropdown-item"
                        to={`/${subPages[subList.title]}/${mainList.title}`}
                      >
                        {t(subList.title)}
                      </Link>
                     </>
                    ))}
                  </div>
                )}
            </div>
          </li>
        ))}

        {/* -------------------------------------------------------- */}
      </ul>

      {/* {mainLists.map((mainList) => (
      <li key={mainList._id} className="menu-item menu-item-has-children items-center">
          <button className="w-100 px-3 text-white" type="button" onClick={() => navigBtn(mainList)}>
            <Dropdown>
              <Dropdown.Toggle
                id="dropdown-basic"
                style={{
                  background: "white",
                  color: "black",
                  border: "none",
                  fontWeight: "500",
                  padding: "0px 2px",
                }}
              >
                {mainList.title}
              </Dropdown.Toggle>

              <Dropdown.Menu>
              {mainList.subLists.map((subList) => (       
                <Dropdown.Item
                  // href="#/action-5"
                  key={subList._id}
                  style={{ padding: "6% 2%", margin: "0px 8px", width: "95%" }}
                >
                  <Link style={{ padding: "0" }}   to={`/${subPages[subList.title]}`}>
                  {subList.title}
                  </Link>
                </Dropdown.Item>
                            ))}

              </Dropdown.Menu>
            </Dropdown>
          </button>
        </li>
        ))} */}
      {/* <li className="menu-item menu-item-has-children">
          <Link to="/about">About Us</Link>
        </li>
        <li className="menu-item menu-item-has-children items-center">
          <Link to="#">
            <Dropdown>
              <Dropdown.Toggle
                id="dropdown-basic"
                style={{
                  background: "white",
                  color: "black",
                  border: "none",
                  fontWeight: "500",
                  padding: "0px 2px",
                }}
              >
                Services
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  href="#/action-1"
                  style={{ padding: "6% 2%", margin: "0px 8px", width: "95%" }}
                >
                  <Link style={{ padding: "0" }} to="/Distress">
                    Help With Distress Home
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item
                  href="#/action-2"
                  style={{ padding: "6% 2%", margin: "0px 8px", width: "95%" }}
                >
                  <Link style={{ padding: "0" }} to="/Planing">
                    Financial Planing
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item
                  href="#/action-3"
                  style={{ padding: "6% 2%", margin: "0px 8px", width: "95%" }}
                >
                  <Link style={{ padding: "0" }} to="/Modification">
                    Loan Modification
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item
                  href="#/action-4"
                  style={{ padding: "6% 2%", margin: "0px 8px", width: "95%" }}
                >
                  <Link style={{ padding: "0" }} to="/Management">
                    Property Management
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item
                  href="#/action-5"
                  style={{ padding: "6% 2%", margin: "0px 8px", width: "95%" }}
                >
                  <Link style={{ padding: "0" }} to="/Planner">
                    Investment Planner
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Link>
        </li>

        <li className="menu-item menu-item-has-children">
          <Link to="#">
            <Dropdown>
              <Dropdown.Toggle
                id="dropdown-basic"
                style={{
                  background: "white",
                  color: "black",
                  border: "none",
                  fontWeight: "500",
                  padding: "0px 2px",
                }}
              >
                Properties
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {statuslist?.map((res, key) => {
                  return (
                    <Dropdown.Item
                      key={key}
                      href="#/action-1"
                      style={{
                        padding: "6% 2%",
                        margin: "0px 8px",
                        width: "95%",
                      }}
                    >
                      <Link
                        style={{ padding: "0" }}
                        to={"/property/" + res.name}
                      >
                        {res.name}
                      </Link>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </Link>
        </li>
        <li className="menu-item menu-item-has-children">
          <Link to="#">
            <Dropdown>
              <Dropdown.Toggle
                id="dropdown-basic"
                style={{
                  background: "white",
                  color: "black",
                  border: "none",
                  fontWeight: "500",
                  padding: "0px 2px",
                }}
              >
                Sell
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {statuslist?.map((res, key) => {
                  return (
                    <Dropdown.Item
                      key={key}
                      href="#/action-1"
                      style={{
                        padding: "6% 2%",
                        margin: "0px 8px",
                        width: "95%",
                      }}
                    >
                      <Link style={{ padding: "0" }} to={"/sell/" + res.name}>
                        {res.name}
                      </Link>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </Link>
        </li>
       
        <li className="menu-item menu-item-has-children">
          <Link to="#">
            <Dropdown>
              <Dropdown.Toggle
                id="dropdown-basic"
                style={{
                  background: "white",
                  color: "black",
                  border: "none",
                  fontWeight: "500",
                  padding: "0px 2px",
                }}
              >
                Rent
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {statuslist?.map((res, key) => {
                  return (
                    <Dropdown.Item
                      href="#/action-1"
                      key={key}
                      style={{
                        padding: "6% 2%",
                        margin: "0px 8px",
                        width: "95%",
                      }}
                    >
                      <Link style={{ padding: "0" }} to={"/rent/" + res.name}>
                        {res.name}
                      </Link>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </Link>
        </li>
        <li className="menu-item menu-item-has-children items-center">
          <Link to="#">
            <Dropdown>
              <Dropdown.Toggle
                id="dropdown-basic"
                style={{
                  background: "white",
                  color: "black",
                  border: "none",
                  fontWeight: "500",
                  padding: "0px 2px",
                }}
              >
                Think To Know
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  href="#/action-1"
                  style={{ padding: "6% 2%", margin: "0px 8px", width: "95%" }}
                >
                  <Link style={{ padding: "0" }} to="/Property">
                    How to buy property in Thailand
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item
                  href="#/action-2"
                  style={{ padding: "6% 2%", margin: "0px 8px", width: "95%" }}
                >
                  <Link style={{ padding: "0" }} to="/Terms">
                    Get a long terms visa in Thailand
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item
                  href="#/action-3"
                  style={{ padding: "6% 2%", margin: "0px 8px", width: "95%" }}
                >
                  <Link style={{ padding: "0" }} to="/Estate">
                    Thailand real estate laws
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item
                  href="#/action-3"
                  style={{ padding: "6% 2%", margin: "0px 8px", width: "95%" }}
                >
                  <Link style={{ padding: "0" }} to="/Retire">
                    How to retire in Thailand
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Link>
        </li>
        <li className="menu-item menu-item-has-children items-center">
          <Link to="#">
            <Dropdown>
              <Dropdown.Toggle
                id="dropdown-basic"
                style={{
                  background: "white",
                  color: "black",
                  border: "none",
                  fontWeight: "500",
                  padding: "0px 2px",
                }}
              >
                Finance
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  href="#/action-1"
                  style={{ padding: "6% 2%", margin: "0px 8px", width: "95%" }}
                >
                  <Link style={{ padding: "0" }} to="/Mortgage">
                    How to get a mortgage loan in Thailand
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item
                  href="#/action-2"
                  style={{ padding: "6% 2%", margin: "0px 8px", width: "95%" }}
                >
                  <Link style={{ padding: "0" }} to="/Invest">
                    Can you invest in Thailand
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item
                  href="#/action-3"
                  style={{ padding: "6% 2%", margin: "0px 8px", width: "95%" }}
                >
                  <Link style={{ padding: "0" }} to="/Loans">
                    Can you get a loans in Thailand
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Link>
        </li>
        <li className="menu-item menu-item-has-children">
          <Link to="/agent">Retirement Asia</Link>
       </li> */}
    </Fragment>
  );
};

export default Menu;
