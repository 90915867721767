import React, { useState } from "react";
import { Link } from "react-router-dom";

const TextContent = ({ closeModal, accept, value }) => {
  // const [check, setCheck] = useState(false);
  return (
    <div className="container">
      <h4 className="text-center">TERMS & CONDITIONS</h4>
      <h5>General</h5>
      <p>
        <span className="bolde">Buyhomeforless.com,</span> a subsidiary of Mstar
        (Asia) co.ltd, and its corporate affiliates including Mstar Holding
        Inc.. (collectively, “Mstar”) operate a family of Web sites (including
        mobile-optimized Web sites), associated Web pages, associated mobile
        apps, and other applications, linking to or bearing these Terms of Use
        and operated by or on behalf of Mstar (Asia) Co.Ltd. (collectively, the
        "Mstar"). Any person accessing or using the Buyhomeforless.com or
        Mstarproperty.com is referred to as “you.”
      </p>
      <br />
      <p>
        <span className="bolde mt-5">Acceptance of Terms</span>
        <br />
        By accessing or using any part of the Buyhomeforless.com or the services
        provided on it or other Web sites, Web pages or other applications as
        set forth below (collectively, the “Services”), you agree to accept and
        comply with the terms, conditions, and notices stated herein and as may
        be modified by Buyhomeforless.com from time-to-time without notice to
        you (the “Terms of Use”). These Terms of Use constitute a binding
        contract between Buyhomeforless.com and you. You are responsible for
        regularly reviewing the Terms of Use. You can review the most current
        version of theTerms of Use at any
        https://www.buyhomeforless.com/terms-of-use. If you do not wish to be
        bound by these Terms of Use, please do not access or use any part of the
        Buyhomeforless.com. Additional terms may govern use of certain Web sites
        or other parts of the Buyhomeforless.com. In the event that any
        provision, term or guideline contained on a particular Web site or other
        part of the Buyhomeforless.com conflicts with the Terms of Use, the
        terms of such Web site or other part of the Buyhomeforless.com shall
        control over the Terms of Use except with respect to the General Terms
        set forth below, which shall at all times control.
      </p>
      <br />
      <p>
        <span className="bolde mt-5">General Use of the Mstar</span>
        <br />
        Unless specified otherwise, Mstar offers you access to the
        Buyhomeforless.com solely for your personal and non-commercial uses. You
        agree to only access or use the Buyhomeforless.com only for legal
        purposes that are permitted by the Terms of Use. Among other things, you
        agree not to:
        <br />
        <ul>
          <li>
            • except with the express written permission of MSTAR, modify, copy,
            distribute, transmit, display, perform, reproduce, publish, license,
            create derivative works from, frame in another Web page, use on any
            other Web site or application, transfer or sell any information,
            software, lists of users, databases or other lists, products or
            services provided through or obtained from the Move Network,
            including without limitation, engaging in the practices of “screen
            scraping,” "databasesscraping,” or any other activity with the
            purpose of obtaining lists of users or other information;{" "}
          </li>
          <li>
            • damage, disable, overburden, or impair the Buyhomeforless.com or
            interfere with any other party’s use and enjoyment of the
            Buyhomeforless.com;
          </li>
          <li>
            • Obtain or attempt to obtain any materials or information through
            any means not made readily accessible by Mstar through the
            Buyhomeforless.com.
          </li>
        </ul>
      </p>
      <br />
      <p>
        Except with the express written permission of Move, you agree that you
        will not access or attempt to access password protected, secure or
        non-public areas of the Move Network.
        <br />
        <br />
        Except with the express written permission of Mstar, you agree that you
        will not create links from any Web site, Web page or other application
        to any page within, or part of, the Buyhomeforless.com; provided however
        upon prior written notice to Mstar at support@buyhomeforless.com, you
        may create a link directly to any of the Buyhomeforless.com home pages,
        including, but not limited to pages currently located at
        https://buyhomeforless.com/. The origin of any link to any
        buyhomeforless.com/ home page must be accompanied by a clear and
        prominent attribution indicating that the link is connected to the
        buyhomeforless.com home page. By creating a link to the
        buyhomeforless.com, you agree that (a) you will not employ any
        technology that results in the placement of content from the
        buyhomeforless.com in a frame and/or a reduced pop-up window and/or any
        other display mechanism which changes appearance of the
        buyhomeforless.com from how it would appear if a user typed the URL in a
        typical browser line, (b) your site shall not display content or link to
        other Web sites or applications that contain content that is illegal,
        obscene, indecent, disparaging, discriminating or otherwise offensive,
        (c) you have duly registered your domain name and possess all rights
        necessary to use the same, and (d) you shall not in any manner access,
        collect, store, disclose, transfer or use any information obtained or
        derived from a user’s access to or use of the buyhomeforless.com. Move
        reserves the right to revoke your permission to create such link at any
        time in its sole discretion and you agree to immediately cease using the
        link at any time that Mstar so requests.
        <br />
        <br />
        You agree that Mstar may in its sole discretion and at any time
        terminate your access to and use of the buyhomeforless.com, or any part
        thereof, with or without notice. You further agree that use of the
        buyhomeforless.com and any of its Services shall be immediately
        terminated if you violate these Terms of Use. In addition, Mstar
        reserves the right, in its sole discretion, to modify or discontinue the
        Services or any portion thereof, with or without notice, and without
        liability to you.
      </p>
      <p>
        The Services provided through the buyhomeforless.com may also be located
        on third party Web sites or applications, via a link, click-through
        advertising, or otherwise. Nothing contained in any of these Services is
        an offer or promise by Mstar to sell a specific product for a specific
        price or that any advertiser will sell any product or service for any
        purpose or price or on any specific terms. In addition, Mstar does not
        make any representation or warranty with respect to such third party
        Services, and is not responsible for their accuracy, sufficiency,
        veracity, completeness, or timeliness. You are responsible for
        confirming the sufficiency and reliability of any third party Services,
        and you hereby release Mstar from any and all claims, demands, liability
        and damages (actual or consequential) of every kind and nature, known
        and unknown, suspected or unsuspected, disclosed or undisclosed,arising
        out of or in any way connected with your use of such Services. Further,
        please note that certain of the interactive Services may be financial or
        mortgage tools that provide information and customized information based
        on user-inputted data. These tools are for the purpose of performing
        calculations, and are not an offer to lend. Interest rates shown are for
        demonstration purposes only. Actual market interest rates may vary.
      </p>
      <p>
        You acknowledge that MSTAR is not a real estate broker, mortgage broker
        or mortgage lender, and MSTAR does not aid or assist borrowers in
        obtaining, solicit borrowers or lenders for, negotiate or make loans
        secured by liens on real property
      </p>
      <p>
        Please note that you may be subject to additional and/or different
        terms, conditions, and privacy policies when you use third party
        Services, Content (as defined below), software or sites.
      </p>
      <h6>Content Contained in the Move Network</h6>
      <p>
        The buyhomeforless.com and all of its contents including, but not
        limited to, all information, text, messages, images, photos,
        illustrations, designs, icons, video clips, sounds, files, trademarks,
        copyrighted material, trade dress, software, specifications, catalogs,
        literature, technical information, advertisements and other content or
        materials on the buyhomeforless.com (collectively, “Content”) is owned
        by Mstar and/or third parties with all rights reserved unless otherwise
        noted. MSTAR grants you a limited license to access and use the
        buyhomeforless.com and Content solely for informational, personal and
        non-commercial purposes (including printing individual pages from the
        buyhomeforless.com provided that you retain all copyright and other
        proprietary notices contained thereon). You are strictly prohibited from
        downloading (other than page caching) modifying, or making any other use
        of the buyhomeforless.com or Content, except with express written
        consent of Mstar. You understand that all third party Content posted on,
        transmitted through, or linked from the buyhomeforless.com, is the sole
        responsibility of the third party originator of such Content. Content is
        provided through the buyhomeforless.com as is, and you agree that the
        use of and reliance on any Content is at your own risk, and that under
        no circumstances shall MSTAR be liable for any Content or for any loss
        or damage of any kind incurred as a result of the use of any Content
        made available via the Services.
      </p>
      <p>
        prohibited from downloading (other than page caching) modifying, or
        making any other use of the buyhomeforless.com or Content, except with
        express written consent of Mstar. You understand that all third party
        Content posted on, transmitted through, or linked from the
        buyhomeforless.com, is the sole responsibility of the third party
        originator of such Content. Content is provided through the
        buyhomeforless.com as is, and you agree that the use of and reliance on
        any Content is at your own risk, and that under no circumstances shall
        MSTAR be liable for any Content or for any loss or damage of any kind
        incurred as a result of the use of any Content made available via the
        Services
      </p>
      <p>
        In so far as the BUYHOMEFORLESS.COM implements Google Maps from Google,
        your use of Google Maps is subject to the Google Maps/Google Earth
        Additional Terms of Service at
        <Link to=" https://www.google.com/intl/en-US_US/help/terms_maps/">
          https://www.google.com/intl/en-US_US/help/terms_maps/
        </Link>
        , including the Google Privacy Policy at{" "}
        <Link to="https://policies.google.com/privacy">
          https://policies.google.com/privacy
        </Link>
        .
      </p>
      <p>
        You acknowledge that Mstar does not screen or approve third-party
        Content, and that MSTAR shall have the right (but not the obligation) in
        its sole discretion to refuse, modify, delete or move any Content that
        is available via the buyhomeforless.com, for any reason
      </p>
      <h6>Content You Provide</h6>
      <p>
        To the extent that you post, upload, input, submit or otherwise transmit
        (collectively, “Transmit” or “Transmitting” as appropriate) Content on
        or through the buyhomeforless.com, you agree to provide true, accurate
        and complete information and to refrain from impersonating or falsely
        representing your affiliation with any person or entity. You are
        entirely responsible for all Content that you provide or otherwise make
        available via the buyhomeforless.com. You also warrant and represent
        that you own or otherwise control all of the rights to such Content
        including, without limitation, all the rights necessary for you to
        Transmit such Content, and to transfer your or others’ interests in such
        Content to Mstar as provided below.
      </p>
      <p>You promise that you will not use the buyhomeforless.com to:</p>
      <ul>
        <li>
          • Transmit any Content
          <ul className="ml-3">
            <li>o not related to appropriate subject matters;</li>
            <li>o which is misleading to others, including consumers; </li>
            <li>
              {" "}
              o that is unlawful, harmful, threatening, abusive, harassing,
              tortuous,defamatory, vulgar, obscene, libelous, invasive of
              another’s privacy, hateful, or racially, ethnically or otherwise
              objectionable;
            </li>
            <li>
              {" "}
              o that you do not have a right to post and transmit under any law
              or under contractual relationships;
            </li>
            <li>
              o such that such posting, uploading, or transmission constitutes
              the infringement of any patent, trademark, trade secret, copyright
              or other proprietary rights of any party;
            </li>
            <li>
              {" "}
              o and materials that contain software viruses or any other
              computer code, files or programs designed to interrupt, destroy or
              limit the functionality of any computer software or hardware or
              telecommunications equipment; or
            </li>
          </ul>
        </li>
        <li>
          • Intentionally or unintentionally violate any applicable local,
          state, national or international law, or any regulations having the
          force of law, including the Civil Rights Act of Thailand Fair Housing
          Act, the Thailand with Disabilities Act, and the Equal Credit
          Opportunity Act. By Transmitting Content to the buyhomeforless.com,
          you grant, and you represent and warrant that you have the right to
          grant, to Mstar an irrevocable, perpetual, non-exclusive, fully paid,
          worldwide license to use, copy, perform, display, and distribute the
          Content and to prepare derivative works of, or incorporate into other
          works, the Content, and to grant and authorize sublicenses (through
          multiple tiers) of the foregoing. Furthermore, by posting Content to
          any public area of the buyhomeforless.com, you grant Mstar all rights
          necessary to prohibit any subsequent aggregation, display, copying,
          duplication, reproduction, or exploitation of the Content on the
          BUYHOMEFORLESS.COM by any party for any purpose.
          <br />
          Mstar takes matters of privacy and information seriously, and
          endeavors to maintain the privacy and security of any and all such
          information that you provide to Mstar through the buyhomeforless.com
          in accordance with its Privacy Policy. Please click here to review the
          Mstar Privacy Policy. The Mstar Privacy Policy applies to any and all
          Content and other information you provide, or that is collected from
          or about you, through your use of the buyhomeforless.com, as provided
          for, and insofar as provided for, in the Mstar Privacy Policy
          <br />
          <br />
          <span className="bolde">Mortgage-Related Disclosure</span>
          <br />
          <br />
          This disclosure statement pertains to the conduct of Mstar, including
          services Mstar provides, in connection with the mortgage loan industry
          and Web sites and mobile applications operated by Mstar. Mstar does
          not confirm, and does not make or give any guarantee regarding,
        </li>
        <li>
          • (i) any information in any request for, or in any request to be
          contacted about, a quote, pre-qualification, pre-approval, loan
          commitment, loan or loan-related document, nor (ii) any information
          about any consumer, potential borrower or other person who submits or
          is the subject of an Inquiry, nor (iii) any information about, or in
          any rating of or feedback about, any advertiser, lender, broker or
          mortgage or loan professional
        </li>
      </ul>
      <p>
        Mstar does not guarantee any information provided by any borrower, any
        specific loan terms and conditions offered by an advertiser, lender,
        broker or mortgage professional, or that any person will be offered or
        able to obtain any particular rates, loan, loan application, quote,
        pre-qualification, pre-approval, loan commitment or loan-related
        transaction.
      </p>
      <p>
        Mstar is not a lender, mortgage broker, loan originator, loan processor
        or underwriter. Mstar does not aid or assist in obtaining loans or loan
        applications, nor in setting or negotiating any terms thereof, nor in
        making loan or loan application decisions
      </p>
      <p>
        Mstar does not offer loans and does not solicit or participate in the
        solicitation of loans or loan applications.
      </p>
      <p>
        Mstar does not take applications for loans or credit nor issue or
        participate in the issuance of pre-qualifications or pre-approvals
        (Please note that the meaning of “pre-qualification” and “pre-approval”
        can sometimes differ the way those terms are used by mortgage lenders
        and brokers, real estate agents and others. Although “pre-qualification”
        often is used to refer to a lender’s preliminary estimate, based on
        unverified information you provide, of the mortgage loan amount you
        might be able to borrow, and “pre-approval” often is used to refer to an
        offer or statement provided to you from a lender (a pre-approval
        letter), based on verified information and often or loan-related
        document, nor (ii) any information about any consumer, potential
        borrower or other person who submits or is the subject of an Inquiry,
        nor (iii) any information about, or in any rating of or feedback about,
        any advertiser, lender, broker or mortgage or loan professional Mstar
        does not guarantee any information provided by any borrower, any
        specific loan terms and conditions offered by an advertiser, lender,
        broker or mortgage professional, or that any person will be offered or
        able to obtain any particular rates, loan, loan application, quote,
        pre-qualification, pre-approval, loan commitment or loan-related
        transaction. Mstar is not a lender, mortgage broker, loan originator,
        loan processor or underwriter. Mstar does not aid or assist in obtaining
        loans or loan applications, nor in setting or negotiating any terms
        thereof, nor in making loan or loan application decisions. Mstar does
        not offer loans and does not solicit or participate in the solicitation
        of loans or loan applications. Mstar does not take applications for
        loans or credit nor issue or participate in the issuance of
        pre-qualifications or pre-approvals (Please note that the meaning of
        “pre-qualification” and “pre-approval” can sometimes differ the way
        those terms are used by mortgage lenders and brokers, real estate agents
        and others. Although “pre-qualification” often is used to refer to a
        lender’s preliminary estimate, based on unverified information you
        provide, of the mortgage loan amount you might be able to borrow, and
        “pre-approval” often is used to refer to an offer or statement provided
        to you from a lender (a pre-approval letter), based on verified
        information and often requiring a completed loan application, indicating
        a specific loan amount you may be offered (or are being offered) subject
        to the terms of the pre-approval letter, you should be aware that
        “pre-qualification” and “pre-approval” might have different meanings
        depending on who is using those terms. One or both of those terms might
        even have different meanings under different mortgage-related laws, and
        the use of and requirements for pre-qualifications or pre-approvals from
        lending institutions might vary depending on the mortgage loan practices
        of a given lending institution.)
      </p>
      <p>
        Any request for, inquiry about or agreement to receive or be contacted
        about loans, loan applications, quotes, pre-qualifications,
        pre-approvals, loan commitments or loan-related transactions by any
        consumer or person (e.g., by way of advertisements on Web sites or
        mobile apps operated by Mstar) is not, and will not be responded to by
        Mstar as, an order, application, offer or transaction for any credit,
        loan, loan application, quote, pre-qualification, pre-approval, loan
        commitment or loan-related transaction or other loan or credit product.
      </p>
      <p>
        Mstar does not endorse or recommend any lender, any borrower nor any of
        the products, rates or offerings of any lender, broker or mortgage
        professional.
      </p>
      <p>
        Nothing contained in any mortgage-related ad or other ad is an offer to
        provide, or a solicitation for, any loan, loan application, quote,
        pre-qualification, pre-approval, loan commitment or loan-related
        transaction.
      </p>
      <p>
        Mstar is not an agent of any consumer or borrower nor of any advertiser,
        lender, broker or mortgage or loan professional.
      </p>
      <p>
        Mstar sometimes may provide advertising services, services to facilitate
        contact when consumers wish to be contacted by lenders, mortgage brokers
        or other loan professionals and/or other like services relating to use
        of Web sites or mobile apps Mstar operates.
      </p>
      <p>
        Decisions regarding loans, loan applications, quotes,
        pre-qualifications, pre-approvals, loan commitments or loan-related
        transactions are not made by Mstar but are made by lenders, brokers or
        other loan professionals which might include participating advertisers
        of MSTAR.
      </p>
      <p>
        Mstar does not make or give any guarantee regarding, and assumes no
        responsibility for, any such decisions, nor any acts or omissions of
        lenders, brokers or other loan professionals, nor any result or
        consequence (direct or indirect) of any business transaction (actual,
        proposed or discussed) or related communications between lenders,
        brokers or other loan professionals and any other person, nor any rates,
        information or other content on or accessible through any Web site or
        app that did not originate solely from Mstar.
      </p>
      <p>
        The foregoing disclosures reflect Mstar good faith conclusions regarding
        the subject matter of such disclosures, and MSTAR takes no
        responsibility for, and shall have no liability as a result of or in
        connection with, any assertions, findings or conclusions that might be
        made, adjudicated, established or otherwise reached by anyone other than
        Mstar, nor any actual or alleged inaccuracy of or other fault with any
        such disclosures.
      </p>
      <p>
        For purposes of this Mortgage-Relate Disclosure statement, “Mstar”
        refers to each of Mstar holding Inc., Mstar (asia) co., ltd. and each of
        its subsidiaries.
      </p>

      <ul>
        <h6>Ability to Opt Out from Receiving Certain Communications.</h6>
        <p>
          You have the choice to opt out of receiving certain marketing
          communications (email or text messages) you might receive from Mstar
          or a Mstar division (each referred to here as a "Sender"). Normally, a
          marketing email you receive from a Sender will have an opt-out link
          provided within the email itself (usually toward the bottom), and you
          typically can opt out of receiving further such marketing emails by
          clicking on the opt-out link and indicating your decision. If you
          receive marketing texts from a Sender, you typically can opt out of
          receiving further such texts by following instructions provided in the
          text itself, often by texting "STOP" , or by emailing the Mstar
          Customer Care team at{" "}
          <Link to="Privacy@Mstarholding.com">Privacy@Mstarholding.com</Link>{" "}
          and including sufficient information to allow the Sender to properly
          respond to your request (e.g., your full name, your complete phone
          number with area code, and an image or description of the marketing
          text you received). Whether it's email or text, your request will be
          handled promptly; but please note that for a reasonable time you might
          receive further such marketing emails or texts while the Sender
          processes your request. Note also that opting out of marketing emails
          or texts will not necessarily cause you to stop receiving
          non-marketing emails or texts, such as texts about a purchase you may
          have made, a transaction you may have with a Sender, a lead you have
          submitted about a property that is for sale or perhaps even about
          listing alerts you may have requested, as well as other types (for
          example, about technical, administrative or informational matters) --
          but even some or all of these kinds of messages (especially if texts)
          the Sender may stop as well.
        </p>

        <p>
          Some additional program details regarding SMS (text) messaging from a
          Sender, including about opt out, are as follows:
        </p>
        <li>• Message and data rates may apply.</li>
        <li>
          • Message number and frequency varies and may be recurring for any
          given program.
        </li>
        <li>
          • Text "STOP" in reply to a marketing text from a Sender to stop
          receiving further such texts (see above for other details).
        </li>
        <li>
          • Text "HELP" in reply to a marketing text from a Sender to seek help
          or assistance.
        </li>
        <li>• Example of a program's sponsor or Sender: buyhomeforless.com</li>
        <li>
          • Texts sent may include both marketing and non-marketing texts.
        </li>
        <li>
          • Carriers disclaim liability for delayed or undelivered messages.
        </li>
      </ul>

      <h6>General Terms</h6>
      <h6>Applicable Law; Agreement to Arbitrate</h6>
      <p>
        THESE TERMS OF USE ARE GOVERNED BY THE LAWS OF THAILAND WITHOUT REGARD
        TO CONFLICTS OF LAWS PRINCIPLES. YOU HEREBY AGREE THAT ANY AND ALL
        DISPUTES OR CLAIMS THAT MAY ARISE BETWEEN YOU AND MSTAR SHALL BE
        RESOLVED EXCLUSIVELY THROUGH FINAL AND BINDING ARBITRATION, RATHER THAN
        IN COURT, EXCEPT THAT YOU MAY ASSERT CLAIMS IN SMALL CLAIMS COURT IF
        YOU’RE CLAIMS QUALIFY. THE FEDERAL ARBITRATION ACT SHALL GOVERN THE
        INTERPRETATION AND ENFORCEMENT OF THIS PARAGRAPH. YOU AGREE THAT YOU AND
        MSTAR MAY BRING CLAIMS AGAINST EACH OTHER ONLY ON AN INDIVIDUAL BASIS
        AND NOT AS PART OF ANY PURPORTED CLASS OR REPRESENTATIVE ACTION OR
        PROCEEDING. UNLESS BOTH YOU AND MSTAR AGREE OTHERWISE, THE ARBITRATOR
        MAY NOT CONSOLIDATE OR JOIN MORE THAN ONE PERSON'S OR PARTY'S CLAIMS,
        AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED,
        REPRESENTATIVE, OR CLASS PROCEEDING. FURTHER, THE ARBITRATOR MAY AWARD
        RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ONLY IN
        FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT
        NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT PARTY'S INDIVIDUAL
        CLAIM(S). THE ARBITRATION WILL BE CONDUCTED BY THE THAILAND ARBITRATION
        CENTER ("THAC") UNDER ITS RULES AND PROCEDURES, AS MODIFIED BY THIS
        PARAGRAPH. THE ("THAC") RULES ARE AVAILABLE AT . A FORM FOR INITIATING
        ARBITRATION PROCEEDINGS IS AVAILABLE ON THE (THAC) WEB SITE AT
        https://thac.or.th/ ARBITRATION SHALL BE HELD IN THE COUNTRY IN WHICH
        YOU RESIDE OR AT ANOTHER MUTUALLY AGREED LOCATION. IF THE VALUE OF THE
        RELIEF SOUGHT IS $10,000 OR LESS, YOU OR MOVE MAY ELECT TO HAVE THE
        ARBITRATION CONDUCTED BY TELEPHONE OR BASED SOLELY ON WRITTEN
        SUBMISSIONS, WHICH ELECTION SHALL BE BINDING ON YOU AND MSTAR SUBJECT TO
        THE ARBITRATOR'S DISCRETION TO REQUIRE AN IN-PERSON HEARING, IF THE
        CIRCUMSTANCES WARRANT. ATTENDANCE AT AN IN-PERSON HEARING MAY BE MADE BY
        TELEPHONE BY YOU AND/OR MSTAR, UNLESS THE ARBITRATOR REQUIRES OTHERWISE.
        THE ARBITRATOR'S AWARD SHALL BE FINAL AND BINDING AND JUDGMENT ON THE
        AWARD RENDERED BY THE ARBITRATOR MAY BE ENTERED IN ANY COURT HAVING
        JURISDICTION THEREOF. PAYMENT OF ALL FILING, ADMINISTRATION AND
        ARBITRATOR FEES WILL BE GOVERNED BY THE ("THAC") RULES, UNLESS OTHERWISE
        STATED IN THIS PARAGRAPH. IF A COURT DECIDES THAT ANY PART OF THIS
        PARAGRAPH IS INVALID OR UNENFORCEABLE, THE OTHER PARTS OF THIS PARAGRAPH
        SHALL STILL APPLY. ACCESS OR USE OF THE BUYHOMEFORLESS.COM IS
        UNAUTHORIZED IN ANY JURISDICTION THAT DOES NOT GIVE EFFECT TO ALL
        PROVISIONS OF THESE TERMS AND CONDITIONS, INCLUDING WITHOUT LIMITATION
        THIS PARAGRAPH.
      </p>
      <p>
        You agree that no joint venture, partnership, employment, or agency
        relationship exists between you and MSTAR as a result of the Terms of
        Use or accessing or using the BUYHOMEFORLESS.COM. MSTAR performance
        under the Terms of Use is subject to existing laws and legal process,
        and nothing contained in the Terms of Use is in derogation of MSTAR
        right to comply with governmental, court and law enforcement requests or
        requirements relating to your access or use of the BUYHOMEFORLESS.COM or
        information provided to or gathered by MSTAR with respect to such use.
      </p>
      <h6>Interpretation of the Terms of Use</h6>
      <p>
        If any part of the Terms of Use is determined to be invalid or
        unenforceable pursuant to applicable law including, but not limited to,
        the warranty disclaimers and liability limitations contained herein,
        then the invalid or unenforceable provision will be deemed superseded by
        a valid, enforceable provision that most closely matches the intent of
        the original provision and the remainder of the Terms of Use shall
        continue in effect. Unless otherwise specified herein (or in any other
        definitive written agreement between you and MSTAR), the Terms of Use
        constitutes the entire agreement between you and MSTAR with respect to
        the BUYHOMEFORLESS.COM and it supersedes all prior or contemporaneous
        communications and proposals, whether electronic, oral or written,
        between you and MSTAR with respect to the BUYHOMEFORLESS.COM. A printed
        version of the Terms of Use and of any notice given in electronic form
        shall be admissible in judicial or administrative proceedings based upon
        or relating to the Terms of Use to the same extent and subject to the
        same conditions as other business documents and records originally
        generated and maintained in printed form.
      </p>
      <h6>Disclaimer of Warranties</h6>
      <p>
        YOU EXPRESSLY UNDERSTAND AND AGREE THAT: YOUR USE OF AND RELIANCE UPON
        ANY AND ALL OF THE BUYHOMEFORLESS.COM, SERVICES AND/OR CONTENT IS AT
        YOUR SOLE RISK AND IS MADE AVAILABLE ON AN “AS IS” AND “AS AVAILABLE”
        BASIS. MSTAR MAKES NO EXPRESS OR IMPLIED REPRESENTATIONS, WARRANTIES, OR
        GUARANTEES WITH REGARD TO THE APPROPRIATENESS, ACCURACY, SUFFICIENCY,
        CORRECTNESS, VERACITY, VALUE, COMPLETENESS, OR TIMELINESS OF THE MOVE
        NETWORK, SERVICES AND/OR CONTENT. MSTAR EXPRESSLY DISCLAIMS ALL
        WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
        LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE AND NON-INFRINGEMENT.
      </p>
      <h6>Indemnification</h6>
      <p>
        YOU AGREE TO INDEMNIFY AND HOLD MSTAR, ITS THIRD-PARTY VENDORS,
        CORPORATE AFFILIATES, DIRECTORS, OFFICERS, EMPLOYEES AND AGENTS HARMLESS
        FROM ANY AND ALL CLAIMS AND DEMANDS, INCLUDING, BUT NOT LIMITED TO
        REASONABLE ATTORNEYS’ FEES, MADE BY ANY THIRD-PARTY DUE TO OR ARISING
        OUT OF ANY CONTENT, SUBMITTED, POSTED, OR OTHERWISE PROVIDED BY YOU TO
        THE BUYHOMEFORLESS.COM AND/OR MSTAR AND/OR ITS THIRD PARTY ADVERTISERS
        AND ANY BREACH BY YOU OR YOUR AFFILIATES, EMPLOYEES, AGENTS AND
        REPRESENTATIVES OF THESE TERMS OF USE.
      </p>
      <h6>Limitation of Liability</h6>
      <p>
        IN NO EVENT SHALL MOVE BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE,
        INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER
        INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS,
        ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF
        THE BUYHOMEFORLESS.COM, WITH THE DELAY OR INABILITY TO ACCESS OR USE THE
        BUYHOMEFORLESS.COM OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO
        PROVIDE SERVICES, OR FOR ANY CONTENT, SOFTWARE, PRODUCTS AND SERVICES
        MADE AVAILABLE OR OBTAINED THROUGH THE BUYHOMEFORLESS.COM, OR OTHERWISE
        ARISING OUT OF THE USE OF THE MOVE NETWORK, WHETHER BASED ON CONTRACT,
        TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF MSTAR HAS BEEN
        ADVISED OF THE POSSIBILITY OF DAMAGES. ANY SERVICES OR CONTENT MADE
        AVAILABLE OR OBTAINED THROUGH THE USE OF THE BUYHOMEFORLESS.COM, AND ALL
        OTHER USE OF THE BUYHOMEFORLESS.COM, IS DONE AT YOUR OWN DISCRETION AND
        RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER
        SYSTEM OR LOSS OF DATA THAT RESULTS THEREFROM.
      </p>
      <p>
        IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE MOVE NETWORK, OR WITH
        ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO
        DISCONTINUE USING THE BUYHOMEFORLESS.COM.
      </p>
      <h6>Copyright</h6>
      <p>
        All materials on the BUYHOMEFORLESS.COM (as well as the organization and
        layout of the BUYHOMEFORLESS.COM) are owned and copyrighted or licensed
        by Move, its corporate affiliates or its third-party vendors. © by MSTAR
        HOLDING, Inc., all rights reserved. No reproduction, distribution, or
        transmission of the copyrighted materials on the BUYHOMEFORLESS.COM is
        permitted without the written permission of MSTAR. Any rights not
        expressly granted herein are reserved.
      </p>
      <p>
        Pursuant to Title 17, United States Code, Section 512, notifications of
        claimed copyright infringement should be sent to MSTAR HOLDING INC.
        Designated Agent set forth below. All inquiries must be filed in
        accordance with Title 17, United States Code, Section 512.
      </p>
      <p>
        IP Administrator <br />
        Legal Department MSTAR HOLDING, Inc. <br />
        8526 PICO VISTA RD <br />
        PICO RIVERA, CA 90660 <br />
        Telephone: (626) 592-8975 <br />
        legal@mstarholding.com
      </p>
      <p>
        Repeat Infringers. It is Mstar policy to terminate in appropriate
        circumstances access to or use of the BUYHOMEFORLESS.COM (or any part
        thereof) by repeat infringers.
      </p>
      <h6>Trademarks</h6>
      <p>
        BUYHOMEFORLESS.COM, and all taglines and stylized logo treatments
        including any one or more of the foregoing are trademarks of the
        BUYHOMEFORLESS.COM and are used with its permission. These and all other
        trademarks used in this work are the property of their respective owners
      </p>
      <div>
        <input
          onClick={() => {
            accept();
          }}
          checked={value}
          className="mr-1 mt-2"
          type="checkbox"
        />
        <label>Accept all terms and conditions</label>
        <button
          onClick={closeModal}
          style={{ float: "right" }}
          className="btn btn-primary"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default TextContent;
