import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
// import Header from "../layouts/Headerfive";
import Content from "../sections/register/Content";
import Header from "../layouts/Header";

const Register = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Buy Home For Less | Register</title>
        <meta name="description" content="#" />
      </Helmet>
      {/* <Header /> */}
      <Header />  
      <Content />
    </Fragment>
  );
};

export default Register;
