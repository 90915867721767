import React from "react";
import Footercontent from "./Footercontent";

const Footerthree = () => {
  return (
    <footer className="acr-footer footer-dark">
      <Footercontent />
    </footer>
  );
};

export default Footerthree;
