import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import Header from "../layouts/Header";
import Breadcrumb from "../layouts/Breadcrumb";
import Footer from "../layouts/Footerthree";
import Content from "../sections/rent/Content";
import { useTranslation } from "react-i18next";
import Categories from "../sections/homefour/Categories";

const Catgory_thing_to_know = () => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <Helmet>
                <title>Buy Home For Less | things to know</title>
                <meta name="description" content="#" />
            </Helmet>
            <Header />
            <Breadcrumb breadcrumb={{ pagename: t("Things To Know") }} />
            <div className="container">
                <Categories />
            </div>
            {/* <Content /> */}
            <Footer />
        </Fragment>
    );
};

export default Catgory_thing_to_know;
