/////////////////////     Functional Component ////////////////////////////
import React, { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import Header from "../layouts/Header";
import Breadcrumb from "../layouts/Breadcrumb";
import Footer from "../layouts/Footerthree";
import Content from "../sections/submit-listing/Content";
import { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

const Submitlisting = () => {
  const {t} = useTranslation();

  // const [titleSeo, setTitleSeo] = useState("");
  // const [description, setDescription] = useState("");
  // const [metaTags,setMetaTags] = useState("")
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(`${BASE_URL}/submitlisting/get-properties`);
  //       console.log("response : ", response.data.result)
  //       if (response.data.result.title) {
  //         setTitleSeo(response.data.result.title)
  //         setDescription(response.data.result.slug)
  //         setMetaTags(response.data.result.metaTag)
  //       }
  //     } catch (error) {
  //       console.error('Error fetching data:', error.message);
  //     }
  //   };

  //   fetchData();
  // }, []);


  return (
    <Fragment>
      <Helmet>
        <title>Buy Home For Less | Submit Listing</title>
        <meta name="description" content="#" />
      </Helmet>
      <Header />
      <Breadcrumb breadcrumb={{ pagename: t("submitListing") }} />
      <Content />
      <Footer />
    </Fragment>
  );
};

export default Submitlisting;
