import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import axios from "axios";
import CopyToClipboard from "react-copy-to-clipboard";
import "./Banner.css";
import { Container, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import BASE_URL from "../../../baseUrl";
import $ from "jquery";
import { Image, Modal, Button } from "antd";
import landSize from "../../../assets/img/land.jpeg";
// import { Image } from "antd";
// import landSize from "../../../assets/img/land.jpeg";
import guestroom from "../../../assets/img/guestroom.png";
import bed from "../../../assets/img/bedroom.png";
import bath from "../../../assets/img/bathoom.png";
import area from "../../../assets/img/Land_size.jpeg";
import meetingroom from "../../../assets/img/meetingroom.png";
import Sauna from "../../../assets/img/Sauna.png";
import diningroom from "../../../assets/img/diningroom.png";
import maidroom from "../../../assets/img/maidroom.png";
import livingroom from "../../../assets/img/livingroom.png";
import gym from "../../../assets/img/gym.png";
import calender from "../../../assets/img/calender.png";
import { Link } from "react-scroll";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Banner = () => {
  const params = useParams();
  const custome = useRef();
  const currentURL = window.location.href;
  const [data, setData] = useState({});
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [flg, setflg] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [thbToEur, setThbToEur] = useState();
  const [thbToUsd, setThbToUsd] = useState();
  const [updatePrice, setUpdatePrice] = useState(null);
  const [updateCurrency, setUpdateCurrency] = useState(null);
  // const toast = useToast();
  // const notify = (msg) => toast(msg);
  const copiedLink = () => {
    // setflg(() => true);
    // if (flg) {
    return toast.success("Link copied");
    // }
  };

  useEffect(() => {
    axios
      .get(`${BASE_URL}/submitlisting/submit/${params.id}`)
      .then((res) => {
        // console.log(res?.data?.result);
        setData(res.data.result);
      })
      .catch((err) => {
        setError(err.message);
      });
    fetchExchangeRates();
  }, []);

  const next = () => {
    custome.current.slickNext();
  };
  const previous = () => {
    custome.current.slickPrev();
  };

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    fade: true,
    speed: 10,
    autoplay: true,
  };
  function popup() {
    var items = data?.Gallery?.picture?.map((name) => {
      return {
        src: `${BASE_URL}/${name}`,
      };
    });

    $(".gallery-thumb").magnificPopup({
      type: "image",
      gallery: {
        enabled: true,
      },
      tCounter: '<span class="mfp-counter">%curr% of %total%</span>', // markup of counter
      items: items,
    });
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const imagesettings = {
    // dots: true,
    // infinite: true,
    // speed: 500,
    // slidesToShow: 1,
    // slidesToScroll: 1,
    // nextArrow: null,
    // prevArrow: null,
    // className: 'custom-slider'
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    nextArrow: false,
    prevArrow: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 1500,
          dots: false,
          nextArrow: false,
          prevArrow: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 1500,
          nextArrow: false,
          prevArrow: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 1500,
          nextArrow: null,
          prevArrow: null,
        },
      },
    ],
  };

  const convertCurrency = (rateStr, amountInThb) => {
    const rate = JSON.parse(rateStr);
    const convertPrice = (amountInThb * rate?.amount).toFixed(2);
    setUpdatePrice(convertPrice);
    setUpdateCurrency(rate.sign);
  };
  // Fastforex Api
  // const fetchExchangeRates = async () => {
  //   try {
  //     const response = await fetch(
  //       `https://api.fastforex.io/fetch-all?from=THB&api_key=449c6f874f1a3ad367ce98e7`
  //     );
  //     const data = await response.json();
  //     setThbToEur(JSON.stringify({ amount: data.results.EUR, sign: "€" }));
  //     setThbToUsd(JSON.stringify({ amount: data.results.USD, sign: "$" }));
  //   } catch (err) {
  //     setError(err.message);
  //   }
  // };

  // Exchange-Rate-Api
  const fetchExchangeRates = async () => {
    try {
      const response = await fetch(
        `https://v6.exchangerate-api.com/v6/449c6f874f1a3ad367ce98e7/latest/THB`
      );
      const data = await response.json();
      setThbToEur(
        JSON.stringify({ amount: data.conversion_rates.EUR, sign: "€" })
      );
      setThbToUsd(
        JSON.stringify({ amount: data.conversion_rates.USD, sign: "$" })
      );
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div
      className=" banner-2 slider-no-padding mb-5 relative "
      // style={{ padding: "2%" }}
    >
      <div className="banner-item">
        <ToastContainer />
        <div
          className="bg-cover bg-center dark-overlay "
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/subheader.jpg)`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: 400,
          }}
        >
          <div
            className="d-flex  ms-4  absolute bottom-0 w-[70%] md:w-[30%]"
            // style={{ width: "30%" }}
          >
            <Link
              to="#"
              className="btn-custom secondary w-100 justify-between flex flex-wrap w-100"
            >
              {data?.BasicInformation?.listingType == "Rent" && (
                <h6
                  style={{
                    display: "flex",
                    // justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "2px",
                    color: "black",
                  }}
                >
                  <span className="mr-1">{" Rental Price : "} </span>
                  <span>
                    {data?.BasicInformation?.rentalPrice
                      ? (updateCurrency
                          ? updateCurrency
                          : data?.BasicInformation?.currency) +
                        " " +
                        (updatePrice
                          ? updatePrice
                          : data?.BasicInformation?.rentalPrice)
                      : ""}
                  </span>
                </h6>
              )}
              {data?.BasicInformation?.listingType !== "Rent" && (
                <h5
                  style={{
                    display: "flex",
                    // justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "2px",
                    color: "black",
                  }}
                >
                  <span className="mr-1">{" Monthly Mortage : "} </span>
                  <span>
                    {/* {console.log("object",updatePrice,updatePrice?updatePrice:data?.BasicInformation?.mortage)} */}
                    {data?.BasicInformation?.mortage
                      ? (updateCurrency
                          ? updateCurrency
                          : data?.BasicInformation?.mortagecurrency || "$") +
                        " " +
                        (updatePrice
                          ? updatePrice
                          : data?.BasicInformation?.mortage)
                      : ""}
                  </span>
                </h5>
              )}
              {/* {data?.BasicInformation?.currency == "฿" ?
                <select className="mx-3 btn-custom secondary" onChange={(e)=>{
                  convertCurrency(e.target.value,data?.BasicInformation?.mortage)
                }}>
                  <option>Select currrncy</option>
                  <option value={thbToUsd}>USD</option>
                  <option value={thbToEur}>EURO</option>
                </select>:""} */}
              {/* {data?.BasicInformation?.currency == "฿" ? (
              data?.BasicInformation?.listingType == "Rent" ? (
                <select
                  className="mx-2 btn-custom secondary"
                  onChange={(e) => {
                    if (e.target.value == false) {
                      return setUpdatePrice(false);
                    }
                    convertCurrency(
                      e.target.value,
                      data?.BasicInformation?.rentalPrice
                    );
                  }}
                >
              
                  <option value={thbToUsd}>USD</option>
                  <option value={thbToEur}>EURO</option>
                </select>
              ) : (
                <select
                  className="mx-3 btn-custom secondary"
                  onChange={(e) => {
                    convertCurrency(
                      e.target.value,
                      data?.BasicInformation?.mortage
                    );
                  }}
                >
                  <option value={thbToUsd}>USD</option>
                  <option value={thbToEur}>EURO</option>
                </select>
              )
            ) : (
              ""
            )} */}
              {data?.BasicInformation?.currency == "฿" && (
                <>
                  {/* Rent Currency Selector */}
                  {data?.BasicInformation?.listingType === "Rent" && (
                    <select
                      className="mx-2 btn-custom secondary"
                      onChange={(e) => {
                        if (e.target.value === "false") {
                          setUpdatePrice(false);
                        } else {
                          convertCurrency(
                            e.target.value,
                            data?.BasicInformation?.rentalPrice
                          );
                        }
                      }}
                    >
                      <option value={thbToUsd}>USD</option>
                      <option value={thbToEur}>EURO</option>
                    </select>
                  )} 
                </>
              )}
              {
                data?.BasicInformation?.mortagecurrency == "฿" && (
                  data?.BasicInformation?.listingType === "Sell" && (
                    <select
                      className="mx-2 btn-custom secondary"
                      onChange={(e) => {
                        if (e.target.value === "false") {
                          setUpdatePrice(false);
                        } else {
                          convertCurrency(
                            e.target.value,
                            data?.BasicInformation?.mortage
                          );
                        }
                      }}
                    >
                      <option value={thbToUsd}>USD</option>
                      <option value={thbToEur}>EURO</option>
                    </select>
                  )
                )
              }
            </Link>
          </div>
        </div>
        {data.BasicInformation ? (
          <div className="acr-listing-details row">
            <div className="col-md-12 col-lg-3">
              <div className="acr-listing-nav1 "></div>

              <div className="acr-listing-section-body">
                <div className="">
                  {data?.BasicInformation.listingType !== "Rent" ? (
                    <h5 className="fs-4">
                      Selling Price : {data?.BasicInformation.currency}
                      {data.BasicInformation.price}{" "}
                    </h5>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  {data?.Gallery?.file?.length > 1 ? (
                    <div
                      style={{
                        width: "100%",
                        maxWidth: "300px",
                        height: "200px",
                      }}
                    >
                      <Slider {...imagesettings}>
                        {data?.Gallery?.file.map((image, index) => (
                          <div key={index} style={{ height: "50%" }}>
                            <Image
                              src={image}
                              style={{
                                aspectRatio: "25/16",
                                cursor: "pointer",
                                width: "100%",
                              }}
                              alt={`Slide ${index}`}
                              className="property-slider-image"
                            />
                          </div>
                        ))}
                      </Slider>
                    </div>
                  ) : (
                    <div style={{ width: "100%", maxWidth: "300px" }}>
                      <Image
                        src={data?.Gallery?.file}
                        style={{
                          aspectRatio: "20/16",
                          cursor: "pointer",
                          width: "20vh",
                        }}
                        alt="Slide"
                      />
                    </div>
                  )}
                </div>
                {/* <h5>Thumbnail image</h5> */}
                {/* <div className="acr-listing-section-price d-flex flex-wrap">
                {data?.Gallery?.file?.length > 1 ? (
                  <div style={{ width: '100%', maxWidth: '300px' }}>
                    <Slider {...imagesettings}>
                      {data?.Gallery?.file.map((image, index) => (
                        <div key={index} style={{ height: '50%' }}>
                          <Image
                            src={image}
                            style={{ aspectRatio: "25/16", cursor: 'pointer', width: '50%' }}
                            alt={`Slide ${index}`}
                            className="property-slider-image"
                          />
                        </div>
                      ))}
                    </Slider>
                  </div>
                ) : (
                  <div style={{ width: '100%', maxWidth: '300px' }}>
                    <Image
                      src={data?.Gallery?.file}
                      style={{ aspectRatio: "20/16", cursor: 'pointer', width: '20vh' }}
                      alt="Slide"
                    />
                  </div>
                )}
                {data?.Gallery?.picture.slice(0,2)?.map((item, index) => {
                  return (
                    <div key={index} className="listing-list gallery-thumb">
                      <div className="listing-thumbnail mr-2 ">
                      <button
                      // onClick={() => popup()}
                      className="gallery-thumb "
                      style={{ border: "none", background: "none" ,width:"100px", height:"100px" }}
                    >
                      <Image
                        width={100}
                        // height={100}
                        src={`${item}`}
                        style={{objectFit:"cover"}}
                      />
                    </button>
                      </div>
                    </div>
                  );
                })}
              </div> */}
              </div>
            </div>
            <div className=" col-md-12 col-lg-7 acr-listing-section col-md-7 col-sm-12 mb-2">
              <div className="acr-listing-section-body row">
                <div className="col-md-12">
                  <h4>{data.BasicInformation.type}</h4>
                  <div className="acr-listing-icons col-md-12">
                    <div className="acr-listing-icon mb-2 col-md-4">
                      <div className="flex justify-between w-100">
                        <div className="flex items-center">
                          <span className="mr-2">
                            <img width="20px" height="30px" src={bed} />
                          </span>
                          <span>{t("Beds")}</span>
                        </div>
                        <span className="acr-listing-icon-value">
                          {data.Details.beds?.toString().length > 5
                            ? data.Details.beds.toString().slice(0, 5) + "..."
                            : data.Details.beds
                            ? data.Details.beds
                            : "---"}
                        </span>
                      </div>
                    </div>
                    <div className="acr-listing-icon mb-2 col-md-4">
                      <div className="flex justify-between w-100">
                        <div className="flex">
                          <i className="fas fa-parking fs-5" />{" "}
                          {/* Parking icon */}
                          <span>{t("Parking")}</span>
                        </div>
                        <span className="acr-listing-icon-value">
                          {data.Details?.parking
                            ? data.Details?.parking
                            : "---"}
                        </span>
                      </div>
                    </div>
                    <div className="acr-listing-icon mb-2 col-md-3">
                      <div className="flex justify-between w-100">
                        <div className="w-100 flex">
                          <span className="mr-2">
                            <img width="20px" height="20px" src={guestroom} />
                          </span>
                          <span>{t("Guest room")}</span>
                        </div>
                        <span className="acr-listing-icon-value">
                          {data.Details.guestRoom === "true"
                            ? "Yes"
                            : data.Details.guestRoom === "false"
                            ? "No"
                            : "--"}
                        </span>
                      </div>
                    </div>

                    {(data?.BasicInformation?.type == "Condo" || data?.BasicInformation?.type == "Apartment")? (
                          <div className="acr-listing-icon mb-2 col-md-4">
                          <div className="flex justify-between w-100">
                            <div className="flex items-center">
                              <i
                                className="fas fa-warehouse "
                                style={{ fontSize: "12px" }}
                              />
                              <span>{t("Room Floor")}</span>
                            </div>
                            <span className="acr-listing-icon-value">
                              {data.BasicInformation?.roomfloor?.toString().length > 5
                                ? data.BasicInformation?.roomfloor.toString().slice(0, 5) + "..."
                                : data.BasicInformation?.roomfloor
                                ? data.BasicInformation?.roomfloor
                                : "---"}
                            </span>
                          </div>
                        </div>
                    ):
                    <div className="acr-listing-icon mb-2 col-md-4">
                      <div className="flex justify-between w-100">
                        <div className="flex items-center">
                          <i
                            className="fas fa-warehouse "
                            style={{ fontSize: "12px" }}
                          />
                          <span>{t("Floor")}</span>
                        </div>
                        <span className="acr-listing-icon-value">
                          {data.Details?.story?.toString().length > 5
                            ? data.Details.story.toString().slice(0, 5) + "..."
                            : data.Details?.story
                            ? data.Details?.story
                            : "---"}
                        </span>
                      </div>
                    </div>
                    }
                    <div className="acr-listing-icon mb-2 col-md-4">
                      <div className="flex justify-between w-100">
                        <div className="flex items-center">
                          <span className="mr-2">
                            <img width="20px" height="20px" src={landSize} />
                          </span>
                          <span>{t("Land size")}</span>
                        </div>
                        <span className="acr-listing-icon-value">
                          {data.BasicInformation?.land
                            ? data.BasicInformation?.land
                            : "---"}
                        </span>
                      </div>
                    </div>
                    
                    <div className="acr-listing-icon mb-2 col-md-3">
                      <div className="flex justify-between w-100">
                        <div className="flex items-center">
                          <span className="mr-2">
                            <img width="20px" height="25px" src={bath} />
                          </span>
                          <span>{t("Bathrooms")}</span>
                        </div>
                        <span className="acr-listing-icon-value">
                          {data.Details.bathrooms?.toString().length > 5
                            ? data.Details.bathrooms.toString().slice(0, 5) +
                              "..."
                            : data.Details.bathrooms
                            ? data.Details.bathrooms
                            : "---"}
                        </span>
                      </div>
                    </div>

                    <div className="acr-listing-icon mb-2 col-md-4">
                      <div className="flex justify-between w-100">
                        <div className="flex">
                          <span className="mr-2">
                            <img width="20px" height="20px" src={livingroom} />
                          </span>
                          <span>{t("Living Room")}</span>
                        </div>
                        <span className="acr-listing-icon-value">
                          {data.Details.living || "---"}
                        </span>
                      </div>
                    </div>
                    <div className="acr-listing-icon mb-2 col-md-4">
                      <div className="flex justify-between w-100">
                        <div className="flex">
                          <span className="mr-2">
                            <img width="20px" height="20px" src={diningroom} />
                          </span>
                          <span>{t("Dining Room")}</span>
                        </div>
                        <span className="acr-listing-icon-value">
                          {data.Details?.dining?.toString().length > 5
                            ? data.Details.dining.toString().slice(0, 5) + "..."
                            : data.Details?.dining
                            ? data.Details?.dining
                            : "---"}
                        </span>
                      </div>
                    </div>
                    <div className="acr-listing-icon mb-2 col-md-3">
                      <div className="flex justify-between w-100">
                        <div className="flex">
                          <span className="mr-2">
                            <img width="20px" height="20px" src={Sauna} />
                          </span>
                          <span>{t("Sauna")}</span>
                        </div>
                        <span className="acr-listing-icon-value">
                          {data.Details.sauna === "true"
                            ? "Yes"
                            : data.Details.sauna == "false"
                            ? "No"
                            : "---"}
                        </span>
                      </div>
                    </div>
                    <div className="acr-listing-icon mb-2 col-md-4">
                      <div className="flex justify-between w-100">
                        <div className="flex">
                          <span className="mr-2">
                            <img width="20px" height="20px" src={maidroom} />
                          </span>
                          <span>{t("Maid Room")}</span>
                        </div>
                        <span className="acr-listing-icon-value">
                          {data.Details.maidRoom === "true"
                            ? "Yes"
                            : data.Details.maidRoom == "false"
                            ? "No"
                            : "---"}
                        </span>
                      </div>
                    </div>
                    {/* <div className="acr-listing-icon mb-1">
      <span className="mr-2"><img width="20px" height="20px" src={kitchen}/></span>
      <span>{t("Kitchens")}</span>
      <span className="acr-listing-icon-value">
        {data.Details?.kitchens}
      </span>
    </div> */}
                    <div className="acr-listing-icon mb-2 col-md-4">
                      <div className="flex w-100 justify-between">
                        <div className="flex">
                          <span className="mr-2">
                            <img width="20px" height="20px" src={meetingroom} />
                          </span>
                          <span>{t("Meeting room")}</span>
                        </div>
                        <span className="acr-listing-icon-value">
                          {data.Details.meetingRoom == "true"
                            ? "Yes"
                            : data.Details.meetingRoom === "false"
                            ? "No"
                            : "---"}
                        </span>
                      </div>
                    </div>
                    <div className="acr-listing-icon mb-2 col-md-3">
                      <div className="flex justify-between w-100">
                        <div className="flex">
                          <span className="mr-2">
                            <img width="20px" height="20px" src={gym} />
                          </span>
                          <span>{t("Gym")}</span>
                        </div>
                        <span className="acr-listing-icon-value">
                          {data.PropertyAmenity.Gym == "true"
                            ? "Yes"
                            : data.PropertyAmenity.Gym == "false"
                            ? "No"
                            : "---"}
                        </span>
                      </div>
                    </div>
                    <div className="acr-listing-icon mb-2 col-md-4">
                      <div className="flex justify-between w-100">
                        <div className="flex items-center">
                          <span className="mr-2">
                            <img width="20px" height="20px" src={calender} />
                          </span>
                          <span>{t("Year Built")}</span>
                        </div>
                        <span className="acr-listing-icon-value">
                          {data.Details?.built?.toString().length > 4
                            ? data.Details.built.toString().slice(0, 4) + "..."
                            : data.Details?.built
                            ? data.Details?.built
                            : "---"}
                        </span>
                      </div>
                    </div>
                    {(data?.BasicInformation?.type == "Condo" || data?.BasicInformation?.type == "Apartment") ?(
                       <div className="acr-listing-icon mb-2 col-md-4">
                       <div className="flex justify-between w-100">
                         <div className="flex items-center">
                           <span className="mr-2">
                             <img width="20px" height="20px" src={guestroom} />
                           </span>
                           <span>{t("Room#")}</span>
                         </div>
                         <span className="acr-listing-icon-value">
                           {data.BasicInformation?.roomNo?.toString().length > 4
                             ? data.BasicInformation.roomNo.toString().slice(0, 4) + "..."
                             : data.BasicInformation?.roomNo
                             ? data.BasicInformation?.roomNo
                             : "---"}
                         </span>
                       </div>
                     </div>
                    ):null}
                     {(data?.BasicInformation?.type == "Condo" || data?.BasicInformation?.type == "Apartment" || data?.BasicInformation?.type == "Flats")?(
                    <div className="acr-listing-icon mb-2 col-md-3">
                      <div className="flex justify-between w-100">
                        <div className="flex">
                          <span className="mr-2">
                            <img width="20px" height="20px" src={area} />
                          </span>
                          <span>{t("ROOM AREA")}</span>
                        </div>
                        <span className="acr-listing-icon-value">
                          {data.BasicInformation.space
                            ? data.BasicInformation.space + "SQM"
                            : "---"}
                        </span>
                      </div>
                    </div>
                    ):null}
                  </div>
                </div>
                {/* <p>{data.BasicInformation.description}</p> */}
              </div>
            </div>
            <div className="acr-listing-section col-md-2 px-0 mb-2">
              <div className="acr-listing-controls">
                <div onClick={copiedLink}>
                  <CopyToClipboard text={currentURL}>
                    <Link to="#" className="acr-listing-control">
                      <i onClick={copiedLink} className="flaticon-share" />
                    </Link>
                  </CopyToClipboard>
                </div>
                {/* <Link to="#" className="acr-listing-control">
                <i className="flaticon-star" />
              </Link> */}
                <Link
                  to="schedule"
                  smooth={true}
                  duration={10}
                  className="acr-schedule-tour acr-listing-control"
                >
                  <i className="flaticon-event" />
                  <span>{t("Schedule Link tour")}</span>
                </Link>
              </div>
              <div className="acr-listing-section-body">
                <div className="acr-listing-meta">
                  <div className="row">
                    <div className="col-lg-6 col-md-3 col-sm-3">
                      <div className="acr-listing-meta-item">
                        <span>{t("Type")}</span>
                        <p>{data.BasicInformation.type}</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-3 col-sm-3">
                      <div className="acr-listing-meta-item">
                        <span>{t("View")}</span>
                        <p>
                          {data.Details.view == "Select View"
                            ? "---"
                            : data.Details.view}
                        </p>
                      </div>
                    </div>
                    {data.Details.lotsize !== "undefined" ? (
                      <div className="col-lg-6 col-md-3 col-sm-3">
                        <div className="acr-listing-meta-item">
                          <span>{t("lotSize")}</span>
                          <p>
                            {data.Details.lotsize?.toString().length > 10
                              ? data.Details.lotsize.toString().slice(0, 10) +
                                "..."
                              : data.Details.lotsize
                              ? data.Details.lotsize
                              : "---"}
                          </p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="col-lg-6 col-md-3 col-sm-3">
                      <div className="acr-listing-meta-item">
                        <span>{t("Condition")}</span>
                        <p>
                          {data.Details.condition == ""
                            ? "---"
                            : data.Details.condition
                            ? data.Details.condition
                            : "---"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <span>{error}</span>
        )}
      </div>
    </div>
  );
};

export default Banner;
