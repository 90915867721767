// import React, { Component } from 'react';
// import { Link } from 'react-router-dom';

// class Bluecta extends Component {
//     render() {
//         return (
//             <div className="container">
//                 <div className="cta cta-1">
//                     <div className="row align-items-center">
//                         <div className="col-lg-4">
//                             <h3>Need More Information On Real Estate?</h3>
//                         </div>
//                         <div className="offset-lg-1 col-lg-7">
//                             <p>
//                                 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
//                                 when an unknown printer took a galley of type and scrambled.
//                             </p>
//                             <Link to="/contact" className="btn-custom-2 light">Find Out More</Link>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         );
//     }
// }

// export default Bluecta;

/////////////////////////////////////////////////   Functional Component  /////////////////////////////////////////////////////////

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import BASE_URL from "../../baseUrl";



const Bluecta = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/Banner/banner`);
        if (response.data.result.title) {
          setName(response.data.result.title)
          setDescription(response.data.result.description)
        }
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchData();
  }, []);
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  return (
    <div className="container">
      <div className="cta cta-1">
        <div className="row align-items-center">
          <div className="col-lg-4">
            <h3>{t(name)}</h3>
          </div>
          <div className="offset-lg-1 col-lg-7">
            <p>
              {t(description)}
            </p>
            <Link onClick={handleClick} to="/buy" className="btn-custom-2 light">
              {t('FindOutMore')}

            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bluecta;
