const Data = {
  locationlist: [
    "Any Location",
    
    "Bangkok",
    "Karachi Division",
  ],
  statuslist: [
    // "Any Status",
    // "Home",
    // "Condo",

    // "Hotel",
    // "Apartment",
    // "Resort",
    // "Land",
    "For Sale, Sold",
    "New Addition",
    "Featured, Leased"
  ],
  type:[
    "Any Type",
    "Residential",
    "Condo",
    "Apartment",
    "Resort",
    "Land",
    "Hotel"

  ],
  pricerangelist: [
    { res: "Any Range", value: "0" },
    // { res: "$60k - $80k", value: ["60", "80"] },
    // { res: "$80k - $100k", value: ["80", "100"] },
    // { res: "$100k - $120k", value: ["100", "120"] },
    // { res: "$120k - $140k", value: ["120", "140"] },
    // { res: "$140k - $160k", value: ["140", "160"] },
    // { res: "$160k - $180k", value: ["160", "180"] },
    // { res: "$180k - $200k", value: ["180", "200"] },
    { res: "$434", value: ["434"] },
    { res: "$34", value: ["34"] },
    { res: "$22", value: ["22"] },
  ],
  bedslist: ["Any amount", "34"],
  bathroomslist: ["Any amount", "34"],
  environmentlist: [
    "Any Environment",
    "Beach",
    "Mountain",
    "Rural hills",
    "Urban",
    "Cities",
  ],
  housearealist: [
    "Any House Area",
    "100-200",
    "200-300",
    "300-400",
    "400-500",
    "500+",
  ],
  landarealist: [
    "Any Land Area",
    "100-200",
    "200-300",
    "300-400",
    "400-500",
    "500+",
  ],
  // diameterlist: [
  //   "Any Range",
  //   "Within 2 miles",
  //   "Within 4 miles",
  //   "Within 6 miles",
  //   "Within 8 miles",
  //   "Within 10 miles",
  //   "Within 22 miles",
  // ],
  floorlist: ["Any floor", "1st", "2nd", "3rd", "4th", "5th"],
  typelist: ["Any floor", "1st", "2nd", "3rd", "4th", "5th"],
  sortbylist: [
    "Any",
    "Top Selling",
    "Most Popular",
    "Price: High - Low",
    "Price: Low - High",
    "Number of baths",
    "Number of beds",
  ],
};

export default Data;
