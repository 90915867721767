import React from "react";
import Banner from "./Banner";
import Categories from "./Categories";
import Listings from "../hometwo/Listings";
import Blockcta from "../../layouts/Blockcta";
import Whyus from "../homethree/Whyus";
import Toplistings from "../home/Toplistings";
import Recentlistings from "./Recentlistings";
import Latestblog from "../home/Latestblog";
import Faqs from "./Faqs";
import Agentsslider from "../home/Agentsslider";
import Bluecta from "../../layouts/Bluecta";
import Testimonials from "../home/Testimonials";
import Calculator from "../../layouts/Calculator";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import BASE_URL from "../../../baseUrl";
import { RxCross2 } from "react-icons/rx";
// Here it is For Promotion
const Content = () => {
  const { t } = useTranslation();

  // useEffect(() => {
  //   axios
  //     .get(`${BASE_URL}/admin/popup`)
  //     .then((res) => {
  //       const data = res.data.data;
  //       const fileType = data.fileType; // Assuming your backend returns fileType (image/video)
  //       const fileUrl = data.file; // URL of the file
  //       let htmlContent = "";
  //       if (fileType.startsWith("image/")) {
  //         htmlContent = `<img src="${fileUrl}" alt="Promotion" style="width: 100%; height: 30vh;" />`;
  //       } else if (fileType.startsWith("video/")) {
  //         htmlContent = `
  //             <video controls style="width: 100%; height: auto;">
  //               <source src="${fileUrl}" type="video/mp4" />
  //               Your browser does not support the video tag.
  //             </video>`;
  //       }

  //       Swal.fire({
  //         customClass: {
  //           confirmButton: 'custom-confirm-button',
  //           popup: 'custom-popup',
  //         },
  //         confirmButtonText: '<span style="display: flex; align-items: center;"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16"> <path d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zM4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 1 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/></svg></span>',
  //         html: htmlContent,
  //         showClass: {
  //           popup: 'animate__animated animate__fadeInDown',
  //         },
  //         hideClass: {
  //           popup: 'animate__animated animate__fadeOutUp',
  //         },
  //       });
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }, []);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/admin/popup`)
      .then((res) => {
        const data = res.data.data;
        const fileType = data.fileType; // Assuming your backend returns fileType (image/video)
        const fileUrl = data.file; // URL of the file
          let htmlContent = "";
    
          if (fileType.startsWith("image/")) {
            htmlContent = `<img src="${fileUrl}" alt="Promotion" style="width: 100%; height: 100%;" />`;
          } else if (fileType.startsWith("video/")) {
            htmlContent = `
            <div style="position:relative; width: 100%; height: 100%; object-fit: cover;">
            <video controls style="width: 100%; height: 100%; object-fit: cover;">
              <source src="${fileUrl}" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>`;
          }
    
          Swal.fire({
            customClass: {
              confirmButton: 'custom-confirm-button',
              popup: 'custom-popup',
            },
            // confirmButtonText: '<span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16"> <path d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zM4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 1 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/></svg></span>',
            html: htmlContent,
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }, []);
    

  return (
    <div>
      <Banner />
      <div className="marginHomeContent">
        <Categories />
        <div className="section section-padding pt-0"></div>
        <div className="w-100">
          <div className="container Advertisement-img"></div>
        </div>
        <div className="acr-footer footer-2">
          <Whyus />
        </div>

        <Recentlistings />
        <Blockcta />
        <Toplistings />
        <Latestblog />
        <Testimonials />
        <div className="section pt-0 pb-3">
          <Faqs />
        </div>

        <Agentsslider />
        <div className="pb-5 pt-0">
          <Bluecta />
        </div>
        <div className="container">
          <div className="col-12 myclass">
            <div className="w-full md:w-1/2">
              <h5>{t("Mortgage Calculator")}</h5>
              <Calculator />
            </div>
            <div className="myImages hidden md:block">
              <img
                className="mortgageImage"
                src="http://androthemes.com/themes/react/acres/assets/img/blog/2.jpg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Content;
