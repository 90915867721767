import { createContext, useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const languageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const [langName, setLangName] = useState("English");

    const { i18n } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage(langName);
    }, [langName, i18n]);

    const handleLanguageChange = (value) => {
        setLangName(value);
    };

    return (
        <languageContext.Provider
            value={{ langName, handleLanguageChange }}
        >
            {children}
        </languageContext.Provider>
    );
};

export const useLanguage = () => {
    return useContext(languageContext);
};
