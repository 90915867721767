import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import BASE_URL from "../../../baseUrl";


var PriceFilter = {};

const ListingFilter = ({ getData }) => {
  // const [advanceSearch, setAdvanceSearch] = useState(true);

  const [typeList, setTypeList] = useState([]);
  const [location, setLocation] = useState("");
  const [buy, setBuy] = useState("");
  const [type, setType] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [beds, setBeds] = useState("");
  const [bath, setbath] = useState("");
  const [buildarea, setbuildarea] = useState("");
  const [landarea, setlandarea] = useState("");
  const [loading, setLoading] = useState(false);


  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(`${BASE_URL}/admin/get-categories`)
      .then((res) => {
        setTypeList(res.data.result);

      });
  }, []);

  const filterData = () => {
    setLoading(true);

    // Create PriceFilter object
    const PriceFilter = {
      location: location,
      buy: buy === "any" ? "any" : buy==="buy"?'sell':buy,
      type: type === "any" ? "any" : type,
      minPrice: minPrice,
      maxPrice: maxPrice,
      beds: beds,
      landarea: landarea,
      bath: bath,
      buildarea: buildarea,
    };
    // console.log(PriceFilter);
    // Check if any field in PriceFilter is empty
    const anyFieldEmpty = Object.values(PriceFilter).some(value => value === "");

    // if (anyFieldEmpty) {
    //   // If any field is empty, do not make the API request
    //   setLoading(false);
    //   toast.error("please fill all fields", {
    //     icon: '⚠️',
    //     duration: 2000,
    //     position: "top-right", // Changed to top-right
    //     style: {
    //       marginTop: '220px',
    //       // Pushing the toast 70px down from the top
    //       background: '#FC3206',
    //       color: "#fff",
    //       // Add more properties if needed
    //     },
    //   });
    //   return;
    // }

    // All fields have values, so make the API request
    getData(PriceFilter);

    axios
      .post(`${BASE_URL}/SEO/searchHome`, {
        location: PriceFilter.location,
        category: PriceFilter.buy,
        type: PriceFilter.type,
        minPrice: PriceFilter.minPrice,
        maxPrice: PriceFilter.maxPrice,
        beds: PriceFilter.beds,
        landarea: PriceFilter.landarea,
        bathrooms: PriceFilter.bath,
        buildingArea: PriceFilter.buildarea,
      })
      .then((response) => {
        // console.log('Data posted successfully');
        console.log(PriceFilter,"akakak")

        if (response?.data?.success === true || response?.data?.success === "true") {
          // console.log(response?.data?.result[0], "aagayay");
          if (response?.data?.result[0]?.length === 0) {
            toast.error("There Is Such No Result", {
              icon: '⚠️',
              duration: 2000,
              position: "top-right",
              style: {
                marginTop: '220px',
                background: '#FC3206',
                color: "#fff",
              },
            });
          } else {
            const category = response?.data.result[0].map((x) => x.category)
            // console.log(category,"category");
            if (category?.length != 0) {
              const searchedData = {
                data: response?.data.result[0],
                msg: "location data"
              }
              // console.log(searchedData);

              navigate(`/property/${PriceFilter?.type === ""?"Searching":PriceFilter?.type}/${PriceFilter?.buy === ""?"all":PriceFilter?.buy}`, {
                state: searchedData
              })
            }
          }
        }


      })
      .catch((error) => {
        // console.error('Error:', error);
      })
      .finally(() => {
        // Set loading back to false when the API request is complete
        setLoading(false);
      });
  };



  return (
    <div className="mt-8 mb-16">
      {/* <ToastContainer /> */}
      <div className="flex justify-center">
        <div className="w-11/12 min-md:w-8/12 md:9-12">
          <div className=" rounded-lg px-4 pt-4">

            <Toaster position="" />
            <div className="row">
              <div className="col-lg-4 col-md-4 grid-custom mb-3">
                <input
                  type="text"
                  name="location"
                  className="col-md-6 form-control"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  placeholder={t("searchPlaceholder")}
                  style={{ fontWeight: "bold" }}
                />
              </div>
              <div className="col-lg-2 col-md-2 grid-custom mb-3 ">
                <select
                  className="col-md-2 form-control"
                  name="Status"
                  style={{ fontWeight: "bold" }}
                  value={buy}
                  onChange={(e) => setBuy(e.target.value)}
                >
                  <option value="" disabled hidden>
                    {t("buyRentSell")}
                  </option>
                  <option className="form-control" value="any">
                    {t("any")}
                  </option>
                  <option className="form-control" value="buy">
                    {t("buy")}
                  </option>
                  <option className="form-control" value="rent">
                    {t("rent")}
                  </option>
                  {/* <option className="form-control" value="sell">
                    {t("sell")}
                  </option> */}
                </select>
              </div>
              <div className="col-lg-3 col-md-3 grid-custom">
                <select
                  className="col-md-2 form-control"
                  name="type"
                  style={{ fontWeight: "bold", marginBottom: "15px" }}
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                >
                  <option value="" hidden>
                    {t("propertyType")}
                  </option>
                  <option className="form-control" value="any">
                    {t("anyType")}
                  </option>
                  {typeList?.map((res, key) => (
                    <option
                      className="form-control"
                      value={res.name}
                      key={"status" + key}
                    >
                      {res.name}
                    </option>
                  ))}
                  {/* <option className="form-control" value="Residential">
                    {t("Residential")}
                  </option>
                  <option className="form-control" value="Condo">
                    {t("Condo")}
                  </option>
                  <option className="form-control" value="Apartment">
                    {t("Apartment")}
                  </option>
                  <option className="form-control" value="Resort">
                    {t("Resort")}
                  </option>
                  <option className="form-control" value="Land">
                    {t("Land")}
                  </option>
                  <option className="form-control" value="Hotel">
                    {t("Hotel")}
                  </option> */}
                </select>
              </div>
              <div className="col-lg-2 col-md-2 grid-custom mb-3">
                <button
                  onClick={() => filterData()}
                  className="btn-block form-control col-md-2"
                  style={{
                    borderRadius: 0,
                    backgroundColor: loading ? "#eb444d" : "#fc3206",
                    border: "none",
                    color: "white",
                    fontSize: "19px",

                  }}
                  disabled={loading}
                >
                  {loading ? t('Searching...') : t("searchButton")}
                </button>
              </div>
            </div>
            <div className="row" style={{ alignItems: "center" }}>
              <div className="col-md-2 grid-custom2">
                <input
                  className="form-control2"
                  type="text"
                  value={minPrice}
                  onChange={(e) => setMinPrice(e.target.value)}
                  placeholder={t("minPrice")}
                  style={{ fontWeight: "bold", marginBottom: "15px" }}
                />
              </div>
              <div className="col-md-2 grid-custom2">
                <input
                  className="form-control2"
                  type="text"
                  value={maxPrice}
                  onChange={(e) => setMaxPrice(e.target.value)}
                  placeholder={t("maxPrice")}
                  style={{ fontWeight: "bold", marginBottom: "15px" }}
                />
              </div>
              <div className="col-md-2 grid-custom2">
                <input
                  className="form-control2"
                  type="text"
                  value={beds}
                  onChange={(e) => setBeds(e.target.value)}
                  placeholder={t("beds")}
                  style={{ fontWeight: "bold", marginBottom: "15px" }}
                />
              </div>
              <div className="col-md-2 grid-custom2">
                <input
                  className="form-control2"
                  type="text"
                  value={bath}
                  onChange={(e) => setbath(e.target.value)}
                  placeholder={t("bath")}
                  style={{ fontWeight: "bold", marginBottom: "15px" }}
                />
              </div>
              <div className="col-md-2 grid-custom2">
                <input
                  className="form-control2"
                  type="text"
                  value={buildarea}
                  onChange={(e) => setbuildarea(e.target.value)}
                  placeholder={t("buildingArea")}
                  style={{ fontWeight: "bold", marginBottom: "15px" }}
                />
              </div>
              <div className="col-md-2 grid-custom2">
                <input
                  className="form-control2"
                  type="text"
                  value={landarea}
                  onChange={(e) => setlandarea(e.target.value)}
                  placeholder={t("landArea")}
                  style={{ fontWeight: "bold", marginBottom: "15px" }}
                />
              </div>
              <div className="col-md-3">
                {/* <input type="checkbox"></input>
                <span className="banner-search-checkbox">
                  Near transport station
                </span> */}      </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};
export default ListingFilter;
