import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import convertToBase64 from "../../../helper/convert";
import { ErrorMessage, Field, Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../../../baseUrl";

function Content() {
  const { t } = useTranslation();
  
  const [isLoading, setIsLoading] = useState(false); // Step 2
const navigate = useNavigate()

  const handleFileChange = (e, formik) => {
    const files = e.target.files;
    const base64Array = [];
  
    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
  
      reader.onload = (event) => {
        base64Array.push(event.target.result);
        // Update Formik field value
        formik.setFieldValue("images", base64Array);
      };
  
      reader.readAsDataURL(files[i]);
    }
  };
  
  const initialValues = {
    name: "",
    lastname: "",
    address: "",
    tumbol: "",
    city: "",
    states: "",
    country: "",
    lineid: "",
    whataAppNo: "",
    weChat: "",
    telNo: "",
    email: "",
    video: "",
    images: [],
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("Name is Required")),
    lastname: Yup.string().required(t("Last Name is Required")),
    address: Yup.string().required(t("Address is Required")),
    tumbol: Yup.string().required(t("Tumbol is Required")),
    city: Yup.string().required(t("City/Amphor is Required")),
    states: Yup.string().required(t("States/Province is Required")),
    country: Yup.string().required(t("Country is Required")),
    lineid: Yup.string().required(t("Line Id is Required")),
    whataAppNo: Yup.string()
      .matches(/^[0-9]+$/, t('WhatsApp number must only contain digits'))
      .min(10, t("WhatsApp number is too short"))
      .max(15, t('WhatsApp number is too long')).required(t("WhatsApp Number is Required")),
    weChat: Yup.string().required(t("Wechat is Required")),
    telNo: Yup.string().required(t("Tel Number is Required")).min(8, 'Too short').max(15, 'Too long'),
    email: Yup.string().email(t('Invalid email')).required(t(`Email is required`)),
    video: Yup.string().required(t("Video Url is Required")),
    images: Yup.array()
    .min(1, t("At least one image is required"))
    .required("File is required"),
  })





  const submitData = async (formik) => {
    setIsLoading(true); 
    formik.setTouched({
      name: true,
      lastname: true,
      address: true,
      tumbol: true,
      city: true,
      states: true,
      country: true,
      lineid: true,
      whataAppNo: true,
      weChat: true,
      telNo: true,
      email: true,
      video: true,
      images: true,
    });
    const formData = new FormData();
    formData.append("name", formik?.values?.name);
    formData.append("lastname", formik?.values?.lastname);
    formData.append("address", formik?.values?.address);
    formData.append("tumbol", formik?.values?.tumbol);
    formData.append("city", formik?.values?.city);
    formData.append("state", formik?.values?.states);
    formData.append("country", formik?.values?.country);
    formData.append("lineId", formik?.values?.lineid);
    formData.append("whatsApp", formik?.values?.whatsAppNo);
    formData.append("weChat", formik?.values?.weChat);
    formData.append("tel", formik?.values?.telNo);
    formData.append("email", formik?.values?.email);
    formData.append("uploadVideo", formik?.values?.video);
    
    // Append each image separately
    formik.values.images.forEach((binaryImage, index) => {
      formData.append(`uploadPicture`, new Blob([binaryImage]));
    });
    
    const storedToken = localStorage.getItem("userInfo");
    const token = JSON.parse(storedToken).token;
    
    axios.post(`${BASE_URL}/offer/homeoffer`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        setIsLoading(false);
        navigate("/sell") 

      })
      .catch((err) => {
        console.error("Error submitting data:", err);
        setIsLoading(false);
        alert("An error occurred while sending the information.");

      });
    
  
  }

  return (
    <div className="getOffrHome">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitData}
      >
        {(formik) => (
          <Form className="getOffrHome">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-6 form-group">
                  <label>{t("Name")}</label>
                  <Field
                    name="name"
                    rows={4}
                    type="text"
                    className="form-control"
                    placeholder={t("Name")}
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="error-message text-danger pb-2"
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label>{t("Last Name")}</label>
                  <Field
                    name="lastname"
                    type="text"
                    className="form-control"
                    placeholder={t("Last Name")}
                    required

                  />
                  <ErrorMessage
                    name="lastname"
                    component="div"
                    className="error-message text-danger pb-2"
                  />
                </div>

                <div className="col-md-6">
                  <label>{t("Address")}</label>
                  <Field
                    type="text"
                    className="form-control"
                    placeholder={t("Address")}
                    required
                    name="address"

                  />
                  <ErrorMessage
                    name="address"
                    component="div"
                    className="error-message text-danger pb-2"
                  />
                </div>
                <div className="col-md-6 form-group ">
                  <label>{t("Tumbol")}</label>
                  <div className="input-group">
                    <Field
                      type="text"
                      className="form-control"
                      placeholder={t("Tumbol")}
                      name="tumbol"

                    />
                  </div>
                  <ErrorMessage
                    name="tumbol"
                    component="div"
                    className="error-message text-danger pb-2"
                  />

                </div>

                <div className="col-md-6 form-group">
                  <label>{t("City/Amphor")}</label>
                  <Field
                    type="text"
                    className="form-control"
                    placeholder={t("City/Amphor")}
                    name="city"

                  />
                  <ErrorMessage
                    name="city"
                    component="div"
                    className="error-message text-danger pb-2"
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label>{t("States/Province")}</label>
                  <Field
                    type="text"
                    className="form-control"
                    placeholder={t("States/Province")}
                    name="states"

                  />
                  <ErrorMessage
                    name="states"

                    component="div"
                    className="error-message text-danger pb-2"
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label>{t("Country")}</label>
                  <Field
                    type="text"
                    className="form-control"
                    placeholder={t("Country")}
                    name="country"

                  />
                  <ErrorMessage
                    name="country"

                    component="div"
                    className="error-message text-danger pb-2"
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label>{t("Line Id")}</label>
                  <Field
                    type="text"
                    className="form-control"
                    placeholder={t("Line Id")}
                    name="lineid"

                  />
                  <ErrorMessage
                    name="lineid"

                    component="div"
                    className="error-message text-danger pb-2"
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label>{t("WhatsApp")}</label>
                  <Field
                    type="text"
                    className="form-control"
                    placeholder={t("WhatsApp")}
                    name="whataAppNo"

                  />
                  <ErrorMessage
                    name="whataAppNo"

                    component="div"
                    className="error-message text-danger pb-2"
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label>{t("Wechat")}</label>
                  <Field
                    type="text"
                    className="form-control"
                    placeholder={t("Wechat")}
                    name="weChat"

                  />
                  <ErrorMessage
                    name="weChat"

                    component="div"
                    className="error-message text-danger pb-2"
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label>{t("Tel Number")}</label>
                  <Field
                    type="number"
                    className="form-control"
                    placeholder={t("Tel Number")}
                    name="telNo"

                  />
                  <ErrorMessage
                    name="telNo"

                    component="div"
                    className="error-message text-danger pb-2"
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label>{t("emailAddress")}</label>
                  <Field
                    type="text"
                    className="form-control"
                    placeholder={t("emailAddress")}
                    name="email"

                  />
                  <ErrorMessage
                    name="email"

                    component="div"
                    className="error-message text-danger pb-2"
                  />
                </div>

                <div className="col-md-6 form-group">
                  <label>{t("Upload video")}</label>
                  <Field
                    type="text"
                    className="form-control"
                    placeholder={t("Upload video")}
                    name="video"

                  />
                  <ErrorMessage
                    name="video"

                    component="div"
                    className="error-message text-danger pb-2"
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label>{t("Upload picture")}</label>
                  <div className="custom-file col-md-4 col-lg-6">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="propertyThumbnail"
                      multiple
                      onChange={(e) => handleFileChange(e, formik)}
                      style={{ display: "none" }}
                    />
                    <label
                      className="custom-file-label cursor-pointer"
                      htmlFor="propertyThumbnail"
                    >
                      {t("Choose File")}
                    </label>
                  </div>

                  {formik.values.images?.map((thumbnail, index) => (
                    <img
                      key={index}
                      className="col-lg-3 col-md-3"
                      src={thumbnail}
                      alt={`ThumbImage-${index}`}
                      style={{ width: "100px", margin: "5px" }}
                    />
                  ))}

                  <ErrorMessage
                    name="images"
                    component="div"
                    className="error-message text-danger pt-3"
                  />
                </div>
              </div>
              <button
              type="Submit"
                className="btn-custom w-25"
               
                
                onClick={()=>submitData(formik)}
              disabled={isLoading} // Disable the button while loading
              >
                {isLoading ? (
                            <span>
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> {t('Submitting...')}
                            </span>
                          ) : (
                t("Submit")
                 )} 
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Content;
