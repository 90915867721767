import { Box } from "@chakra-ui/react";
import React, { useState } from "react";
import "./Service.css";
import Content from "../sell/Content";
import { useTranslation } from "react-i18next";

const SellerGuide = () => {
  const {  t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState(1); // Set the default selected item ID

  const data = [
    {
      id: 1,
      paratitle: "Sell Home With Us Now",
      title: "Should I sell my home now ?",
      content: [
        "Fill out the listing form with details about your property, including information about the location, size, and features of the property.",
        "Upload photos of the property to provide potential buyers with a visual representation.",
        "Review and submit your listing.",
        "Our team will review your listing and will contact you if any additional information is needed.",
        "Once your listing is approved, it will be live on our website and visible to potential buyers.",
        "Interested buyers can contact you directly through the website to schedule a showing or to ask any questions they may have.",
        "Once you've found a buyer and the sale is complete, let us know and we will remove the listing from our website.",
      ],
    },
    {
      id: 2,
      title: "How much is my home worth ?",
      paratitle: "Your House Worth ?",
      content: [
        "Fill out the listing form with details about your property, including information about the location, size, and features of the property.",
        "Upload photos of the property to provide potential buyers with a visual representation.",
        "Review and submit your listing.",
        "Our team will review your listing and will contact you if any additional information is needed.",
        "Once your listing is approved, it will be live on our website and visible to potential buyers.",
        "Interested buyers can contact you directly through the website to schedule a showing or to ask any questions they may have.",
        "Once you've found a buyer and the sale is complete, let us know and we will remove the listing from our website.",
      ],
    },
    {
      id: 3,
      title: "How should I sell my home ?",
      paratitle: "Sell Home With Us",
      content: [
        "Fill out the listing form with details about your property, including information about the location, size, and features of the property.",
        "Upload photos of the property to provide potential buyers with a visual representation.",
        "Review and submit your listing.",
        "Our team will review your listing and will contact you if any additional information is needed.",
        "Once your listing is approved, it will be live on our website and visible to potential buyers.",
        "Interested buyers can contact you directly through the website to schedule a showing or to ask any questions they may have.",
        "Once you've found a buyer and the sale is complete, let us know and we will remove the listing from our website.",
      ],
    },
    {
      id: 4,
      title: "How to prepare your home for sale ?",
      paratitle: "Prepare Your Home For Sale",
      content: [
        "Fill out the listing form with details about your property, including information about the location, size, and features of the property.",
        "Upload photos of the property to provide potential buyers with a visual representation.",
        "Review and submit your listing.",
        "Our team will review your listing and will contact you if any additional information is needed.",
        "Once your listing is approved, it will be live on our website and visible to potential buyers.",
        "Interested buyers can contact you directly through the website to schedule a showing or to ask any questions they may have.",
        "Once you've found a buyer and the sale is complete, let us know and we will remove the listing from our website.",
      ],
    },
  ];
  // let data = [
  //     {
  //         img: "/assets/img/listings-list/sellers-guide-1.png",
  //         para: "Should I sell my home now ?",
  //     },
  //     {
  //         img: "/assets/img/listings-list/sellers-guide-2.png",
  //         para: "How much is my home worth ?",
  //     },
  //     {
  //         img: "/assets/img/listings-list/sellers-guide-3.png",
  //         para: "How should I sell my home ?",
  //     },
  //     {
  //         img: "/assets/img/listings-list/sellers-guide-4.png",
  //         para: "How to prepare your home for sale ?",
  //     },
  // ]
  // img={process.env.PUBLIC_URL + "/assets/img/listings-list/service2.jpg"}

  return (
    <>
      <div style={{ backgroundColor: "rgb(242, 242, 242)" }}>
        <Box className="container pt-5 pb-2">
        <h4>{t('sellerGuides')}</h4>

          <div style={{cursor:"pointer"}} className="row ">
            {data.map((item) => (
              <div
                key={item.id}
                className={`col-md-4 col-lg-3 mb-4 ${
                  item.id === selectedItem ? "selected" : ""
                }`}
                onClick={() => setSelectedItem(item.id)}    
              >
                
                <div
                  style={{ borderRadius: "15px" }}
                  className="bg-white shadow py-2 px-3 abcd"
                >
                  <div className="d-flex justify-center  ">
                    {/* You can customize the image rendering here */}
                    <img
                      style={{ height: "74px" }}
                      src={`/assets/img/listings-list/sellers-guide-${item.id}.png`}
                      alt={item.title}
                    />
                  </div>
                  <div>
                    <u>
                      <p
                        className="para_size"
                        style={{ textAlign: "center", fontSize: "22px" }}
                      >
                        {item.title}
                      </p>
                    </u>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Box>
      </div>
      
      <div className="mt-16 mb-2 mx-16 py-10 rounded-2xl text-center border">
        <div>
          <h3 className="mb-8 text-themeColor">{data.find(item => item.id === selectedItem)?.paratitle}</h3>
        </div>
        <div className="my-20 text-medium font-medium px-2">
          <ul>
            {data.find(item => item.id === selectedItem)?.content.map((point, index) => (
              <li key={index}>{point}</li>
            
            ))}
          </ul>
        </div>
        <p className="my-4 px-2">
          <b>Note : </b>We will be available to assist you throughout the process, and can help with pricing, promotion and other aspect of the sale.
        </p>
      </div>
    </>
  );
};

export default SellerGuide;
