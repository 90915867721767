import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import Header from "../layouts/Header";
import Breadcrumb from "../layouts/Breadcrumb";
import Footer from "../layouts/Footerthree";
import Content from "../sections/ThinkToKnow/ComplainContent";
import Download from "../layouts/App";
import { useTranslation } from "react-i18next";

const Complain = () => {
  const {t}=useTranslation()
  return (
    <Fragment>
      <Helmet>
        <title>
          Buy Home For Less | Complain
        </title>
        <meta name="description" content="#" />
      </Helmet>
      <Header />
      <Breadcrumb
        breadcrumb={{ pagename: t("Complain") }}
      />

      <Content />
      <Download />
      <Footer />
    </Fragment>
  );
};

export default Complain;
