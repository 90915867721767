import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import Header from "../layouts/Header";
import Breadcrumb from "../layouts/Breadcrumb";
import Footer from "../layouts/Footerthree";
import Content from "../sections/ThinkToKnow/RetireContent";
import Download from "../layouts/App";
import BsInfo from "../sections/services/Bsinfo";
import { useTranslation } from "react-i18next";




const Retire = () => {
  const { t } = useTranslation();


  let data = {
    title: t("How to retire in Thailand"),
    para: t("Person"),
    picture: "/assets/img/listings-list/Retire.jpg",
  };
  return(
  <Fragment>
    <Helmet>
      <title>
        Buy Home For Less | How to retire in Thailand
      </title>
      <meta name="description" content="#" />
    </Helmet>
    <Header />
    <Breadcrumb breadcrumb={{ pagename: t("How to retire in Thailand") }} />
    <BsInfo data={data} />

    {/* Inserted information about obtaining a non-immigrant visa */}
    <div className="container">
    <div className="section-title-wrap mr-lg-30">
      <h3 className="subtitle">{t('Step1')}</h3>
      <h5 className="text-danger ms-4">{t('requirements')}</h5>
      <ul className=" ms-5 h6">
        <li style={{ listStyle: "number" }} className="text-muted mb-3">
          {t('passport')}
        </li>
        <li style={{ listStyle: "number" }} className="text-muted mb-3">
          {t('accommodation')}
        </li>
        <li style={{ listStyle: "number" }} className="text-muted mb-3">
          {t('bankStatement')}
        </li>
        <li style={{ listStyle: "number" }} className="text-muted mb-3">
          {t('healthInsurance')}
        </li>
        <li style={{ listStyle: "number" }} className="text-muted mb-3">
          {t('nationalityResidence')}
        </li>
        <li style={{ listStyle: "number" }} className="text-muted mb-3">
          {t('applicationLocation')}
        </li>
      </ul>
    </div>
    </div>
    <div className="container mt-5">
        <div className="section-title-wrap mr-lg-30">
      <h3 className="subtitle">{t('Step2')}</h3>
      <h5 className="text-danger ms-4">{t('nonImmigrantOExtensionRequirements')}</h5>
      <ul className="ms-5 h6">
        <li style={{ listStyle: "number" }} className="text-muted mb-3">
          {t('mustBe50YearsOld')}
        </li>
        <li style={{ listStyle: "number" }} className="text-muted mb-3">
          {t('passport')}
        </li>
        <li style={{ listStyle: "number" }} className="text-muted mb-3">
          {t('nonImmigrantOVisa')}
        </li>
        <li style={{ listStyle: "number" }} className="text-muted mb-3">
          {t('tm30Receipt')}
        </li>
        <li style={{ listStyle: "number" }} className="text-muted mb-3">
          {t('proofOfMeetingFinancialRequirements')}
        </li>
        <li style={{ listStyle: "number" }} className="text-muted mb-3">
          {t('incomeCertificate')}
        </li>
        <li style={{ listStyle: "number" }} className="text-muted mb-3">
          {t('thaiBankBook')}
        </li>
        <li style={{ listStyle: "number" }} className="text-muted mb-3">
          {t('letterFromThaiBank')}
        </li>
        <li style={{ listStyle: "number" }} className="text-muted mb-3">
          {t('photos')}
        </li>
        <li style={{ listStyle: "number" }} className="text-muted mb-3">
          {t('proofOfPermanentAccommodation')}
        </li>
      </ul>
      <h5 className="text-success ms-2">
        {t('medicalCertificateAndCriminalRecordCertificate')}
      </h5>
    </div>
    </div>

    <div className="container mt-5 mb-5">
    <div className="section-title-wrap mr-lg-30">
      <h3 className="subtitle">{t('Step3')}</h3>

      <h5 className="text-muted ms-4">
        {t('step3Description')}
        <br />
        <br />
        {t('reentryPermitOptions')}
        <ul className="text-success">
          <li style={{ listStyle: "none", marginLeft: "15px" }}>
            <span className="text-dark">1.</span> {t('singleEntry')}
          </li>
          <li style={{ listStyle: "none", marginLeft: "15px" }}>
            <span className="text-dark">2.</span> {t('multipleEntries')}
          </li>
        </ul>
      </h5>
      <h5 className="text-muted ms-4">
        {t('reentryPermitApplication')}
      </h5>
    </div>
    </div>
    <div className="container mt-5 mb-5">
      <div className="section-title-wrap mr-lg-30">
        <h3 className="subtitle">
          {t('Step4')}
        </h3>

        <h5 className="text-muted ms-4">
          {t('reportingStayDescription')}
          <br />
        </h5>
        <h5 className="text-muted ms-4">
          {t('renewExtensionOfStay')}
        </h5>
      </div>
    </div>
    <div className="container mt-5 mb-5">
      <div className="section-title-wrap mr-lg-30">
        <h3 className="subtitle">
          {t('summaryTitle')}
        </h3>

        <h5 className="text-muted ms-4">
          {t('summaryDescription')}
        </h5>
      </div>
    </div>
    <br />
    <br />
    <div className="mt-5">
      <Download />
    </div>
    <Footer />
  </Fragment>
  );
};

export default Retire;
