import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const App = () => {
  const { t } = useTranslation();

  return (
    <div className="footer-top">
      {/* <div className="container">
        <div className="row footer-btn-wrapper">
          <div className="col-lg-7 footer-widget">
            <h4>{t('downloadOurApp')}</h4>
            <p>{t('dummyText')}</p>
          </div>
          <div className="col-lg-5 col-md-12 footer-widget">
            <div className="footer-btn">
              <Link to="#" className="btn-custom-2 grey">
                {' '}
                <i className="flaticon-playstore" />
                {t('googlePlay')}
              </Link>
              <Link to="#" className="btn-custom-2 grey">
                {' '}
                <i className="flaticon-apple" />
                {t('appStore')}
              </Link>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default App;
