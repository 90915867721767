import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ChatContext = createContext();

const ChatProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Initialize as null or an empty object
  const [selectedChat, setSelectedChat] = useState(null); // Initialize as null or an empty object
  const [chats, setChats] = useState([]); // Initialize as an empty array
  const [mainlist, setMainlist] = useState(""); // Consider the initial value
  const [sublist, setSublist] = useState(""); // Consider the initial value

  const navigate = useNavigate();
  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo) {
      setUser(userInfo);
    } else {
      navigate("/");
    }
  }, [navigate]); // Include navigate in the dependency array

  return (
    <ChatContext.Provider
      value={{ user, setUser, mainlist, setMainlist, selectedChat, setSelectedChat, chats, setChats, sublist, setSublist }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const ChatState = () => {
  return useContext(ChatContext);
};

export default ChatProvider;
