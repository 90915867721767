import React, { useEffect, useReducer, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip, Dropdown, NavLink } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axios from "axios";
import BASE_URL from "../../../baseUrl";
import ListingFilter from "../pricing/ListingFilter";
import Categories from "../homefour/Categories";

const gallerytip = <Tooltip>Gallery</Tooltip>;
const bedstip = <Tooltip>Beds</Tooltip>;
const bathstip = <Tooltip>Bathrooms</Tooltip>;
const areatip = <Tooltip>Square Feet</Tooltip>;

const Content = () => {
  const [state, setState] = useState([]);
  const [filter, setFilter] = useState([]);
  const [screen2, setScreen2] = useState(null);
  const [SearhingData, setSearhingData] = useState();
  const [Data, setdata] = useState([])
  const { t } = useTranslation()

  const navigate = useNavigate();

  const getData2 = async () => {
    const response = await fetch(
      `${BASE_URL}/submitlisting/submitRent`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          mode: "no-cors",
        },
      }
    );
    const data = await response.json();
    setState(data?.result);
  };

  useEffect(() => {
    getData2();
  }, []);

  useEffect(() => {
    if (SearhingData) {
      setdata(SearhingData)
    }

  }, [SearhingData]);


  const getData = async (filterobj) => {
    // console.log(filterobj.type,"filterobj")
    try {
      // if (searchedData?.msg === "location data") {
      //   setState(searchedData?.data);
      // }
      // else{
      const response = await axios.post(
        `${BASE_URL}/submitlisting/search`,
        {
          city: filterobj.city,
          type: filterobj.type ? filterobj.type : "Any Property",
          priceRange: filterobj.priceRange,
          country: filterobj.country,
          status: filterobj.status === "Any Status" ? "" : filterobj.status,
          states: filterobj.states,
        }

      );
      if (response) {
        // console.log(response,"response")
        setState(response.data.result)
      }
      else {
        // console.log("no data found")
      }
      // }

    } catch (error) {
      // console.log(error)
    }
  };


  const filterData = (data) => {
  }



  const acessChat = () => {
    return;
  };





  return (
    <div>
      {/* <ListingFilter getData={getData} setSearhingData={setSearhingData} /> */}
      <div className="container flex justify-center flex-col">
        <div className="shadow-sm mt-3 rounded">
          <ListingFilter getData={filterData} />
        </div>
        <Categories type="Rent" />
      </div>
      <div className="flex justify-center container">
        <div className="w-9/12 my-8" >
          {screen2 ? (
            filter.length === 0 ? (
              <div className="flex justify-center">
                <div className="md:w-9/12 lg:w-4/6  border p-10 border-gray-500 rounded-lg flex justify-between items-center">
                  <div className="w-32 pr-12">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/img/exclaimationMark.png`}
                      width={"100%"}
                      alt="NotFound"
                    />
                  </div>
                  <div className="w-10/12">
                    <h2 className="mb-4 text-gray italic">{`Property Not Found `}</h2>
                    <p className="text-gray-200">
                      Sorry, the specific type of property you are searching for
                      is currently not available on our website. Please check
                      back later or contact us for further assistance
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="my-8 text-center text-2xl font-semibold">
                  Data After Filter
                </div>
                <div>
                  {filter?.map((res, index) => {
                    const basicInformation = res?.BasicInformation;
                    const deatils = res?.Details;
                    const Gallery = res?.Gallery;
                    const Agent = res?.Agent;
                    const author = res?.Author ? res?.Author : res?.Admin;

                    return (
                      <div className="listing listing-list " key={index}>
                        <div
                          className="listing-thumbnail"
                          style={{ width: "60%" }}
                        >
                          <Link
                            onClick={() => {
                              navigate(`/listing-details-v1/${res._id}`);
                              window.location.reload(false);
                            }}
                          >
                            <img
                              src={`/${Gallery?.file}`}
                              alt="listing"
                              style={{
                                width: "100%",
                                height: "100%",
                              }}
                            />
                          </Link>
                          <div className="listing-badges">
                            {/* <span className="listing-badge featured">
                              {" "}
                              <i className="fas fa-star" />{" "}
                            </span> */}

                            {/* <span className="listing-badge sale">{t("On Sale")}</span> */}

                            {/* <span className="listing-badge pending">
                              {" "}
                              {t("Pending")}
                            </span> */}

                            {/* <span className="listing-badge rent"> {t("Rental")}</span> */}
                          </div>
                          {/* <div className="listing-controls"> */}
                          {/* <Link to="#" className="favorite">
                              <i className="far fa-heart" />
                            </Link> */}
                          {/* <Link to="#" className="compare">
                              <i className="fas fa-sync-alt" />
                            </Link> */}
                          {/* </div> */}
                        </div>
                        <div className="listing-body" style={{ width: "70%" }}>
                          <div className="listing-author">
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/img/people/2.jpg`}
                              alt="author"
                            />
                            <div className="listing-author-body">
                              <p>
                                {" "}
                                <Link to="#">{author?.authorname}</Link>{" "}
                              </p>
                              <span className="listing-date">
                                {Agent?.agentName + " " + Agent?.agentName}
                              </span>
                            </div>
                            {/* <Dropdown className="options-dropdown">
                              <Dropdown.Toggle as={NavLink}>
                                <i className="fas fa-ellipsis-v" />
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="dropdown-menu-right">
                                <ul>
                                  <li>
                                    {" "}
                                    <Link to="tel:+123456789">
                                      {" "}
                                      <i className="fas fa-phone" /> Call Agent
                                    </Link>{" "}
                                  </li>
                                  <li
                                    onClick={() => acessChat(author?.authorId)}
                                  >
                                    <Link to="/chat">
                                      <i className="fas fa-envelope" /> Send
                                      Message
                                    </Link>
                                  </li>
                                  <li>
                                    {" "}
                                    <Link to="/">
                                      {" "}
                                      <i className="fas fa-bookmark" /> Book
                                      Tour
                                    </Link>{" "}
                                  </li>
                                </ul>
                              </Dropdown.Menu>
                            </Dropdown> */}
                          </div>
                          <h5 className="listing-title">
                            {" "}
                            <Link
                              onClick={() => {
                                navigate(`/listing-details-v1/${res._id}`);
                              }}
                              className="btn-custom btn-sm secondary"
                            >
                              {basicInformation?.name}
                            </Link>
                          </h5>
                          <span className="listing-price">

                            {basicInformation?.price}
                            {/* {new Intl.NumberFormat().format(
                            item.monthlyprice.toFixed(2)
                          )} */}
                            $ <span>/{basicInformation?.period}</span>{" "}
                          </span>
                          <p className="listing-text">
                            {basicInformation?.description}
                          </p>
                          <div className="acr-listing-icons">
                            <OverlayTrigger overlay={bedstip}>
                              <div className="acr-listing-icon">
                                <i className="flaticon-bedroom" />
                                <span className="acr-listing-icon-value">
                                  {deatils.beds}
                                </span>
                              </div>
                            </OverlayTrigger>
                            <OverlayTrigger overlay={bathstip}>
                              <div className="acr-listing-icon">
                                <i className="flaticon-bathroom" />
                                <span className="acr-listing-icon-value">
                                  {deatils.bathrooms}
                                </span>
                              </div>
                            </OverlayTrigger>
                            <OverlayTrigger overlay={areatip}>
                              <div className="acr-listing-icon">
                                <i className="flaticon-ruler" />
                                <span className="acr-listing-icon-value">
                                  {basicInformation?.space}
                                  {/* {new Intl.NumberFormat().format(item.area)} */}
                                </span>
                              </div>
                            </OverlayTrigger>
                          </div>
                          <div className="listing-gallery-wrapper">
                            <Link
                              onClick={() => {
                                navigate(`/listing-details-v1/${res._id}`);
                                window.location.reload(false);
                              }}
                              className="btn-custom btn-sm secondary"
                            >
                              {t("viewDetails")}
                            </Link>
                            {/* <OverlayTrigger overlay={gallerytip}>
                              <Link to="#" className="listing-gallery">
                                {" "}
                                <i className="fas fa-camera" />{" "}
                              </Link>
                            </OverlayTrigger> */}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )
          ) : state?.length === 0 ? (
            <div className="flex justify-center">
              <div className="md:w-9/12 lg:w-4/6 my-4  border p-10 border-gray-500 rounded-lg flex justify-between items-center">
                <div className="w-32 pr-12">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/img/exclaimationMark.png`}
                    width={"100%"}
                    alt="NotFound"
                  />
                </div>
                <div className="w-10/12">
                  <h2 className="mb-4 text-gray italic"> {t("propertyNotFoundHeading")}</h2>
                  <p className="text-gray-200">
                    {t("propertyNotFoundDescription")}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            state.map((res, index) => {
              // console.log(res, ",../././././././.")
              const basicInformation = res?.BasicInformation;
              const Gallery = res?.Gallery;
              const deatils = res?.Details;
              const author = res?.Author;
              const Agent = res?.Agent;
              return (
                <div className="listing listing-list " key={index}>
                  <div className="listing-thumbnail" style={{ width: "60%",height:"250px" }}>
                    <Link
                      onClick={() => {
                        navigate(`/listing-details-v1/${res._id}`);
                        window.location.reload(false);
                      }}
                    >
                      <img
                        src={`${Gallery?.file[0]}`}
                        alt="listing"
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </Link>
                    <div className="listing-badges">
                      {/* <span className="listing-badge featured">
                        {" "}
                        <i className="fas fa-star" />{" "}
                      </span> */}
                      {/* <span className="listing-badge sale">{t("On Sale")}</span> */}

                      {/* <span className="listing-badge pending">
                        {" "}
                        {t("Pending")}
                      </span> */}

                      {/* <span className="listing-badge rent"> {t("Rental")}</span> */}
                    </div>
                    {/* <div className="listing-controls"> */}
                    {/* <Link to="#" className="favorite">
                        <i className="far fa-heart" />
                      </Link> */}
                    {/* <Link to="#" className="compare">
                        <i className="fas fa-sync-alt" />
                      </Link> */}
                    {/* </div> */}
                  </div>
                  <div className="listing-body pt-2" style={{ width: "70%" }}>
                    <div className="listing-author mb-2">
                      {console.log(Agent.agentthumbnail)}
                      <img
                        src={Agent.agentthumbnail}
                        alt="author"
                      />
                      <div className="listing-author-body">
                        <p>
                          {" "}
                          <Link to="#">{author?.authorname}</Link>{" "}
                        </p>
                        <span className="listing-date">{Agent?.agentName}</span>
                      </div>
                      {/* <Dropdown className="options-dropdown">
                        <Dropdown.Toggle as={NavLink}>
                          <i className="fas fa-ellipsis-v" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu-right">
                          <ul>
                            <li>
                              {" "}
                              <Link to="tel:+123456789">
                                {" "}
                                <i className="fas fa-phone" /> {t("callAgent")}
                              </Link>{" "}
                            </li>
                            <li onClick={() => acessChat(author?.authorId)}>
                              <Link to="/chat">
                                <i className="fas fa-envelope" /> {t("sendMessage")}
                              </Link>
                            </li>
                            <li>
                              {" "}
                              <Link to="/BookTour">
                                {" "}
                                <i className="fas fa-bookmark" /> {t("Book Tour")}
                              </Link>{" "}
                            </li>
                          </ul>
                        </Dropdown.Menu>
                      </Dropdown> */}
                    </div>
                    <h5 className="listing-title mb-2">
                      {" "}
                      <Link
                        onClick={() => {
                          navigate(`/listing-details-v1/${res._id}`);
                          window.location.reload(false);
                        }}
                      >
                        {basicInformation?.name}
                      </Link>{" "}
                    </h5>
                    <span className="listing-price mb-2">
                      {basicInformation?.listingType === "Rent" ? (
                        basicInformation?.rentalPrice
                      ) : (
                        basicInformation?.price
                      )}
                      {/* {new Intl.NumberFormat().format(
                          item.monthlyprice.toFixed(2)
                        )} */}
                      $ <span>/{basicInformation?.period}</span>{" "}
                    </span>
                    <p className="listing-text">
                      {basicInformation?.description?.length > 30
                        ? basicInformation.description.slice(0, 30) + "..."
                        : basicInformation.description}
                    </p>
                    <div className="acr-listing-icons mb-2">
                      <OverlayTrigger overlay={bedstip}>
                        <div className="acr-listing-icon">
                          <i className="flaticon-bedroom" />
                          <span className="acr-listing-icon-value">
                            {deatils.beds?.toString().length > 10
                              ? deatils.beds.toString().slice(0, 10) + "..."
                              : deatils.beds}
                          </span>
                        </div>
                      </OverlayTrigger>
                      <OverlayTrigger overlay={bathstip}>
                        <div className="acr-listing-icon">
                          <i className="flaticon-bathroom" />
                          <span className="acr-listing-icon-value">
                            {deatils.bathrooms?.toString().length > 10
                              ? deatils.bathrooms.toString().slice(0, 10) + "..."
                              : deatils.bathrooms}
                          </span>
                        </div>
                      </OverlayTrigger>
                      <OverlayTrigger overlay={areatip}>
                        <div className="acr-listing-icon">
                          <i className="flaticon-ruler" />
                          <span className="acr-listing-icon-value">
                            {basicInformation?.space?.toString().length > 10
                              ? basicInformation.space.toString().slice(0, 10) + "..."
                              : basicInformation.space}
                            {/* {new Intl.NumberFormat().format(item.area)} */}
                          </span>
                        </div>
                      </OverlayTrigger>
                    </div>

                    <div className="flex justify-between items-center">
                      <div className="listing-gallery-wrapper">
                        <Link
                          onClick={() => {
                            navigate(`/listing-details-v1/${res._id}`);
                            window.location.reload(false);
                          }}
                          className="btn-custom btn-sm secondary"
                        >
                          {t("viewDetails")}
                        </Link>
                        {/* <OverlayTrigger overlay={gallerytip}>
                        <Link to="#" className="listing-gallery">
                          {" "}
                          <i className="fas fa-camera" />{" "}
                        </Link>
                      </OverlayTrigger> */}
                      </div>
                      <div>
                        <label>
                          {t("Country")}:{" "}
                          {res?.Location?.country && res.Location.country !== "undefined"
                            ? res.Location.country.length > 10
                              ? res.Location.country.slice(0, 10) + "..."
                              : res.Location.country
                            : ""}
                          <span></span>
                        </label>
                      </div>
                      {
                        res.Location.provice == "undefined" || res.Location.provice == ""?null:
                        (< label > {t("Province")}: {res?.Location?.provice}<span></span></label>)
                      }
                      
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div >
  );
};

export default Content;
