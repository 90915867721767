import React, { useState } from "react";
import { ChatState } from "../sections/Context/ChatProvider";
import MyChats from "../sections/ChatComponents/MyChats";
import ChatBox from "../sections/ChatComponents/ChatBox";
import Headerfour from "../layouts/Headerfour";
import { Link } from "react-router-dom";

const ChatPage = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  // Get chat state
  const chatState = ChatState();

  // Destructure selectedChat, with a default value of an empty array
  const { selectedChat = [] } = chatState || {};

  const [fetchAgain, setFetchAgain] = useState(false);

  return (
    <div>
      <Headerfour />
      <div className="flex justify-center my-4">
        {userInfo == null ? (
          <div className="w-64 bg-red-100 p-4 rounded-md text-center animate-bounce">
            <p className="text-red-600 font-bold">Please Login first</p>
            <Link to="/login" className="bg-blue-500 hover:bg-blue-700 font-bold py-2 px-4 rounded">
              Login
            </Link>
          </div>
        ) : (
          <div
            style={{ height: "75vh" }}
            className="flex justify-center md:justify-between w-11/12 h-full p-2.5"
          >
            <div
              className={`${(selectedChat == null || selectedChat?.length === 0) || !selectedChat
                ? "block"
                : "hidden md:block"
                } w-full md:w-4/12 lg:w-3/12`}
            >
              <MyChats fetchAgain={fetchAgain} />
            </div>

            <div
              className={`${(selectedChat == null || selectedChat?.length === 0) || !selectedChat
                ? "hidden md:block"
                : "block"
                } w-full md:w-7/12 `}
            >
              <ChatBox fetchAgain={fetchAgain} setFetchAgain={setFetchAgain} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatPage;
