import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import BASE_URL from '../../../baseUrl';
import toast, { Toaster } from 'react-hot-toast';

class Contactform extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            form: {
                fullName: '',
                subject: '',
                emailAddress: '',
                yourMessage: '',
            }
        };
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [name]: value
            }
        }));
    }

    validateForm = () => {
        const { fullName, subject, emailAddress, yourMessage } = this.state.form;
        return fullName && subject && emailAddress && yourMessage;
    }

    SubmitForm = async () => {
        if (!this.validateForm()) {
            toast.error('Please fill all fields', {
                position: 'top-right', // Set position to top-right
              });
            return;
        }

        this.setState({ isLoading: true });

        const { fullName, subject, emailAddress, yourMessage } = this.state.form;
        
        const requestData = {
            name: fullName,
            subject: subject,
            email: emailAddress,
            msg: yourMessage,
        };

        axios.post(`${BASE_URL}/contact`, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((res) => {
            this.setState({ isLoading: false });
            toast.success('Message submitted successfully', {
                position: 'top-right', 
              });
        })
        .catch((err) => {
            console.error("Error submitting data:", err);
            this.setState({ isLoading: false });
            toast.error('An error occurred while sending the information', {
                position: 'top-right', 
              });
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.SubmitForm();
    }

    render() {
        const { t } = this.props;
        const { fullName, subject, emailAddress, yourMessage } = this.state.form;

        return (
            <div className="section pt-0">
                <Toaster />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mb-lg-30">
                            <div className="acr-locations bg-bottom bg-norepeat" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/assets/img/misc/bldg.png)" }}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="acr-location">
                                            <h5>{t("CALIFORNIA")}</h5>
                                            <h5>{t("usa")}</h5>
                                            <div className="acr-location-address">
                                                <p>{t("355 South Grand Street, Suite # 2450 Los Angeles, Ca 90071")}</p>
                                                <Link to="tel:+123456789">{t("phoneLink")}</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="acr-location">
                                            <h5>{t("Dubai")}</h5>
                                            <h5>{t("Middle East")}</h5>
                                            <div className="acr-location-address">
                                                <p>{t("tbilsiAdd")}</p>
                                                <Link to="tel:+123456789">(134) 984 438</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="acr-location">
                                            <h5>{t("ThaiLand")}</h5>
                                            <h5>{t("South East Asia")}</h5>
                                            <div className="acr-location-address">
                                                <p>{t("TUNGSONGHONE, LAKSI BANGKOK, 10210 THAILAND")}</p>
                                                <Link to="tel:+123456789">(443) 893 109</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="acr-location">
                                            <h5>{t("Uk")}</h5>
                                            <h5>{t("Europe")}</h5>
                                            <div className="acr-location-address">
                                                <p>{t("tbilsiAdd")}</p>
                                                <Link to="tel:+123456789">(009) 338 148</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-6">
                            <div className="section-title-wrap section-header">
                            </div>
                            <div className="comment-form">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-12 form-group">
                                            <label>{t('fullName')}</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                placeholder={t('placeholderFullName')} 
                                                name="fullName" 
                                                value={fullName} 
                                                onChange={this.handleChange} 
                                            />
                                        </div>
                                        <div className="col-md-12 form-group">
                                            <label>{t('subject')}</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                placeholder={t('placeholderSubject')} 
                                                name="subject" 
                                                value={subject} 
                                                onChange={this.handleChange} 
                                            />
                                        </div>
                                        <div className="col-md-12 form-group">
                                            <label>{t('emailAddress')}</label>
                                            <input 
                                                type="email" 
                                                className="form-control" 
                                                placeholder={t('placeholderEmailAddress')} 
                                                name="emailAddress" 
                                                value={emailAddress} 
                                                onChange={this.handleChange} 
                                            />
                                        </div>
                                        <div className="col-md-12 form-group">
                                            <label>{t('yourMessage')}</label>
                                            <textarea 
                                                className="form-control" 
                                                placeholder={t('placeholderYourMessage')} 
                                                name="yourMessage" 
                                                rows={7} 
                                                value={yourMessage} 
                                                onChange={this.handleChange} 
                                            />
                                        </div>
                                        <button type="submit" className="btn-custom primary">{t('sendMessage')}</button>
                                    </div>
                                </form>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Contactform);
