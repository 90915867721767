import axios from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import BASE_URL from "../../../baseUrl";
import toast, { Toaster } from "react-hot-toast";
import PhoneInput from "react-phone-input-2";

const ComplainContent = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    Name: "",
    lastname: "",
    TelNumber: "",
    emailAddress: "",
    comment: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    // Check if all fields are filled
    const { Name, lastname, TelNumber, emailAddress, comment } = formData;
    if (!Name || !lastname || !TelNumber || !emailAddress || !comment) {
      toast.error('Please fill all fields', {
        position: 'top-right',
      });
      return;
    }

    const requestData = {
      firstName: Name,
      lastName: lastname,
      telNo: TelNumber,
      email: emailAddress,
      comment: comment,
    };

    axios
      .post(`${BASE_URL}/complainForm`, requestData)
      .then((res) => {
        // Handle success if needed
        // console.log("Complaint submitted successfully", res);
        toast.success('Complaint submitted successfully', {
          position: 'top-right',
        });
        setFormData({
          Name: "",
          lastname: "",
          TelNumber: "",
          emailAddress: "",
          comment: "",
        });
      })
      .catch((err) => {
        console.error("Error submitting data:", err);
        toast.error('An error occurred while sending the complaint', {
          position: 'top-right',
        });
      });
  };

  return (
    <div className="container">
      <Toaster />
      <div className="my-16  py-10 rounded-2xl text-center border">
        <div>
          <h3 className="mb-8 text-themeColor">{t("Complaint Form")}</h3>
        </div>
        <div className="mx-8">
          <div className="row">
            <div className="col-md-6 form-group text-left">
              <label className="pl-3">{t("Name")}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("Name")}
                name="Name"
                value={formData.Name}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-6 form-group text-left">
              <label className="pl-3">{t("Last Name")}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("Last Name")}
                name="lastname"
                value={formData.lastname}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 form-group text-left">
              <label className="pl-3">{t("Tel Number")}</label>
              {/* <input
                type="text"
                className="form-control"
                placeholder={t("Tel Number")}
                name="TelNumber"
                value={formData.TelNumber}
                onChange={handleInputChange}
              /> */}
              <PhoneInput
                country={"cn"}
                value={formData.TelNumber}
                name="TelNumber"
                onChange={(phone) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    TelNumber: phone,
                  }))
                }
                inputStyle={{ width: "100%" }}
                placeholder={t("Tel Number")}
              />
            </div>
            <div className="col-md-6 form-group text-left">
              <label className="pl-3">{t("emailAddress")}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("emailAddress")}
                name="emailAddress"
                value={formData.emailAddress}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 form-group text-left">
              <label className="pl-3">{t("typeYourComment")}</label>
              <textarea
                className="form-control"
                rows="8"
                placeholder={t("typeYourComment")}
                name="comment"
                value={formData.comment}
                onChange={handleInputChange}
              ></textarea>
            </div>
          </div>
          <div className="text-left">
            <button type="button" className="btn btn-primary" onClick={handleSubmit}>
              {t("Send Message")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComplainContent;
