const listings = [
  {
    id: 1,
    gridimg: "assets/img/listings/1.jpg",
    listimg: "assets/img/listings-list/1.jpg",
    title: "Iris Watson, Frederick Nebraska 20620",
    authorimg: "assets/img/people/2.jpg",
    authorname: "Heather Mclayn",
    postdate: "March 3, 2020",
    monthlyprice: 3500,
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    beds: 3,
    bathrooms: 2,
    area: 2499,
    star: true,
    sale: false,
    pending: true,
    rental: false,
    recent: true,
  },
  {
    id: 2,
    gridimg: "assets/img/listings/2.jpg",
    listimg: "assets/img/listings-list/2.jpg",
    title: "Theodore Lowe, Azusa New York 39531",
    authorimg: "assets/img/people/1.jpg",
    authorname: "Randy Blue",
    postdate: "March 3, 2020",
    monthlyprice: 3500,
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    beds: 3,
    bathrooms: 2,
    area: 2499,
    star: true,
    sale: true,
    pending: false,
    rental: false,
    recent: true,
  },
  {
    id: 3,
    gridimg: "assets/img/listings/3.jpg",
    listimg: "assets/img/listings-list/3.jpg",
    title: "Iris Watson, Frederick Nebraska 20620",
    authorimg: "assets/img/people/2.jpg",
    authorname: "Heather Mclayn",
    postdate: "March 3, 2020",
    monthlyprice: 3500,
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    beds: 3,
    bathrooms: 2,
    area: 3500,
    star: false,
    sale: false,
    pending: true,
    rental: false,
    recent: true,
  },
  {
    id: 4,
    gridimg: "assets/img/listings/4.jpg",
    listimg: "assets/img/listings-list/4.jpg",
    title: "Iris Watson, Frederick Nebraska 20620",
    authorimg: "assets/img/people/2.jpg",
    authorname: "Heather Mclayn",
    postdate: "March 3, 2020",
    monthlyprice: 3500,
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    beds: 3,
    bathrooms: 2,
    area: 2499,
    star: false,
    sale: true,
    pending: false,
    rental: false,
    recent: true,
  },
  {
    id: 5,
    gridimg: "assets/img/listings/5.jpg",
    listimg: "assets/img/listings-list/5.jpg",
    title: "Iris Watson, Frederick Nebraska 20620",
    authorimg: "assets/img/people/2.jpg",
    authorname: "Heather Mclayn",
    postdate: "March 3, 2020",
    monthlyprice: 3500,
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    beds: 3,
    bathrooms: 2,
    area: 2499,
    star: false,
    sale: true,
    pending: false,
    rental: false,
    recent: false,
  },
  {
    id: 6,
    gridimg: "assets/img/listings/1.jpg",
    listimg: "assets/img/listings-list/6.jpg",
    title: "Iris Watson, Frederick Nebraska 20620",
    authorimg: "assets/img/people/4.jpg",
    authorname: "Mike Stanly",
    postdate: "March 3, 2020",
    monthlyprice: 3500,
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    beds: 3,
    bathrooms: 2,
    area: 2499,
    star: false,
    sale: false,
    pending: false,
    rental: true,
    recent: false,
  },
  {
    id: 7,
    gridimg: "assets/img/listings/6.jpg",
    listimg: "assets/img/listings-list/7.jpg",
    title: "Iris Watson, Frederick Nebraska 20620",
    authorimg: "assets/img/people/2.jpg",
    authorname: "Heather Mclayn",
    postdate: "March 3, 2020",
    monthlyprice: 3500,
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    beds: 3,
    bathrooms: 2,
    area: 2499,
    star: true,
    sale: false,
    pending: true,
    rental: false,
    recent: false,
  },
  {
    id: 8,
    gridimg: "assets/img/listings/7.jpg",
    listimg: "assets/img/listings-list/8.jpg",
    title: "Theodore Lowe, Azusa New York 39531",
    authorimg: "assets/img/people/1.jpg",
    authorname: "Randy Blue",
    postdate: "March 3, 2020",
    monthlyprice: 3500,
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    beds: 3,
    bathrooms: 2,
    area: 2499,
    star: true,
    sale: true,
    pending: false,
    rental: false,
    recent: false,
  },
];

export default listings;
