import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import Header from "../layouts/Header";
import Breadcrumb from "../layouts/Breadcrumb";
// import Footer from "../layouts/Footer";
import Content from "../sections/contact/Content";
import { withTranslation } from "react-i18next";
import Footer from "../layouts/Footerthree";
class Contact extends Component {
  render() {
    const { t } = this.props;

    return (
      <Fragment>
        <Helmet>
          <title>Buy Home For Less | {t("contactUs")}</title>
          <meta name="description" content="#" />
        </Helmet>
        <Header />
        <Breadcrumb breadcrumb={{ pagename: t("contactUs") }} />
        <Content />
        <Footer />
      </Fragment>
    );
  }
}

export default withTranslation()(Contact);
