// import axios from 'axios';
import React, { useEffect, useState } from "react";
// import { OverlayTrigger } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AgentListingFilter from "../../pages/Agents/AgentlistingFilter";
import BASE_URL from "../../../baseUrl";
import axios from "axios";

function Content() {
  const [state, setstate] = useState([]);
  const [SearhingData, setSearhingData] = useState();
  const [Data, setdata] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [userDetails, setUserDetails] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const getAgent = async () => {
    const response = await fetch(`${BASE_URL}/agent/get-agents`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        mode: "no-cors",
      },
    });
    const data = await response.json();
    // console.log(data);
    setstate(data?.result);
  };
  const itemsPerPage = 5;
  const totalPages = Math.ceil(state.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentData = state.slice(startIndex, startIndex + itemsPerPage);
  // console.log(currentData);
  useEffect(() => {
    const getuser = localStorage.getItem("userInfo");
    if (getuser) {
      const userInfo = JSON.parse(getuser);
      // console.log(userInfo?._id);
      setUserDetails(userInfo?._id);
    }
  }, []);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const searchAgent = async () => {
    const params = {
      city: Data.city,
      country: Data.country,
      states: Data.states,
    };

    const url = `${BASE_URL}/agent/agent-search`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        mode: "no-cors",
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();
    setstate(data?.result);
  };

  useEffect(() => {
    if (SearhingData) {
      setdata(SearhingData);
    }
  }, [SearhingData]);

  useEffect(() => {
    getAgent();
  }, []);

  const EditAgent = (id) => {
    // console.log("helo");
    if (id) {
      // console.log(`${BASE_URL}/AgentCreate/${id}`);
      // const newUrl = `${BASE_URL}/AgentCreate/${id}`;
      // window.location.href = newUrl;
      navigate(`/AgentCreate`, { state: id });
    }
  };
  const DeleteAgent = (id) => {
    try {
      const response = axios.delete(`${BASE_URL}/Agent/delete/${id}`);
      if (response) {
        // console.log(response);
        getAgent();
      }
    } catch (error) {}
  };
  return (
    <>
      <AgentListingFilter
        getData={searchAgent}
        setSearhingData={setSearhingData}
      />
      <div className="container">
        <div className="flex">
          <div className="w-100 my-8">
            {currentData?.map((res) => {
              console.log(res);
              const basicInformation = res?.BasicInformation;
              // const Gallery = res?.Gallery;
              // const deatils = res?.Details;
              const author = res?.Author;
              // const Agent = res?.Agent;
              return (
                <div className="listing listing-list ">
                  <div
                    className="listing-thumbnail"
                    style={{ width: "30%", height: "100%" }}
                  >
                    <Link>
                      <img
                        src={`${res?.imageUrl}`}
                        alt="listing"
                        style={{
                          width: "100%",
                          height: "100%",
                          maxHeight: "200px",
                          minHeight: "200px",
                        }}
                      />
                    </Link>

                    {/* <div className="listing-controls"> */}
                    {/* <Link to="#" className="favorite">
                        <i className="far fa-heart" />
                      </Link> */}
                    {/* <Link to="#" className="compare">
                        <i className="fas fa-sync-alt" />
                      </Link> */}
                    {/* </div> */}
                  </div>
                  <div
                    className="listing-body flex justify-between items-end"
                    style={{ width: "70%" }}
                  >
                    <div>
                      <div className="listing-author">
                        <div className="listing-author-body">
                          <p>
                            {" "}
                            <Link to="#">{author?.authorname}</Link>{" "}
                          </p>
                          <span className="listing-date">
                            {res?.name + " " + res?.lastname}
                          </span>
                        </div>
                      </div>
                      <h5 className="listing-title">
                        {" "}
                        <Link
                          onClick={() => {
                            navigate(`/listing-details-v1/${res._id}`);
                            window.location.reload(false);
                          }}
                        >
                          {basicInformation?.name}
                        </Link>{" "}
                      </h5>
                      <span className="listing-price">
                        {/* {new Intl.NumberFormat().format(
                          item.monthlyprice.toFixed(2)
                        )} */}
                        <b>{t("Real Estate Type")}</b>:{" "}
                        <span >
                          {res?.realEstateType?.map((item, index) => (
                            <span key={index} style={{ marginRight: "8px",fontSize:"20px" }}>
                              {item}
                            </span>
                          ))}
                        </span>
                      </span>

                      <div className="acr-listing-icons flex flex-column align-items-start">
                        <div className="flex">
                          <div
                            style={{ fontSize: 12 }}
                            className="acr-listing-icon"
                          >
                            <p className="acr-listing-icon-value  pe-2">
                              <b>{t("Country")}</b>:
                            </p>
                            <span className="acr-listing-icon-value">
                              {res?.country?.length > 10
                                ? res.country.slice(0, 10) + "..."
                                : res?.country}
                            </span>
                          </div>
                          <div
                            style={{ fontSize: 12 }}
                            className="acr-listing-icon"
                          >
                            <p className="acr-listing-icon-value  pe-2">
                              <b>{t("City/Amphor")}</b>:
                            </p>
                            <span className="acr-listing-icon-value">
                              {res?.city?.length > 10
                                ? res.city.slice(0, 10) + "..."
                                : res?.city}
                            </span>
                          </div>
                          <div
                            style={{ fontSize: 12 }}
                            className="acr-listing-icon"
                          >
                            <p className="acr-listing-icon-value  pe-2">
                              <b>{t("area")}</b>:
                            </p>
                            <span className="acr-listing-icon-value">
                              {res?.area?.length > 10
                                ? res.area.slice(0, 10) + "..."
                                : res?.area}
                            </span>
                          </div>
                        </div>
                        <div className="phonenumber flex">
                          <div
                            style={{ fontSize: 12 }}
                            className="acr-listing-icon"
                          >
                            <p className="acr-listing-icon-value  pe-2">
                              <b>{t("Tel Number")}</b>:
                            </p>
                            <span className="acr-listing-icon-value">
                              {(res?.countryCode + res?.tel)?.length > 10
                                ? (res.countryCode + res.tel).slice(0, 10) +
                                  "..."
                                : res?.countryCode + res?.tel}
                            </span>
                          </div>
                          <div
                            style={{ fontSize: 12 }}
                            className="acr-listing-icon"
                          >
                            <p className="acr-listing-icon-value  pe-2">
                              <b>{t("WhatsappID")}</b>:
                            </p>
                            <span className="acr-listing-icon-value">
                              {res?.whatsappId?.length > 10
                                ? res.whatsappId.slice(0, 10) + "..."
                                : res?.whatsappId}
                            </span>
                          </div>
                          <div
                            style={{ fontSize: 12 }}
                            className="acr-listing-icon"
                          >
                            <p className="acr-listing-icon-value  pe-2">
                              <b>{t("WeChatID")}</b>:
                            </p>
                            <span className="acr-listing-icon-value">
                              {res?.weChatId?.length > 10
                                ? res.weChatId.slice(0, 10) + "..."
                                : res?.weChatId}
                            </span>
                          </div>
                        </div>
                        <div className="phonenumber flex">
                          <div
                            style={{ fontSize: 12 }}
                            className="acr-listing-icon"
                          >
                            <p className="acr-listing-icon-value  pe-2">
                              <b>{t("lineID")}</b>:
                            </p>
                            <span className="acr-listing-icon-value">
                              {res?.lineId?.length > 10
                                ? res.lineId.slice(0, 10) + "..."
                                : res?.lineId}
                            </span>
                          </div>
                          <div
                            style={{ fontSize: 12 }}
                            className="acr-listing-icon"
                          >
                            <p className="acr-listing-icon-value  pe-2">
                              <b>{t("telegramID")}</b>:
                            </p>
                            <span className="acr-listing-icon-value">
                              {res?.telegramId?.length > 10
                                ? res.telegramId.slice(0, 10) + "..."
                                : res?.telegramId}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="flex ">
                      {userDetails === res?.user ? (
                        <>
                          <button
                            className="mr-2 bg-[#01bbbc] rounded text-white py-2 px-3"
                            onClick={() => EditAgent(res?._id)}
                          >
                            {t("edit")}
                          </button>
                          <button
                            className="px-2 bg-[#01bbbc] text-white py-2 rounded"
                            onClick={() => DeleteAgent(res?._id)}
                          >
                            {t("delete")}
                          </button>
                        </>
                      ) : (
                        ""
                      )}
                    </div> */}
                    {/* <div className="listing-gallery-wrapper">
                      <Link
                        onClick={() => {
                          navigate(`/listing-details-v1/${res._id}`);
                          window.location.reload(false);
                        }}
                        className="btn-custom btn-sm secondary"
                      >
                        {t("viewDetails")}
                      </Link>
                        <Link to="#" className="listing-gallery">
                          {" "}
                          <i className="fas fa-camera" />{" "}
                        </Link>
                    </div> */}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="mb-4">
          {currentData.length === 0 ? null : (
            <nav aria-label="...">
              <ul className="pagination">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    Previous
                  </button>
                </li>
                {[...Array(totalPages)].map((_, index) => (
                  <li
                    key={index}
                    className={`page-item ${
                      currentPage === index + 1 ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          )}
        </div>
      </div>
    </>
  );
}

export default Content;
