import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import Header from "../layouts/Header";
import Breadcrumb from "../layouts/Breadcrumb";
import Footer from "../layouts/Footerthree";
// import Footer from "../layouts/Footer";
import Content from "../sections/pricing/Content";
import { useTranslation } from "react-i18next";

const About = () => {
  const {t} = useTranslation();
  return (
    <Fragment>
      <Helmet>
        <title>Buy Home For Less | About</title>
        <meta name="description" content="#" />
      </Helmet>
      <Header />
      <Breadcrumb breadcrumb={{ pagename: t("About Us") }} />
      <Content />
      <Footer />
    </Fragment>
  );
};

export default About;
