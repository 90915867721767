import React from "react";
import { Helmet } from "react-helmet";
import Header from "../layouts/Headerfour";
import Footer from "../layouts/Footerthree";
import Content from "../sections/homefour/Content";
import { useEffect, useState } from "react";
import axios from "axios";
import BASE_URL from "../../baseUrl";

const Homefour = () => {
  const [titleSeo, setTitleSeo] = useState("");
  const [description, setDescription] = useState("");
  const [metaTags, setMetaTags] = useState("")
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/SEO/get`);

        if (response.data.result.title) {
          setTitleSeo(response.data.result.title)
          setDescription(response.data.result.slug)
          setMetaTags(response.data.result.metaTag)
        }
      } catch (error) {
      }
    };
    fetchData();
  }, []);

  // data-react-helmet

  return (
    <div>
      <Helmet>
        <title>{titleSeo}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={titleSeo} />
        <meta name="keywords" content={metaTags} />
      </Helmet>
      <Header />
      <Content />
      <Footer />
    </div>
  );
};

export default Homefour;
