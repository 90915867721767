import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, NavLink } from "react-bootstrap";
import App from "./App";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../Context/LanguageContext";
import axios from "axios";
import BASE_URL from "../../baseUrl";
import footer from "../../assets/img/logoFooter.png"
// import i18n from "../sections/Language/Language";

const Footer = () => {
  const {  t } = useTranslation();
  const {  handleLanguageChange } = useLanguage(); 
  const [email, setEmail] = useState("");


  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    handleLanguageChange(lng);
    localStorage.setItem("language", lng);
    i18n.changeLanguage(lng);
};
const storedLanguage = localStorage.getItem("language");

useEffect(() => {
const storedLanguage = localStorage.getItem("language") || "English";
handleLanguageChange(storedLanguage); 
i18n.changeLanguage(storedLanguage); 
}, [i18n, handleLanguageChange]);


const getFlagAndName = (languageCode) => {
switch (languageCode) {
case "Chinese":
  return {
    flag: "china.png",
    name: "Chinese",
    width: "30px",
  };
case "Thai":
  return {
    flag: "thailand.png",
    name: "Thai",
    width: "60px",
  };
case "English":
default:
  return {
    flag: "united-states.png",
    name: "English",
  };
}
};

const { flag, name } = getFlagAndName(storedLanguage);


const sendEmail = ()=>{
  axios
  .post(`${BASE_URL}/SEO/subscription`, {
    email:email
  }).then((res)=>{
  }).catch((e)=>{
    console.log(e)
  })
}

  return (
    <footer className="acr-footer footer-2 footer-dark">
      {/* Footer Top Start */}
      <App />
      {/* Footer Top End */}
      {/* Footer Middle Start */}
      <div className="footer-middle">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-12 footer-widget">
              <div className="footer-logo">
                {" "}
                <img
                  src={footer}
                  alt="acres"
                />{" "}
              </div>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's{" "}
              </p>
              <Dropdown    className="acr-language-selector">
          <Dropdown.Toggle as={NavLink} className="dropdownicon">
        <img
          src={process.env.PUBLIC_URL + `/assets/img/flags/${flag}`}
          alt="flag"
          width={30}
        />
        <span>{name}</span>
      </Dropdown.Toggle>
              <Dropdown.Menu  className="dropdown-menu">
              <ul>
                  <li
                    onClick={() => {
                      changeLanguage("Chinese");
                    }}
                  >
                    {" "}
                    <Link to="#">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/img/flags/china.png"
                        }
                        alt="flag"
                      />{" "}
                      Chinese
                    </Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link
                      to="#"
                      onClick={() => {
                        changeLanguage("English");
                      }}
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/img/flags/united-states.png"
                        }
                        alt="flag"
                      />
                      English
                    </Link>{" "}
                  </li>
                  <li
                    onClick={() => {
                      changeLanguage("Thai");
                    }}
                  >
                    {" "}
                    <Link to="#">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/img/flags/thailand.png"
                        }
                        alt="flag"
                      />{" "}
                      Thai
                    </Link>{" "}
                  </li>
                </ul>
              </Dropdown.Menu>
            </Dropdown>
            </div>
            
            <div className="col-lg-2 offset-md-1 col-md-4 col-sm-6 footer-widget">
        <h5 className="widget-title">{t('menu')}</h5>
        <ul>
          <li><Link to="/">{t('find_home')}</Link></li>
          <li><Link to="/submit-listing">{t('add_listing')}</Link></li>
          {/* <li><Link to="/AgentCreate">{t('become_agent')}</Link></li> */}
          <li><Link to="/about">{t('about_us')}</Link></li>
          <li><Link to="/Complain">{t('complaint')}</Link></li>
          <li><Link to="/blog">{t('blogs_news')}</Link></li>
          <li><Link to="/submit-listing">{t('sell_property')}</Link></li>
          {/* <li><Link to="/contact">{t('contact_us')}</Link></li> */}
        </ul>
      </div>
      <div className="col-lg-2 col-md-3 col-sm-6 footer-widget">
      <h5 className="widget-title">{t('legal')}</h5>
      <ul>
        <li><Link to="/privacypolicy">{t('privacy_policy')}</Link></li>
        <li><Link to="/refundpolicy">{t('refund_policy')}</Link></li>
        <li><Link to="/cookiepolicy">{t('cookie_policy')}</Link></li>
        <li><Link to="/termsandcond">{t('terms_condition')}</Link></li>
        <li><Link to="/sell/agent">{t('find_agent_worldwide')}</Link></li>
        <li><Link to="/contact">{t('contact_us')}</Link></li>
        {/* <li><Link to="#">{t('job')}</Link></li> */}
      </ul>
    </div>
    <div className="col-lg-2 col-md-12 footer-widget">
      <h5 className="widget-title">{t('newsletter')}</h5>
      <p>{t('newsletter_description')}</p>
      <form method="post">
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder={t('newsletter_placeholder')}
            name="newsletter-email"
            onChange={(e)=>setEmail(e.target.value)}
          />
        </div>
        {/* <button
          onClick={()=>alert("haiaiaiia")}
          className="btn-custom primary light btn-block"
        >
          {t('join_newsletter')}
        </button> */}
      </form>
        <button
        onClick={()=>sendEmail()}
        className="btn-custom primary light btn-block"
        >
          {t('join_newsletter')}

        </button>
    </div>
            {/* <div className="col-lg-2 col-sm-4 footer-widget">
                                <h5 className="widget-title">Legal</h5>
                                <ul>
                                    <li> <Link to="/legal">Privacy Policy</Link> </li>
                                    <li> <Link to="/legal">Refund Policy</Link> </li>
                                    <li> <Link to="/legal">Cookie Policy</Link> </li>
                                    <li> <Link to="/legal">Legal Terms</Link> </li>
                                </ul>
                            </div> */}
          </div>
        </div>
      </div>
      {/* Footer Middle End */}
      {/* Footer Bottom Start */}
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <p className="m-0">
                © Copyright 2020 - <Link to="#">AndroThemes</Link> All Rights
                Reserved.
              </p>
            </div>
            <div className="col-lg-5">
              <ul>
                <li>
                  {" "}
                  <Link to="/">{t('find_home')}</Link>{" "}
                </li>
                <li>
                  {" "}
                  <Link to="/submit-listing">{t('add_listing')}</Link>{" "}
                </li>
                <li>
                  {" "}
                  <Link to="/sell/agent">{t('View Agencies')}</Link>{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Footer Bottom End */}
    </footer>
  );
};

export default Footer;
