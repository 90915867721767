import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const BsInfo = (props) => {
  const {  t } = useTranslation();

    let {data} = props
    
  return (
    <div className="section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 mb-lg-30 acr-dots-wrapper acr-single-img-wrapper">
            <img
              src={process.env.PUBLIC_URL + data.picture}
              alt="img"
            />
            <div className="acr-dots" />
          </div>
          <div className="col-lg-6">
            <div className="section-title-wrap mr-lg-30">
              {/* <h5 className="custom-primary">Get Started</h5> */}
              <h2 className="title">
                {data?.title}
              </h2>
              <p className="subtitle">
                {data?.para}
              </p>
              {/* <ul className="acr-list">
                <li>Lorem Ipsum is simply dummy text</li>
                <li>Many desktop publishing packages and web page editors</li>
                <li>There are many variations of passages of Lorem Ipsum</li>
                <li>Internet tend to repeat predefined chunks as necessary</li>
                <li>Contrary to popular belief, Lorem Ipsum is not simply</li>
              </ul> */}
              <ul className="acr-list">
              {data?.li1 ? <li>{data?.li1}</li> : null}

                {data?.li2 ? <li>{data?.li2}</li> :null }
                
              </ul>

              {/* <Link to="/listing-map" className="btn-custom">
                {t("GetStarted")}
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BsInfo;
