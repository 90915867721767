import React, { useState, useReducer, useEffect } from "react";
import Data from "../../../data/select";
import RentReducer from "../../../reducers/RentReducer";
import { useTranslation } from "react-i18next";

var RentFilter = {};

const AgentListingFilter = ({ getData, setSearhingData,onClick }) => {
  const [advanceSearch, setAdvanceSearch] = useState(true);
  const { t } = useTranslation()
  const {
    locationlist,
    statuslist,
    pricerangelist,
    bedslist,
    bathroomslist,
    diameterlist,
  } = Data;

  const [RentState, rentDispatch] = useReducer(RentReducer, RentFilter);
  const filterData = () => {
    RentFilter = {
      country: RentState.country,
      states: RentState.states,
      city: RentState.city,
    
     
    };
    getData(RentFilter);
  };
  useEffect(() => {
    if(RentState){
    
      setSearhingData(RentState)
    }
  }, [RentState]);
 

  return (
    <div className="container mt-8">
      <div className="flex ">
        <div className="acr-filter-form w-100">
          <div className="border border-gray px-4 pt-4 pb-2 rounded-xl">
            <div className="row ">
              <div className="col-lg-3 col-md-6">
                <div className="">
                  <input
                    className="form-control"
                    placeholder={t("Country")}
                    style={{ fontWeight: "bold", marginBottom: "15px" }}
                    value={RentState.country}
                    onChange={(e) =>
                      rentDispatch({
                        type: "UPDATE",
                        value: e.target.value,
                        key: "country",
                      })
                    }
                  >
                    {/* <option value="" hidden>
                      {t("location")}
                    </option>
                    {locationlist?.map((res,ind) => {
                     
                      return (
                        <option key={ind} className="form-control" value={res}>
                          {res}
                        </option>
                      );
                    })} */}
                  </input>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div>
                  <input
                    // defaultValue="Any Status"
                    placeholder={t("States/Province")}
                    className="form-control"
                    style={{ fontWeight: "bold", marginBottom: "15px" }}
                    value={RentState.states}
                    onChange={(e) =>
                      rentDispatch({
                        type: "UPDATE",
                        value: e.target.value,
                        key: "states",
                      })
                    }
                  >
                    {/* <option value="" hidden>
                      {t("States/Province")}
                    </option>
                    {statuslist?.map((res ,ind) => {
                      return (
                        <option key={ind} className="form-control" value={res}>
                          {res}
                        </option>
                      );
                    })} */}
                  </input>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div>
                  <input
                    // defaultValue="Any Status"
                    placeholder={t("City/Amphor")}
                    className="form-control"
                   
                    style={{ fontWeight: "bold", marginBottom: "15px" }}
                    value={RentState.city}
                    onChange={(e) =>
                      rentDispatch({
                        type: "UPDATE",
                        value: e.target.value,
                        key: "city",
                      })
                    }
                  >
                    {/* <option value="" hidden>
                      {t("City/Amphor")}
                    </option>
                    {statuslist?.map((res ,ind) => {
                      return (
                        <option key={ind} className="form-control" value={res}>
                          {res}
                        </option>
                      );
                    })} */}
                  </input>
                </div>
              </div>
            
              <div className="submit-btn col-lg-3 col-md-6">
                <div className="form-group mb-0 mt-1">
                  <button
                    onClick={() => filterData()}
                    className="btn-custom secondary btn-block"
                  >
                    {t("Search listings")}
                  </button>
                </div>
              </div>
            </div>
            
          </div>

          {/* <div className="flex justify-center">
            <div
              className={
                advanceSearch
                  ? "advanced-search-trigger semi-circle active"
                  : "advanced-search-trigger semi-circle"
              }
              onClick={() => setAdvanceSearch(!advanceSearch)}
            >
              <i className="fas fa-chevron-up" />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default AgentListingFilter;
