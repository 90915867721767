import React, { Fragment } from "react";
import Bluecta from "../../layouts/Bluecta";
import Gallery from "./Gallery";
import Info from "./Info";
import Services from "./Services";
import BsInfo from "./Bsinfo";
import { useTranslation } from "react-i18next";

const FinancailContent = () => {
  const {t} = useTranslation();
    let data = {
        title:t("Financial Planing"),
        para:t("Buy home for"),
        picture:"/assets/img/listings-list/Financial.jpg"
    }

  return (
    <Fragment>
      <BsInfo data={data}  />
      <Services />
      <Gallery />
      <div className="section pt-0">
        <Bluecta />
      </div>
    </Fragment>
  );
};

export default FinancailContent;
