import React from "react";
import Header from "../layouts/Header";
import Content from "../sections/PropertyListing/Content";
import Breadcrumb from "../layouts/Breadcrumb";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PropertyListing = () => {
  const { type,listingtype } = useParams();
  // console.log(listingtype,"akakakakkaka");
  const location = useLocation();
  const { t } = useTranslation()
 
  return (
    <div>
      <Header />
      <Breadcrumb breadcrumb={{ pagename: `${t(type)}` }} />
      <Content listingtype={listingtype}searchedData={location.state} />
    </div>
  );
};

export default PropertyListing;
