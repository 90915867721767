import React, { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import contactinfo from "../../../data/contactinfo.json";
import axios from "axios";
import { useTranslation } from "react-i18next";
import BASE_URL from "../../../baseUrl";

const Faqs = () => {
  const [flag, setFlag] = useState(false);
  const [data, setData] = useState([]);
  const { t } = useTranslation()

  useEffect(() => {
    axios
      .get(`${BASE_URL}/admin/get-faqs`)
      .then((res) => {
        setFlag(true);
        // console.log(res?.data?.result);
        setData(res.data.result);
      });
  }, []);
  return (
    <div className="">
      <div className="container">
        <div className="section-title-wrap section-header">
          <h5 className="custom-primary">{t("FAQ")}</h5>
          <h2 className="title">{t("FAQ")}</h2>
        </div>
        <div className="row">
          <div className="col-lg-8 mb-lg-30">
            {data?.length === 0 && flag ? (
              <h5>No Data</h5>
            ) : data?.length === 0 && flag === false ? (
              <h5>Loading ...</h5>
            ) : (
              <Accordion defaultActiveKey="0" className="with-gap">
                {Array.isArray(data) && data?.map((res, key) => (
                  <Accordion.Item eventKey={key + 1} key={key}>
                    <Accordion.Header>{t(res.title)}</Accordion.Header>
                    <Accordion.Body className="collapseparent">
                      {res.content.split("\n").map((line, index) => (
                        <p key={index}>{t(line)}</p>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            )}
          </div>
          <div className="col-lg-4 infographics-5">
            {contactinfo.slice(0, 2).map((item, i) => (
              <div key={i} className="acr-infographic-item">
                <i className={"flaticon-" + item.icon + ""} />
                <div className="acr-infographic-item-body">
                  <h5>{t(item.title)}</h5>
                  <p>{t(item.desfaq)}</p>
                  <Link
                    to={item.btnurl}
                    className="btn-custom secondary btn-sm"
                  >
                    {t(item.btntext)}
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
